import { CameraAlt, Cancel, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, Button, CircularProgress, colors, MenuItem, Stack, Typography } from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'

import dayjs from 'dayjs';
import AppBox from '../AppBox';
import AppDivider from '../AppDivider';
import AppTextField from '../AppTextField';
import AppSnackbar from '../../global/snackbar/Snackbar';
import { personalAPI } from '../../../api/personal';
import { PersonalSchema } from '../../../validations/PersonalSchema';
import { classroomAPI } from '../../../api/classroom';
import { parseClassroom } from '../../../config/parseClassroom';
import { cycleOption, genderOption, salaryOption } from '../../../config/defaultValue';
import { jobAPI } from '../../../api/job';
import { serverURL } from '../../../config/serverUrl';
import { useNavigate } from 'react-router-dom';
import ComfirmDeletionDialog from '../../global/ComfirmDeletionDialog';



const PersonalUpdate = ({niu}) => {
    const navigate = useNavigate()

    const [cycleOneData, setCycleOneData] = useState([])
    const [cycleTwoData, setCycleTwoData] = useState([])
    const [cycleThreeData, setCycleThreeData] = useState([])
    const [cycleFourData, setCycleFourData] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    const [url,setUrl] = React.useState(null)


    const [jobsList, setJobsList] = useState([])
    const [job, setJob] = useState("")
    const [cycle, setCycle] = useState(1)
    const [classroom, setClassroom] = useState("")
    const [professorId, setProfessorId] = useState("1")
    const [classroomOptions, setClassroomOptions] = useState([])

    const [noNiu, setNoNiu] = useState(" ")
    const [createdAt, setCreatedAt] = useState(" ")
    const [updatedAt, setUpdatedAt] = useState(" ")
    const [isActive, setIsActive] = useState(false)


     // InputState
    const [imageFile, setImageFile] = useState(null)
    const [firstName, setFirstName] = useState(" ")
    const [lastName, setLastName] = useState(" ")
    const [birthdate, setBirthDate] = useState(dayjs())
    const [gender, setGender] = useState("M")
    const [number, setNumber] = useState(" ")
    const [email, setEmail] = useState(" ")
    const [fixedSalary, setFixedSalary] = useState(true)
    const [salary, setSalary] = useState(0)

    //image input
    const inputImage = useRef()


    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarType, setSnackbarType] = useState("success")

    //loading 
    const [loading, setLoading] = useState(false)

    // deletion dialog 
    const [openDeletionDialog, setOpenDeletionDialog] = useState(false)
    

    const handleImageChange = (image) => {

        //console.log(image);
        const fileMimeType = image.type.replace('image/','')
        const fileTypes = /jpeg|jpg|png|gif/
        const isGoodMimeType = fileTypes.test(fileMimeType)
        if(isGoodMimeType) {
            setImageFile(image) 
            setUrl(URL.createObjectURL(image))

            //console.log(URL.createObjectURL(image));

            setOpenSnackbar(true)
            setSnackbarType("success")
            setSnackbarMessage("Image chargée avec succès")
        }
        else{
            setOpenSnackbar(true)
            setSnackbarType("error")
            setSnackbarMessage("Veuillez choisir une image")
        }
    }

    const handleCycleChange = (_cycle) => {
        setCycle(_cycle)
        if(_cycle === 1){
            setClassroom(cycleOneData[0].id)
            setClassroomOptions(cycleOneData)
        }
        else if(_cycle === 2){
            setClassroom(cycleTwoData[0].id)
            setClassroomOptions(cycleTwoData)
        }
        else if(_cycle === 3) {
            setClassroom(cycleThreeData[0].id)
            setClassroomOptions(cycleThreeData)
        }
        else if(_cycle === 4) {
            setClassroom(cycleFourData[0].id)
            setClassroomOptions(cycleFourData)
        }
        else {
            return
        }

    }


    const handleClassChange = (_classroom) => {
        setClassroom(_classroom)
    }

    const handleSucessDeletionDialog = () => {
        setOpenDeletionDialog(false)
        deletePersonal()
    }

    const deletePersonal = () => {
         setIsLoading(true)

        const deletedAt = {
            deletedAt: dayjs()
        }

        personalAPI.deletePersonal(noNiu,deletedAt)
            .then(({data}) => {
                if(data.success){
                    setOpenSnackbar(true)
                    setSnackbarType("success")
                    setSnackbarMessage("Suppression effectuer avec succès")
                }
                else {
                    setOpenSnackbar(true)
                    setSnackbarType("error")
                    setSnackbarMessage("Erreur lors de la suppression")
                }

                setIsLoading(false)
                if(job === professorId) {
                    navigate("/professor")
                }
                else{
                    navigate("/personal")
                }

            })
            .catch((e) => {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage("Erreur lors de la suppression")

                setIsLoading(false)
            })
    }

    const updatePersonnal = async() => {
        setLoading(true)


        const personnal = {
            firstName: firstName,
            lastName: lastName,
            birthDate: birthdate,
            gender: gender,
            phoneNumber: number,
            email: email,
            fixedSalary: fixedSalary,
            salary: salary,
            upatedAt: dayjs(),
            isActive: isActive
        }

        const isValid = await PersonalSchema
            .validate(personnal)
            .catch(error => {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage(error.errors)
                setLoading(false)

                return
            })

        if(isValid) {
            const formData = new FormData();
            for ( var key in personnal ) {
                formData.append(key, personnal[key]);
            }

            if(job === professorId) {
                formData.append('classroomId',classroom);
                formData.append('jobId',null);
                formData.append('role','enseignement');
            }
            else {
                formData.append('jobId',job);
                formData.append('classroomId',null);
                formData.append('role','administration');
            }

            if(imageFile) {
                formData.append('image',imageFile, `${niu}`);
                formData.append('photoUrl',`${niu}.${imageFile.type.replace('image/','')}`);
            }

            personalAPI.putPersonal(noNiu,formData)
            .then(({data}) => {
                if(data.success){
                    setOpenSnackbar(true)
                    setSnackbarType("success")
                    setSnackbarMessage("Informations modifier avec succès")
                }
                else {
                    setOpenSnackbar(true)
                    setSnackbarType("error")
                    setSnackbarMessage("Erreur lors de la modification des informations")

                }

                initializeJobAndClassroomAndPersonal()

                setLoading(false)
            })
            .catch((e) => {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage("Erreur lors de l'enregistrement du personnel")

                setLoading(false)
            })

        }

    }


    // get classroom from db and update state
    const initializeJobAndClassroomAndPersonal = () => {
        setIsLoading(true);

        //get job
        jobAPI.getJob()
            .then(({data}) => {
                setJobsList(data);
                //console.log(data);
                let professorIndex = data.find(i => i.title.trim().toLowerCase() === 'corps enseignant')
                setProfessorId(professorIndex.id)

                //get classroom
                classroomAPI.getClassroom()
                    .then(({data}) => {
                        const parsedClassroom = parseClassroom(data);

                        const _cycleOneData = parsedClassroom.filter((item) => item.cycle === 1)
                        setCycleOneData(_cycleOneData)

                        const _cycleTwoData = parsedClassroom.filter((item) => item.cycle === 2)
                        setCycleTwoData(_cycleTwoData)

                        const _cycleThreeData = parsedClassroom.filter((item) => item.cycle === 3)
                        setCycleThreeData(_cycleThreeData)

                        const _cycleFourData = parsedClassroom.filter((item) => item.cycle === 4)
                        setCycleFourData(_cycleFourData)

                        //get personal
                        personalAPI.getPersonalByNiu(niu)
                            .then(({data}) => {

                                // console.log(data);
                                // update state
                                setNoNiu(data.niu)
                                setCreatedAt(data.createdAt)
                                setUpdatedAt(data.updatedAt)
                                setIsActive(data.isActive)

                                // InputState
                                setUrl(data.photoUrl)
                                setLastName(data.lastName)
                                setFirstName(data.firstName)
                                setBirthDate(data.birthDate)
                                setGender(data.gender)
                                setEmail(data.email)
                                setNumber(data.phoneNumber)
                                setFixedSalary(data.fixedSalary)
                                setSalary(data.salary)

                                if(data.role === "enseignement") {
                                    setJob(professorIndex.id)
                                    // setCycle(data.classroom.cycle)
                                    // setCycle(data.classroom.classroom)

                                    //classroom
                                    const _cycle = data.classroom.cycle
                                    setCycle(_cycle)
                                    if(_cycle === 1){
                                        setClassroom(_cycleOneData[0].id)
                                        setClassroomOptions(_cycleOneData)
                                    }
                                    else if(_cycle === 2){
                                        setClassroom(_cycleTwoData[0].id)
                                        setClassroomOptions(_cycleTwoData)
                                    }
                                    else if(_cycle === 3) {
                                        setClassroom(_cycleThreeData[0].id)
                                        setClassroomOptions(_cycleThreeData)
                                    }
                                    else if(_cycle === 4) {
                                        setClassroom(_cycleFourData[0].id)
                                        setClassroomOptions(_cycleFourData)
                                    }
                                    else {
                                        return
                                    }
                                    handleClassChange(data.classroom.id) 

                                }
                                else {
                                    setJob(data.jobId)
                                }



                            })
                    })

            });

            setIsLoading(false);
    }


    useEffect(() => {
        initializeJobAndClassroomAndPersonal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        {
            !isLoading && (
                <Stack p={5}>
                <AppBox>
                    {
                        !url && 
                        <Box  
                            onClick={() => inputImage.current.click()}
                            display='flex'
                            width={200} 
                            height={200} 
                            border={1} 
                            borderColor={colors.grey['300']} 
                            bgcolor={colors.grey['200']} 
                            justifyContent='center'
                            alignItems='center'
                        >
                            <CameraAlt sx={{ fontSize: '3rem', color: colors.grey['500'] }} />
                        </Box>
                    }
                    {
                        url && 
                        <Box
                            onClick={() => inputImage.current.click()}
                            display='flex'
                            width={200} 
                            height={200} 
                            border={1} 
                            borderColor={colors.grey['300']} 
                            bgcolor={colors.grey['200']} 
                            justifyContent='center'
                            alignItems='center'
                        >
                            {
                                imageFile && (
                                    <img src={url} alt='screenshot' className='img-fluid' />
                                )
                            }
                            {
                                !imageFile && (
                                    <img src={`${serverURL}/profiles/personals/${url}`} alt='screenshot' className='img-fluid' />
                                )
                            }
                        </Box>
                    }
                    <input 
                        style={{ visibility: 'hidden' }}
                        ref={inputImage} 
                        type='file' 
                        name='uploaded_file'
                        onChange={(e) => handleImageChange(e.target.files[0])} 
                    />
                </AppBox>
                <Typography color="primary" pt="20px">Niveau</Typography>
                <AppDivider />
                <AppBox>
                    <AppTextField
                        required 
                        select 
                        fullWidth 
                        label="Choix de cycle"
                        size='small'
                        value={job} 
                        onChange={(e) => setJob(e.target.value)}
                    >
                        {jobsList.map((data) => (
                        <MenuItem key={data.id} value={data.id}>{data.title}</MenuItem>
                    )
                    )
                        }
                    </AppTextField>
                </AppBox>
                {job === professorId && 
                    (
                        <AppBox>
                            <AppTextField
                                select 
                                label="Choix de cycle" 
                                value={cycle} 
                                onChange={(e) => handleCycleChange(e.target.value)}
                            >
                                {
                                    cycleOption.map((data) => (
                                        <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                                    ))
                                }
                            </AppTextField>
                            <AppTextField
                                select 
                                label="Choix de la classe" 
                                value={classroom} 
                                onChange={(e) => handleClassChange(e.target.value)}
                            >
                            {classroomOptions.map((data) => (
                                <MenuItem key={data.id} value={data.id}>{data.label}</MenuItem>
                                ))
                            }
                            </AppTextField>
                        </AppBox>
                    )
                }
                <Typography color="primary" pt="20px">Information personnelle</Typography>
                <AppDivider />
                <AppBox>
                <AppTextField
                        label="Nom" 
                        value={lastName}
                        onClick={(e) => e.target.value === " " && setLastName("")}
                        onChange={(e) => setLastName(e.target.value)} 
                        error={!lastName}
                        helperText={
                            !lastName ? "Veuillez entrer le nom" : ""
                        }
                    />
                    <AppTextField 
                        label="Prenom" 
                        value={firstName} 
                        onClick={(e) => e.target.value === " " && setFirstName("")}
                        onChange={(e) => setFirstName(e.target.value)} 
                        error={!firstName}
                        helperText={
                            !firstName ? "Veuillez entrer le prenom" : ""
                        }
                    />
                </AppBox>
                <AppBox width="50%">
                    <AppTextField
                        select 
                        label="Genre" 
                        value={gender} 
                        onChange={(e) => setGender(e.target.value)}
                    >
                        {
                            genderOption.map((data) => (
                                <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                            ))
                        }
                    </AppTextField>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Date de naissance"
                            inputFormat="DD/MM/YYYY"
                            value={birthdate}
                            onChange={(value) => setBirthDate(value)}
                            maxDate={dayjs()}
                            renderInput={(params) => <AppTextField {...params} />}
                        />
                    </LocalizationProvider>
                </AppBox>
                <Typography color="primary" pt="20px">Contact</Typography>
                <AppDivider />
                <AppBox>
                    <AppTextField 
                        label="Telephone"
                        type="tel"
                        value={number} 
                        onClick={(e) => e.target.value === " " && setNumber("")}
                        onChange={(e) => e.target.value.length < 9 && setNumber(e.target.value)} 
                        error={!number}
                        helperText={
                            !number ? "Veuillez entrer le numero de telephone" : ""
                        }
                    />

                    <AppTextField 
                        label="Email"
                        type="email"
                        required={false}
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </AppBox>
                <Typography color="primary" pt="20px">Salaire</Typography>
                <AppDivider />
                <AppBox>
                    <AppTextField 
                        label="Type de salaire" 
                        select
                        value={fixedSalary} 
                        onChange={(e) => setFixedSalary(e.target.value)}
                    >
                        {
                            salaryOption.map((data) => (
                                <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                            ))
                        }
                    </AppTextField>
                    <AppTextField 
                        label="Salaire" 
                        type="number"
                        value={salary} 
                        onChange={(e) => setSalary(e.target.value)} 
                        error={salary < 0}
                        helperText={
                            salary < 0 ? "Veuillez entrer le salaire" : ""
                        }
                    />
                </AppBox>
                <AppBox>
                     <AppTextField
                        label="Est actif"
                        value={isActive ? "Oui" : "Non"}
                        aria-readonly 
                        disabled
                    />

                </AppBox>
                <AppBox>
                    <AppTextField
                        sx={{ mt: 2 }}
                        aria-readonly 
                        disabled
                        label="NIU" 
                        value={noNiu}
                    />
                </AppBox>
                <AppBox>
                <AppTextField
                        sx={{ mt: 2 }}
                        aria-readonly 
                        disabled
                        label="Modifier le" 
                        value={dayjs(updatedAt).format('DD MMMM, HH:mm:ss')}
                    />
                    <AppTextField 
                        sx={{ mt: 2 }}
                        aria-readonly 
                        disabled
                        label="Créer le" 
                        value={dayjs(createdAt).format('DD MMMM, HH:mm:ss')}
                    />
                </AppBox>
                <Box mt={2}>
                    <LoadingButton loading={loading} onClick={() => updatePersonnal()} variant='contained' loadingPosition='start' startIcon={<Save />} disableElevation>
                        Enregistrer
                    </LoadingButton>
                    <Button startIcon={<Cancel />} variant='contained' color='error' sx={{ mx: 2 }} onClick={() => setOpenDeletionDialog(true)}>
                        Supprimer
                    </Button>
                </Box>


            </Stack>
            )
        }
        {
            isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            )
        }
            <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
            <ComfirmDeletionDialog open={openDeletionDialog} handleSuccess={() => handleSucessDeletionDialog()} setOpen={(isOpen) => setOpenDeletionDialog(isOpen)} />
        </>
    )
}

export default PersonalUpdate