import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PaiementList from '../profile/PaiementList'
import { paiementAPI } from '../../api/paiement'
import LoadingBackdrop from '../global/LoadingBackdrop'

const History = ({niu, total}) => {

    const [loading, setLoading] = useState(false)
    const [paiementsList, setPaiementsList] = useState([])

    const getPaiements = ()  => {
        setLoading(true)
        paiementAPI.getStudentPaiementByNiu(niu)
            .then(({data}) => {
                setPaiementsList(data)
            })
            .catch((e) => console.log(e))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getPaiements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Box>
            {
            loading && <LoadingBackdrop />
            }
            {
                !loading && <PaiementList paiementsList={paiementsList} total={total} />
            }
        </Box>
    )
}

export default History