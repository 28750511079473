import { API } from "./index";



// setting years
const getStudentByNiu = (niu) => API.get(`/student/${niu}`);
const getStudent = () => API.get('/student');
const getStudentByChar = (char) => API.get(`/student/search/${char}`);
const getStudentByCycle = (cycle) => API.get(`/student/cycle/${cycle}`);
const getStudentByClassroom = (classroom) => API.get(`/student/classroom/${classroom}`);
const postStudent = (data) => API.post('/student', data, {
    headers: {
    'Content-Type': 'multipart/form-data'
  }
});

const putStudent = (niu,data) => API.put(`/student/${niu}`, data, {
    headers: {
    'Content-Type': 'multipart/form-data'
  }
});

const deleteStudent = (niu,data) => API.delete(`/student/${niu}`, data);


export const studentAPI = {
    getStudentByNiu,
    getStudent,
    getStudentByCycle,
    getStudentByClassroom,
    getStudentByChar,
    postStudent,
    putStudent,
    deleteStudent
}
