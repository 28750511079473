import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { combineTwoArrayAndSumByDate } from '../../config/combineTwoArrayAndSumByDate';
import { combineTwoArrayByIndex } from '../../config/combineTwoArrayByIndex';
import { sortByYearMonth } from '../../config/sortByYearMonth';


const AreaChartComponent = ({monthlyPaiement, monthlyProfessorExpense, monthlyExpense, monthlyIncome}) => {
  const [data, setData] = useState([])
  //console.log(data);

  useEffect(() => {

    const expense = combineTwoArrayAndSumByDate(monthlyExpense,monthlyProfessorExpense)
    const income = combineTwoArrayAndSumByDate(monthlyPaiement,monthlyIncome)


    income.forEach(function(obj) {
        obj.Revenu = obj.amount;
        delete obj.amount; 
      });

    expense.forEach(function(obj) {
      obj.Depense = obj.amount;
      delete obj.amount;
    });

    setData(sortByYearMonth(combineTwoArrayByIndex(income,expense)))

  },[monthlyPaiement, monthlyProfessorExpense, monthlyExpense,monthlyIncome])

  

  return ( 
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 20,
          bottom: 0,
        }}
      >
          <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
              </linearGradient>
          </defs>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="createdAt" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="Revenu" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="Depense" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default AreaChartComponent;