import React from 'react'
import {Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr'
import PaiementItem from './PaiementItem';
import EmptyDataAlert from '../global/EmptyDataAlert';

dayjs.locale(fr) 


const PaiementList = ({paiementsList, total, resource}) => {

    return (
        <Stack width='100%'>
            {
                paiementsList.length <= 0 && (
                <EmptyDataAlert />
                )
            }
            {
                paiementsList.length > 0 && (
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Numéro de recu</TableCell>
                                <TableCell align="center">Montant</TableCell>
                                <TableCell align="center">Balance</TableCell>
                                <TableCell align="center">Reste</TableCell>
                                <TableCell align="center">Cumul</TableCell>
                                <TableCell align="center">Pour</TableCell>
                                <TableCell align="right">Date de transaction</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                paiementsList.map((receipt,index) => {
                                    receipt.total = total
                                    return (<PaiementItem key={index} receipt={receipt} resource={resource}/>
                                    )
                                  }
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                )
            }
        </Stack>
    )
}

export default PaiementList