import { Cancel, Paid } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { paiementAPI } from '../../../api/paiement'
import AppSnackbar from '../../global/snackbar/Snackbar'
import { OMAPI } from '../../../api/orange/OM'

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  const MonthItemDialog = ({openDialog, setOpenDialog, monthName, monthId, total, paid, niu, getYears, getPaiements, kind}) => {

    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarType, setSnackbarType] = useState("success")
 
    const [amount, setAmount] = useState(0)
    const balance = total - paid


    const handleCloseDialog = (event, raison) => {
      if(["escapeKeyDown", "backdropClick"].includes(raison))
      {
        return;
      }
      setOpenDialog(false);
    };

    const makePaiement = async () => {
      setLoading(true)


      if(amount === 0) {
        setOpenSnackbar(true)
        setSnackbarType("error")
        setSnackbarMessage("Veuillez entrer un montant")
      }
      else if(amount > balance){
        setOpenSnackbar(true)
        setSnackbarType("error")
        setSnackbarMessage("Le montant payer ne peut être supérieur au montant dû")
      }
      else {
        const paiement = {
          receiptNumber: dayjs().valueOf(),
          kind: kind,
          amount: amount,
          balance: balance,
          yearMonthId: monthId,
          createdAt: dayjs(),
          updatedAt: dayjs()
        }

        if(kind) {
          paiement.studentNiu = niu
        }
        else {
          paiement.personalNiu = niu
        }

        // console.log(paiement);

        if(kind && kind === 3) {
          try {
            const token_response = await OMAPI.getToken()
            console.log(token_response);
          } catch (error) {
            console.log("OM",error);
          }
          
        }
        else {
          paiementAPI.postPaiement(paiement)
            .then(() => {
            getYears()
            getPaiements()

            setAmount(0)

            setOpenDialog(false)

            setOpenSnackbar(true)
            setSnackbarType("success")

            setSnackbarMessage("Paiement effectuer avec succès")
            setLoading(false)

          })
          .catch((e) => {

            setOpenSnackbar(true)
            setSnackbarType("error")
            setSnackbarMessage("Erreur lors du paiement")

            setLoading(false)
          })
        }
      }

    }


    return (
      <>
        <Dialog maxWidth='xs' fullWidth open={openDialog} onClose={handleCloseDialog}  TransitionComponent={Transition} scroll='paper'>
          <DialogTitle display='flex' flexDirection='row' justifyContent='flex-end' alignItems='baseline'>
            <Typography variant='h4' component='span'> {monthName} </Typography> 
            <Typography variant='h5' component='span' className='fw-light fst-italic'> - ({total})</Typography>
          </DialogTitle>
          <DialogContent>
          <Box display='flex' flexDirection='column'>
              <Typography variant='body1' component='span' className='fw-light'>{niu}</Typography>

              <Typography variant='h6' className='fw-bold'>Payer: {paid}</Typography>
              <Typography variant='h6' className='fw-bold'>Restant: {balance}</Typography>

              <TextField 
                variant='standard' 
                size='small' 
                label="Montant" 
                type='number'
                sx={{ mt: 2 }} 
                value={amount} 
                onChange={(e) => setAmount(e.target.value) } 
              /> 

            </Box>
          </DialogContent>
          <DialogActions sx={{px: 3, mb: 3}}>
            <Button variant='contained' startIcon={ <Cancel /> } disableElevation onClick={handleCloseDialog}>Annuler</Button>
            <LoadingButton loading={loading} variant='contained' disableElevation startIcon={<Paid />} onClick={() => {kind ? makePaiement() : makePaiement()}}>
                  {kind ? kind === 3 ? "Payer par orange money" : "Encaisser" : 'Debourser'} 
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
      </>
    )
}

export default MonthItemDialog