import { Refresh, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { schoolInformationAPI } from '../../api/schoolInformation'
import { SchoolInformationSchema } from '../../validations/SchoolInformationSchema'
import AppSnackbar from '../global/snackbar/Snackbar'
import AppBox from '../profile/AppBox'
import AppDivider from '../profile/AppDivider'
import AppTextField from '../profile/AppTextField'
import EmptyDataAlert from '../global/EmptyDataAlert'

const SchoolInformation = () => {
  // loading
  const [loading, setLoading] = useState(false)
  // snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarType, setSnackbarType] = useState("success")

  const [id, setId] = useState(" ")
  const [name, setName] = useState(" ")
  const [address, setAddress] = useState(" ")
  const [door, setDoor] = useState(" ")
  const [street, setStreet] = useState(" ")
  const [phoneNumber, setPhoneNumber] = useState(" ")
  const [email, setEmail] = useState(" ")

  const [emptyData, setEmptyData] = useState(false)

  const setInformation = async () => {
    setLoading(true)

    const information = {
      id: id,
      name: name,
      address: address,
      street: street,
      door: door,
      phoneNumber: phoneNumber,
      email: email
    }

    const isValid = await SchoolInformationSchema
      .validate(information)
      .catch(error => {
        setOpenSnackbar(true)
        setSnackbarType("error")
        setSnackbarMessage(error.errors)
        setLoading(false)

        return
      })

    if(isValid) {
      schoolInformationAPI.putInformation(id,information)
        .then(({data}) => {
            if(data.success){
                setOpenSnackbar(true)
                setSnackbarType("success")
                setSnackbarMessage("Informations modifier avec succès")
            }
            else {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage("Erreur lors de la modification des informations")
            }

            getInformations()

            setLoading(false)

        })
        .catch((e) => {
            setOpenSnackbar(true)
            setSnackbarType("error")
            setSnackbarMessage("Erreur lors de la modification des informations")

            setLoading(false)
        })
    }
  }

  const getInformations = () => {

    setLoading(true)

    schoolInformationAPI.getInformation()
      .then(({data}) => {
        if(!data) {
          setEmptyData(true)
        }
        else {
          // console.log(data);
          setId(data.id ? data.id : -1)
          setName(data.name ? data.name: "N/A")
          setAddress(data.address ? data.address : "N/A")
          setStreet(data.street ? data.street : "N/A")
          setDoor(data.door ? data.door : "N/A")
          setPhoneNumber(data.phoneNumber ? data.phoneNumber : "N/A")
          setEmail(data.email ? data.email : "N/A")
        }
        

        setLoading(false)
      })
      .catch(() => {
        setName("N/A")
        setAddress("N/A")
        setStreet("N/A")
        setDoor("N/A")
        setPhoneNumber("N/A")
        setEmail("N/A")

        setLoading(false)
      })
  }

  useEffect(() => {
    getInformations()

  },[])


  return (
    <Stack>
      <Box display='flex' flexDirection='row-reverse'>
        <LoadingButton size='large' loading={loading} onClick={() => setInformation()} variant='contained' loadingPosition='end' endIcon={<Save />} disableElevation>
            Sauvegarder
        </LoadingButton>
        <IconButton sx={{ border: '2px solid #4a148c', mx: 2 }} color="primary" onClick={() => window.location.reload()}>
          <Refresh color='primary'/>
        </IconButton>
      </Box>
      {
        emptyData && (
          <Box my={2}>
            <EmptyDataAlert />
          </Box>
        )
      }
      <Typography color="primary" variant='subtitle1'>Information</Typography>
      <AppDivider />
      <AppBox>
        <AppTextField 
          label="Nom"
          value={name}
          onClick={(e) => e.target.value === " " && setName("")}
          onChange={(e) => setName(e.target.value)}
          error={!name}
          helperText={
              !name ? "Veuillez entrer le nom" : ""
            }
        />
      </AppBox>
      <AppBox>
        <AppTextField 
          label="Adresse"
          value={address}
          onClick={(e) => e.target.value === " " && setAddress("")}
          onChange={(e) => setAddress(e.target.value)}
          error={!address}
          helperText={
            !address ? "Veuillez entrer l'adresse" : ""
          }
      />
      </AppBox>
      <AppBox>
        <AppTextField 
          label="Rue"
          value={street}
          onClick={(e) => e.target.value === " " && setStreet("")}
          onChange={(e) => setStreet(e.target.value)}
          error={!street}
          helperText={
              !street ? "Veuillez entrer le numéro de rue" : ""
            }
        />
        <AppTextField 
          label="Porte"
          value={door}
          onClick={(e) => e.target.value === " " && setDoor("")}
          onChange={(e) => setDoor(e.target.value)}
          error={!door}
          helperText={
              !door ? "Veuillez entrer le numéro de porte" : ""
            }
        />
      </AppBox>
      <AppBox>
        <AppTextField 
          label="Telephone"
          value={phoneNumber}
          onClick={(e) => e.target.value === " " && setPhoneNumber("")}
          onChange={(e) => setPhoneNumber(e.target.value)}
          error={!phoneNumber}
          helperText={
            !phoneNumber ? "Veuillez entrer le numéro de téléphone" : ""
          }
      />
      </AppBox>
      <AppBox>
        <AppTextField 
          label="Email"
          value={email}
          onClick={(e) => e.target.value === " " && setEmail("")}
          onChange={(e) => setEmail(e.target.value)}
          error={!email}
          helperText={
            !email ? "Veuillez entrer l'adresse email" : ""
          }
      />
      </AppBox>
      <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
    </Stack>
  )
}

export default SchoolInformation