import { Cancel, Delete } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ComfirmDeletionDialog = ({open, setOpen, handleSuccess}) => {

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Box>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle>Comfirmer la suppression</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Êtes-vous sûr de vouloir effectuer cette suppression ?
                </DialogContentText>
                </DialogContent>
                <DialogActions sx={{mx: 1.8 ,display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button fullWidth variant='contained' startIcon={ <Cancel />} onClick={handleClose}>Annuler</Button>
                    <Button fullWidth variant='contained' startIcon={ <Delete />} color='error' onClick={handleSuccess}>Supprimer</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ComfirmDeletionDialog