import { API } from "./index";


const getJob = () => API.get('/job');
const postJob = (data) => API.post('/job', data);


export const jobAPI = {
    getJob,
    postJob
}
