import { Stack, Paper, IconButton } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { serverURL } from '../../config/serverUrl'
import EmptyDataAlert from '../global/EmptyDataAlert'
import { DataGrid, GridToolbar,frFR } from '@mui/x-data-grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PersonalList = ({personalListData}) => {

  //console.log(personalListData);

  const columns = [
    {field: 'niu', headerName:'Niu', flex: 1},
    {field: 'photoUrl', headerName:'Photo', flex: 1, sortable: false, disableColumnMenu: true, renderCell: (cellValue) => <img 
      src={`${serverURL}/profiles/personals/${cellValue.row.photoUrl}`} 
      alt='photo_de_profil'
      style={{ maxHeight: '45px', maxWidth: '45px'}}
    />},
    {field: 'firstName', headerName:'Nom', flex: 1},
    {field: 'lastName', headerName:'Prenom', flex: 1},
    {field: 'birthDate', headerName:'Date de naissance', flex: 1},
    {field: 'age', headerName:'Age', flex: 1},
    {field: 'gender', headerName:'Genre', flex: 1},
    {field: 'phoneNumber', headerName:'Telephone', flex: 1},
    {field: 'jobLabel', headerName:'Classe', flex: 1},
    {field: 'Ouvrir', sortable: false, disableColumnMenu: true, renderCell: (cellValues) => {
      return (
          <IconButton  size="small" onClick={() => navigate(`/profile/personal/${cellValues.row.niu}`)}>
            <ArrowForwardIosIcon/>
          </IconButton>
      )
    }},
  ]

  const dataGridSx = {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    width: '100%',
    borderRadius: 2
  }

  const parseData = (data) => {
    return data.map((d,i) => {
      const age = dayjs().year() - dayjs(d.birthDate).year()
      return {
      ...d,
      id: i + 1,
      jobLabel: d.job.title,
      age: `${age > 1 ? `${age} ans` : '~ 1 an'}`
      //console.log(d);
    }})
  }

  const navigate = useNavigate()

  return (
    <Stack>
      {
        personalListData.length <= 0 && (
          <EmptyDataAlert />
        )
      }
      {
        personalListData.length > 0 && (
          <Paper sx={dataGridSx}>
            <DataGrid 
              rows={parseData(personalListData)} 

              columns={columns} 
              slots={{ toolbar: GridToolbar }} 
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
              ...personalListData.initialState,
              pagination: {
                ...personalListData.initialState?.pagination,
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            />
          </Paper>
        )
      }
    </Stack>

  )
}

export default PersonalList