import React, {useEffect, useState} from 'react'
import { Box, Button, Stack, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogActions  } from '@mui/material'
import { Add, Cancel, Save } from '@mui/icons-material'
import Search from '../components/income/Search'
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import { monthOption } from '../config/defaultValue';
import { yearAPI } from '../api/year';
import { incomeAPI } from '../api/income';
import { LoadingButton } from '@mui/lab';
import IncomeList from '../components/income/IncomeList';
import AppSnackbar from '../components/global/snackbar/Snackbar';
import EmptyDataAlert from '../components/global/EmptyDataAlert';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function Income() {
  
  // const [date, setDate] = useState(dayjs())
  const [year, setYear] = useState("all");
  const [month, setMonth] = useState("all");
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  //disableMonth on year all
  const [disableMonth, setDisableMonth] = useState(true);

  // data list
  const [yearsList, setYearsList] = useState([]);
  const [incomesList, setIncomesList] = useState([]);

  //component dialog / snackbar handler
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  const handleYearChange = (_year) => {

    if(_year !== "all"){
      setDisableMonth(false);
      getIncomes(_year);
    }
    else {
      setDisableMonth(true);
      getIncomes();
    }

    setYear(_year);
    //console.log(_year);

  }

  const handleMonthChange = (_month) => {

    if(_month !== "all"){
      setDisableMonth(false);
      getIncomes(year,_month);
    }else {
      getIncomes(year);
    }

    setMonth(_month);
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (event, raison) => {
    if(["escapeKeyDown", "backdropClick"].includes(raison))
    {
      return;
    }
    
    setOpenDialog(false);
  };

  // store new expene in db
  const addIncome = () => {

    setLoading(true)

    // default
    // const date = dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]') ;
    const date = dayjs().format();
    

    // create income
    const incomeData = {
      receiptNumber: dayjs(date).valueOf(),
      transactionDate: date,
      reason: reason,
      amount: amount,
      by: 1,
      createdAt: date,
      updatedAt: date
    }

    // store income in db
    incomeAPI.postIncome(incomeData)
      .then(({data}) => {
        if(data.success){
          // Show snackbar
          setOpenSnackbar(true);
          setSnackbarType("success")
          setSnackbarMessage("Gain ajouter avec succès");

          // get income from ddb
          getIncomes();
        }
        else{
          setOpenSnackbar(true);
          setSnackbarType("error")
          setSnackbarMessage("Erreur lors de l'enregistrement du gain");
        }
        //set button loading
        setLoading(false);

        // close dialog
        handleCloseDialog();

        // reinitialize state
        setReason("");
        setAmount("");
      })
      .catch((e) => {
        setOpenSnackbar(true);
        setSnackbarType("error")
        setSnackbarMessage("Erreur lors de l'enregistrement du gain");

        setLoading(false);
      })


  }

  // get years from db and update state
  const getYears = () => {
    yearAPI.getYear()
        .then(({data}) => {
            setYearsList(data);
            // console.log(data);
        });
  }

  // get incomes from db and update state
  const getIncomes = (year, month) => {
    // console.log('year', year);
    // console.log('month', month);

    // some year
    if(year) {
      // console.log("year null");
      // some
      if(month) {
        // console.log("year month");
        incomeAPI.getIncome(year,month)
        .then(({data}) => {
            setIncomesList(data);
        });
      } 
      else {
          incomeAPI.getIncome(year,null)
          .then(({data}) => {
              setIncomesList(data);
        });

      }
    }
    else{
      // console.log("all all");

      // All month & all month
      incomeAPI.getIncome()
          .then(({data}) => {
              setIncomesList(data);
              // console.log(data);
          });
    }
  }
 
  // on app launch get year data from db
  useEffect(() => {
      getYears();
      getIncomes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 


  return (
    <>
      <Stack p={5}>
        <Stack display='flex' direction='row' justifyContent='space-between' >
            <Box display='flex' gap={3}>
                <TextField
                    required 
                    select 
                    fullWidth 
                    sx={{ minWidth: '100px' }}
                    label="Année" 
                    size='large'
                    value={year} 
                    onChange={(event) => handleYearChange(event.target.value)}
                >
                    <MenuItem key="all" value="all">Tout</MenuItem>
                    {yearsList.map(({year}) => {
                        return (
                          <MenuItem key={year} value={year}>{year}</MenuItem>
                        )
                      })
                    }
                </TextField>

                <TextField
                    disabled={disableMonth}
                    required 
                    select 
                    fullWidth 
                    sx={{ minWidth: '150px' }}
                    label="Mois" 
                    size='large'
                    value={month} 
                    onChange={(event) => handleMonthChange(event.target.value)}
                  >
                    <MenuItem key="all" value="all">Tout</MenuItem>
                    {monthOption.map((data) => {
                        return (
                         <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                      )
                    })
                        }
                  </TextField>
            </Box>
            <Box display='flex' direction='row' gap={2}>
                <Search />
                 <Button onClick={handleOpenDialog} size='large' variant="contained" className='sidebar-btn' color='primary' disableElevation startIcon={<Add />} sx={{ fontSize: "1rem" }}>
                    Nouveau gain
                  </Button>
            </Box>
        </Stack>

        <Stack mt={5}>
          {
            incomesList.length === 0 && (
              <EmptyDataAlert />
            )
          }

          {
            incomesList.length > 0 && (
              <IncomeList
                incomesList={incomesList}
              />
            )
          }
        </Stack>

        <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}  TransitionComponent={Transition}>
          <DialogTitle>Nouveau gain</DialogTitle>
          <DialogContent>
            <Stack gap={4}>

              <TextField
                required 
                fullWidth 
                sx={{ minWidth: '150px' }}
                label="Libelle/Designation" 
                size='large'
                value={reason} 
                variant="standard"
                onChange={e => setReason(e.target.value)}
              />

              <TextField
                required 
                fullWidth 
                sx={{ minWidth: '150px' }}
                label="Montant" 
                size='large'
                value={amount}
                variant="standard"
                type='number'
                onChange={e => setAmount(e.target.value)}
              />
            </Stack> 
          </DialogContent>
          <DialogActions>
            <Button variant="contained" endIcon={ <Cancel /> } onClick={() => handleCloseDialog()}> Annuler </Button>
            <LoadingButton variant="contained" endIcon={ <Save /> } onClick={() => addIncome()} loadingPosition='end' loading={loading}> Enregistrer </LoadingButton>
          </DialogActions>
        </Dialog>
      </Stack>
      <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />

    </>


  )
}

export default Income