import { TextField } from '@mui/material';
import React from 'react'


const AppTextField = (props) => {

    return (
        <TextField fullWidth required size='small' variant='standard' {...props}></TextField>
    )
}

export default AppTextField