import { API } from "./index";


const getPresence = (niu,year,month) => {
     if(year) {
        if(month) {
            return API.get(`/presence?niu=${niu}&year=${year}&month=${month}`)
        }
        return API.get(`/presence?niu=${niu}&year=${year}`)
    }
    return API.get(`/presence?niu=${niu}`)
}

const postPresence = (data) => API.post('/presence', data);


export const presenceAPI = {
    getPresence,
    postPresence
}
