import {  Paper, Stack } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import EmptyDataAlert from '../global/EmptyDataAlert'
import { DataGrid, GridToolbar,frFR } from '@mui/x-data-grid';

const IncomeList= ({incomesList}) => {

    const parseData = (data) => {
        return data.map((d,i) => {
            return {
                ...d,
                transactionDate: dayjs(d.transactionDate).format('DD/MM/YYYY à HH:MM')
                //console.log(d);
            }
        })
    }

    incomesList = parseData(incomesList)

    const columns = [
        {field: 'receiptNumber', headerName:'Numero de recu', flex: 1},
        {field: 'reason', headerName:'Libelle/Designation', flex: 1},
        // {field: 'by', headerName:'Fait Par', flex: 1},
        {field: 'transactionDate', headerName:'Date', flex: 1},
        {field: 'amount', headerName:'Montant', flex: 1},
    ]

    const dataGridSx = {
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '100%',
        width: '100%',
        borderRadius: 2
    }


    return (
        <Stack>
            {
                incomesList.length <= 0 && (
                <EmptyDataAlert />
                )
            }
            {
                incomesList.length > 0 && (
                <Paper sx={dataGridSx}>
                    <DataGrid 
                    rows={incomesList} 

                    columns={columns} 
                    slots={{ toolbar: GridToolbar }} 
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    pageSizeOptions={[5, 10, 25]}
                    initialState={{
                    ...incomesList.initialState,
                    pagination: {
                        ...incomesList.initialState?.pagination,
                        paginationModel: {
                        pageSize: 10,
                        },
                    },
                    }}
                    />
                </Paper>
                )
            }
        </Stack>
    )
}

export default IncomeList