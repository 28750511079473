import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@mui/material'
import React, { useState } from 'react'
import { personalAPI } from '../../../api/personal';
import AppSnackbar from '../snackbar/Snackbar';
import { LoadingButton } from '@mui/lab';
import { Cancel, KeyboardArrowRight, Save } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SetPasswordDialog = ({ niu, open, close}) => {

    const [openResetFormDialogOpen, setOpenResetFormDialogOpen] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarType, setSnackbarType] = useState("success")


    const resetPassword = () => {
        setIsLoading(true)
        personalAPI.putPersonal(niu, {
            password: password,
            mustChangePassword: true
        })
            .then(({data}) => {
                if(data.success){
                    setOpenSnackbar(true)
                    setSnackbarType("success")
                    setSnackbarMessage("Mot de passe modifier avec succès")
                }
                else {
                    setOpenSnackbar(true)
                    setSnackbarType("error")
                    setSnackbarMessage("Erreur lors de la modification du mot de passe")

                }
                close()
                setOpenResetFormDialogOpen(false)
            })
        setIsLoading(false)
    }

    const openResetForm = () => {
        close()
        setPassword("")
        setPasswordConfirmation("")
        setOpenResetFormDialogOpen(true)
    }


    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={open}
                TransitionComponent={Transition}
                onClose={() => close()}
            >
                <DialogTitle>Reinitialisation</DialogTitle>
                <DialogContent>
                    <DialogContentText>Vous êtes sur le point de reinitialiser le mot de passe de cet utilisateur. Confirmez-vous cette action ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => close()} endIcon={ <Cancel/> }>Annnuler</Button>
                    <Button variant='contained' onClick={() => openResetForm()} endIcon={ <KeyboardArrowRight/> }>Oui</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={openResetFormDialogOpen}
                TransitionComponent={Transition}
                onClose={() => setOpenResetFormDialogOpen(false)}
            >
                <DialogTitle>Entrez le mot de passe</DialogTitle>
                <DialogContent>

                    <TextField
                        margin="dense"
                        label="Mot de passe"
                        fullWidth
                        variant="standard"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Confirmez le mot de passe"
                        fullWidth
                        variant="standard"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => setOpenResetFormDialogOpen(false)} endIcon={<Cancel/>}>Annnuler</Button>
                    <LoadingButton 
                        variant='contained' 
                        onClick={resetPassword} 
                        disabled={password !== passwordConfirmation || !password}
                        endIcon={ <Save/> }
                        loading={isLoading} 
                        loadingPosition="end" 
                    >Reinitialiser</LoadingButton>
                </DialogActions>
            </Dialog>
            <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
        </>
  )
}