import { Box, Stack, Typography, colors, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { personalAPI } from '../../api/personal';
import UserList from './UserList';

const DividerBox = styled(Box)(({ theme }) => ({
    width: "100%", 
    height: "2px", 
    backgroundColor: colors.purple[900], 
    borderRadius: "2px", 
    marginBottom: "15px"
}));

const UserManagement = () => {

  const [administrationsList, setAdministrationsList] = useState([])
  const [professorsList, setProfessorsList] = useState([])

  const getAdministrationList = () => {
    personalAPI.getAdministration()
      .then(({data}) => {
        setAdministrationsList(data)
      })
  }


  const getProfessorList = () => {
    personalAPI.getProfessor()
      .then(({data}) => {
        setProfessorsList(data)
      })
  }

  useEffect(() => {
    getAdministrationList()
    getProfessorList()
  }, [])

  return (
    <>
      <Stack>
        <Box>
          <Typography color="primary" variant='subtitle1'>Personnels administratifs</Typography>
          <DividerBox />
          <UserList userList={administrationsList} reloadData={getAdministrationList} />
        </Box>
        <Box sx={{mt: 5}}>
          <Typography color="primary" variant='subtitle1'>Corps enseignants</Typography>
          <DividerBox />
          <UserList userList={professorsList} reloadData={getProfessorList}/>
        </Box>
      </Stack>
    </>
  )
}

export default UserManagement