import { getDateDifference } from "./getDateDifferense";

export const getTotalMinutes = (array) => {
    let total = 0;
    if(!array) {
        return 0; 
    }
    array.forEach(item => {
        const times = getDateDifference(item.start, item.end)
        const hours = times.split(":")[0]
        const minutes = times.split(":")[1]
        const totalMinutes = (hours * 60) + parseInt(minutes)
        total += totalMinutes
    });
    return total; 
}