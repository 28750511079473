import { cycleFourOption, cycleOneOption, cycleThreeOption, cycleTwoOption } from "./defaultValue";

let countClassroom = 1
let label = ""


export const getClassroomLabel = (classroomList, x_cycle, x_classroom) => {

    if(x_cycle === 1) {
      label = cycleOneOption[0].value
    }

    else if(x_cycle === 2) {
      switch (x_classroom) {
          case 1:
              label = cycleTwoOption[0].value
              break
          case 2: 
              label = cycleTwoOption[1].value
              break
          case 3: 
              label = cycleTwoOption[2].value
              break
          default:
              label = "Erreur"
      }
    }
    else if(x_cycle === 3) {
      switch (x_classroom) {
          case 1:
              label = cycleThreeOption[0].value
              break
          case 2: 
              label = cycleThreeOption[1].value
              break
          case 3: 
              label = cycleThreeOption[2].value
              break
          case 4: 
              label = cycleThreeOption[3].value
              break
          case 5: 
              label = cycleThreeOption[4].value
              break
          case 6: 
              label = cycleThreeOption[5].value
              break
          default:
              label = "Erreur"
      }
    }
    else if(x_cycle === 4) {
        switch (x_classroom) {
          case 1:
              label = cycleFourOption[0].value
              break
          case 2: 
              label = cycleFourOption[1].value
              break
          case 3: 
              label = cycleFourOption[2].value
              break
          default:
              label = "Erreur"
      }
    }

    const cycleData = classroomList.filter(
        (item) => item.cycle === x_cycle
    );

    countClassroom = cycleData.filter(
        (item) => item.classroom === x_classroom
    ).length + 1

    return `${label} (${countClassroom})`
}