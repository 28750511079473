export const combineTwoArrayAndSumByDate = (arr1, arr2) => {
    return [...arr1, ...arr2].reduce((a,v) => {
      let index = a.findIndex(({createdAt}) => createdAt === v.createdAt);
      if(index !== -1) {
         a[index].amount += v.amount;
         return a;
      }
      return [...a, { createdAt:v.createdAt, amount: v.amount }]
   },[])

}