import { useState } from 'react'
import { studentAPI } from '../api/student'
import { Box, Stack, Tab, TextField, Typography } from '@mui/material'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { CalendarMonth, HistoryRounded, Search } from '@mui/icons-material'
import View from '../components/paiement/View'
import History from '../components/paiement/History'

const Paiement = () => {

    const [loading, setLoading] = useState(false)
    const [niu, setNiu] = useState("KOUO250805")
    const [student, setStudent] = useState({})

    const getStudent = () => {
        setLoading(true)
        studentAPI.getStudentByNiu(niu)
            .then(({data}) => {
                setStudent(data)
            })
            .catch((e) => console.log(e))
            .finally(() => setLoading(false))
        
    }


    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const search = () => {
        setLoading(true)

        try {
            getStudent()
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    return (
        <Stack display={'flex'} alignItems={'center'} justifyContent={'center'} mx={'20%'}>
            <Typography variant='h2' textAlign={'start'} width={'100%'}>Paiement par Orange money</Typography>
            <Box display={'flex'} gap={2} width={'100%'} mt={5} alignItems={'start'}>
                <TextField
                    label="Niu"
                    value={niu} 
                    onChange={(e) => setNiu(e.target.value)} 
                    size='small'
                    fullWidth
                    helperText="Trouvez l'élève par son Numéro d'identification unique"
                />
                <LoadingButton loading={loading} onClick={() => search()} variant='contained' disableElevation size='large'>
                    <Search />
                </LoadingButton>
            </Box>
            {

                Object.keys(student).length > 0 && <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} textColor='primary' indicatorColor='primary'>
                            <Tab label="Paiement" value="1" icon={<CalendarMonth />} iconPosition='start'/>
                            <Tab label="Historique" value="2" icon={<HistoryRounded />} iconPosition='start'/>
                        </TabList>
                        </Box>
                        <TabPanel value="1" sx={{  px:0, pt:1 }}>
                            <View niu={niu} />
                        </TabPanel>
                        <TabPanel value="2">
                            <History niu={niu} total={student.classroom.monthlyPrice}/>
                        </TabPanel>
                    </TabContext>
                </Box>
            }

        </Stack>
    )
}

export default Paiement