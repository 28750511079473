import styled from '@emotion/styled';
import { Add, Cancel, Save } from '@mui/icons-material';
import { Box, Button, colors, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { yearAPI } from '../../api/year';
import { LoadingButton } from '@mui/lab';
import AppSnackbar from '../global/snackbar/Snackbar';
import EmptyDataAlert from '../global/EmptyDataAlert';

var localeData = require('dayjs/plugin/localeData')
dayjs.extend(localeData)


const DividerBox = styled(Box)(({ theme }) => ({
    width: "100%", 
    height: "2px", 
    backgroundColor: colors.purple[900], 
    borderRadius: "2px", 
    marginBottom: "15px"
}));


const YearList = () => {

    // state initialization
    const [yearsList, setYearsList] = useState([])
    const [year, setYear] = useState(dayjs());
    const [loading, setLoading] = useState(false);

    const disabledYear = (y) => {
        return yearsList.some(({ year }) => year === dayjs(y).year());
    };

    //component dialog / snackbar handler
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState("success");
  
    // open dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // close dialig
    const handleCloseDialog = (event, raison) => {

        if(["escapeKeyDown", "backdropClick"].includes(raison))
        {
        return;
        }
    
        setOpenDialog(false);
    };

    const addYear = () => {
        // set button loading
        setLoading(true);

        // post year data to server
        yearAPI.postYear({year: parseInt(year.year())})
            // on function finish
            .then(({data}) => {
                //console.log(data);
                if(data.success){
                    //Show snackbar
                    setSnackbarMessage("Année ajouter avec succès");
                    setSnackbarType("success");
                    setOpenSnackbar(true);

                    // get year from databse
                    getYears();
                }
                else {
                    setSnackbarMessage("Erreur lors de l'enregistrement de l'année");
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                }
                // set year to current year
                setYear(dayjs());

                 //close dialog
                handleCloseDialog();

                //set button loading
                setLoading(false);

            });

    }

    // get year from db and update state
    const getYears = () => {
        yearAPI.getYear()
            .then(({data}) => {
                setYearsList(data)
                // console.log(data);
            });
    }
 
    // on app launch get year data from db
    useEffect(() => {
       getYears();
    }, [])

    return (
        <>
            <Stack>
                <Box width="100%" display="flex" flexDirection="column" alignItems="end">
                    <Button size='large' variant='contained' endIcon={ <Add /> } onClick={() => handleOpenDialog()}>Ajouter une année</Button>
                </Box>
                <Typography color="primary" variant='subtitle1'>Année scolaire</Typography>
                <DividerBox />

                {
                    yearsList.length === 0 && (
                        <EmptyDataAlert />
                    )
                }

                {
                    yearsList.length > 0 && (
                        <Stack display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                            {
                                yearsList.map(({year}) => (
                                    <Box key={year} display="flex" width="150px" px={1} py={4} borderRadius={3} border={0.8} justifyContent="center" alignItems="center">
                                        <Typography>{year}</Typography>
                                    </Box>
                                ))

                            }
                        </Stack>
                    )
                }

            </Stack>
            <Dialog open={openDialog}>
                <DialogTitle>
                    <Typography>Ajouter une nouvelle année</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ py:1}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                             <DatePicker
                                shouldDisableYear={disabledYear}
                                views={['year']}
                                label="Année"
                                value={year}
                                onChange={(value) => {setYear(value) }}
                                minDate={dayjs().subtract(7, 'year')}
                                maxDate={dayjs()}
                                openTo="year"
                                renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                            />
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p:2 }}>
                    <Box display="flex" flexDirection="row" gap={2}>
                        <Button variant="contained" endIcon={ <Cancel /> } onClick={() => handleCloseDialog()}> Annuler </Button>
                        <LoadingButton variant="contained" endIcon={ <Save /> } onClick={() => addYear()} loadingPosition='end' loading={loading}> Enregistrer </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
        </>
    )
}

export default YearList