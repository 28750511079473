import { FormatListBulleted } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { MenuItem, Stack, Tab, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import SearchBar from '../components/professor/Search'
import { cycleOption } from '../config/defaultValue'
import { classroomAPI } from '../api/classroom'
import ProfessorList from '../components/professor/ProfessorList'

const Professor = () => {

  const [classroomsList, setClassroomsList] = useState([])

  const [cycle, setCycle] = useState('all')
  const [classroom, setClassroom] = useState('all')

  const [disbableClassroom, setDisbableClassroom] = useState(true)

  const [classroomOptions, setClassroomOptions] = useState([])

  const handleCycleChange = (event) => {
      const _cycle = event.target.value

      if(_cycle === 'all'){
        setDisbableClassroom(true)
      }
      else 
      {
        setDisbableClassroom(false)

        if(_cycle === 1){
            setClassroomOptions(cycleOneData)
            //console.log(1)
        }
        else if(_cycle === 2){
            setClassroomOptions(cycleTwoData)
        }
        else if(_cycle === 3) {
            setClassroomOptions(cycleThreeData)
        }
        else if(_cycle === 4) {
            setClassroomOptions(cycleFourData)
        }
        else {
          setClassroomOptions([])
        }

      }

      setClassroom('all')
      setCycle(_cycle)
  }

  const handleClassroomChange = (event) => {
        const _classroom = event.target.value
        setClassroom(_classroom)
  }

  // Tab
  const [value, setValue] = useState("1")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // get classroom from db and update state
  const getClassrooms = () => {
      classroomAPI.getClassroom()
        .then(({data}) => {
            setClassroomsList(data)
            //console.log(data)
        })
  }


  useEffect(() => {
    getClassrooms()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // example for map and then filter some speficic value // rename value
  // let cycleOneData = classroomsList.filter((item) => item.cycle === 1).map(({id, label}) => ({id,label}))
  // const cycleOneData = classroomsList.filter((item) => item.cycle === 1).map(({id, label}) => ({key: id,value: label}))


  const cycleOneData = classroomsList.filter((item) => item.cycle === 1)

  const cycleTwoData = classroomsList.filter((item) => item.cycle === 2)

  const cycleThreeData = classroomsList.filter((item) => item.cycle === 3)

  const cycleFourData = classroomsList.filter((item) => item.cycle === 4)

  //console.log('lengh',studentsList.length)

  return (
    <Stack pt={3}>
        <Stack display='flex' flexDirection='row' justifyContent='space-between' mt={2} mx={2.5} gap={2} id='topBar'>
            <Stack display='flex' flexDirection='row' gap={2}>
              <TextField
                required 
                select 
                fullWidth 
                label="Choix de cycle" 
                size='large' 
                value={cycle} 
                onChange={handleCycleChange}
                sx={{ minWidth: '180px' }}
               >
                  <MenuItem value='all'>Tout</MenuItem>
                  {
                    cycleOption.map(data => (
                      <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem> 
                    ))
                  }
                </TextField>
                <TextField
                  required 
                  select 
                  fullWidth 
                  label="Choix de la classe" 
                  size='large' 
                  value={classroom} 
                  onChange={handleClassroomChange}
                  sx={{ minWidth: '180px' }}
                  disabled={disbableClassroom}
                >
                    <MenuItem value='all'>Tout</MenuItem>
                    {classroomOptions.map(data => (
                          <MenuItem key={data.id} value={data.id}>{data.label}</MenuItem>
                        )
                      )
                    }
                    </TextField>
            </Stack>
            <Stack display='flex' flexDirection='row' gap={2}>
              {/* <Button size='large' variant="contained" className='sidebar-btn' color='primary' disableElevation startIcon={<Search />} sx={{ fontSize: "1rem" }}>Chercher</Button> */}
              <SearchBar />
            </Stack>
        </Stack>
        <Stack>
          <TabContext value={value}>
            <Stack borderBottom={1} borderColor='#e8e8e8' display='flex' flexDirection='row' mr={2}>
                <TabList onChange={handleChange} textColor='primary' indicatorColor='primary'>
                    <Tab value={"1"} icon={<FormatListBulleted />} iconPosition='start' label='Liste'/>
                </TabList>
            </Stack>
            <TabPanel value={"1"} >
                <ProfessorList  cycle={cycle} classroom={classroom} classroomList={classroomsList} />
            </TabPanel> 
          </TabContext>
        </Stack>
    </Stack>
  )
}

export default Professor