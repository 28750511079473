import { ErrorOutline } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function Error() {
  return (
    <Stack height='100vh' pt={18} alignItems='center' bgcolor='primary.main'>
      <Stack color={'white'}>
        <ErrorOutline sx={{ fontSize: '11rem', lineHeight: .7 }}/>
      </Stack>
      <Typography color='white' sx={{ fontSize: "16rem", lineHeight: .8, fontWeight: 'bold' }} >Oops</Typography>
      <Typography color='white' variant='h3' mt={7}>404 - Page introuvable</Typography>
      <Typography color='white' variant='body1' mt={1}>Désole, la page que vous rechercher n'existe pas.</Typography>
      <Button variant="contained" size='large' className='sidebar-btn' color={'secondary'} disableElevation sx={{ fontSize: "1rem", marginTop: "1.5rem", borderRadius: 5 }}>
        <Link to='/' className='text-decoration-none text-capitalize' style={{ color: 'inherit' }} >Acceuil</Link>
      </Button>
    </Stack>
  )
}

export default Error