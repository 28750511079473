import { ManageAccounts, Person } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Alert, AlertTitle, Stack, Tab } from '@mui/material'
import React, { useEffect, useState } from 'react'


import { classroomAPI } from '../api/classroom'
import  ClassroomSubscribe from '../components/classroom/Subscribe'
import  AdministrationSubscribe from '../components/personal/Subscribe'
import useAuth from '../hooks/useAuth'



const Subscribe = () => {
  const { auth } = useAuth()

  const [classroomsList, setClassroomsList] = useState([])

  // Tab
  const [value, setValue] = useState("1")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // get classroom from db and update state
  const getClassrooms = () => {
      classroomAPI.getClassroom()
        .then(({data}) => {
            setClassroomsList(data)
            //console.log(data)
        })
  }

  useEffect(() => {
    getClassrooms()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // example for map and then filter some speficic value // rename value
  // let cycleOneData = classroomsList.filter((item) => item.cycle === 1).map(({id, label}) => ({id,label}))
  // const cycleOneData = classroomsList.filter((item) => item.cycle === 1).map(({id, label}) => ({key: id,value: label}))


  const cycleOneData = classroomsList.filter((item) => item.cycle === 1)

  const cycleTwoData = classroomsList.filter((item) => item.cycle === 2)

  const cycleThreeData = classroomsList.filter((item) => item.cycle === 3)

  const cycleFourData = classroomsList.filter((item) => item.cycle === 4)

  //console.log('lengh',studentsList.length)
  //console.log(cycleOneData);

  return (
    <Stack>
        <Stack>
           <TabContext value={value}>
            <Stack borderBottom={1} borderColor='#e8e8e8' display='flex' flexDirection='row' mr={2} justifyContent='flex-end'>
                <TabList onChange={handleChange} textColor='primary' indicatorColor='primary'>
                    <Tab value={"1"} icon={<Person />} iconPosition='start' label='Eleves'/>
                    {
                      (auth?.role === "administrateur" || auth?.role === "directeur") && (
                        <Tab value={"2"} icon={<ManageAccounts />} iconPosition='start' label='Administration'/> 
                      )
                    }
                </TabList>
            </Stack>
            <TabPanel value={"1"} sx={{ p:'0' }}>
              {
                (
                  !cycleOneData.length > 0   ||
                  !cycleTwoData.length > 0   ||
                  !cycleThreeData.length > 0 ||
                  !cycleFourData.length > 0
               ) 
               && 
               ( <Alert severity="info" sx={{m: 2}}>
                      <AlertTitle>Information</AlertTitle>
                      Classe non créer ou manquante — <strong>Veuillez créez les classes !</strong>
                  </Alert> 
               )
              }
              {
                cycleOneData.length > 0 && 
                cycleTwoData.length > 0 &&
                cycleThreeData.length > 0 &&
                cycleFourData.length > 0 && 
                ( <ClassroomSubscribe cycleOneData={cycleOneData} cycleTwoData={cycleTwoData} cycleThreeData={cycleThreeData} cycleFourData={cycleFourData} /> )
              }
            </TabPanel>
            <TabPanel value={"2"} sx={{ p:'0' }} >
              {
                (
                  !cycleOneData.length > 0   ||
                  !cycleTwoData.length > 0   ||
                  !cycleThreeData.length > 0 ||
                  !cycleFourData.length > 0
               ) 
               && 
               ( <Alert severity="info" sx={{m: 2}}>
                      <AlertTitle>Information</AlertTitle>
                      Classe non créer ou manquante — <strong>Veuillez créez les classes !</strong>
                  </Alert> 
               )
              }
              {
                cycleOneData.length > 0 && 
                cycleTwoData.length > 0 &&
                cycleThreeData.length > 0 &&
                cycleFourData.length > 0 && 
                ( <AdministrationSubscribe cycleOneData={cycleOneData} cycleTwoData={cycleTwoData} cycleThreeData={cycleThreeData} cycleFourData={cycleFourData} /> )
              }
            </TabPanel> 
            
        </TabContext>
        </Stack>

       
    </Stack>
  )
}

export default Subscribe