import { API } from "./index";

const getStudentMonthByYearAndNiu = (year,niu) => API.get(`/year/student/${year}/${niu}`);
const getPersonalMonthByYearAndNiu = (year,niu) => API.get(`/year/personal/${year}/${niu}`);
const getMonthByYear = (year) => API.get(`/year/${year}`);
const getYear = () => API.get('/year');
const postYear = (data) => API.post('/year', data);


export const yearAPI = {
    getStudentMonthByYearAndNiu,
    getPersonalMonthByYearAndNiu,
    getMonthByYear,
    getYear,
    postYear
}
