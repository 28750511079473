import { useEffect } from 'react'

const useActivity = () => {

    const updateExpireTime = () => {

        const expireTime = Date.now() + 1800000 // 30 min

        localStorage.setItem('expireTime', expireTime)
    }

    useEffect(() => {

        updateExpireTime()

        window.addEventListener('click', updateExpireTime)
        window.addEventListener('keypress', updateExpireTime)
        window.addEventListener('scroll', updateExpireTime)
        window.addEventListener('mousemove', updateExpireTime)

        return () => {
            window.removeEventListener('click', updateExpireTime)
            window.removeEventListener('keypress', updateExpireTime)
            window.removeEventListener('scroll', updateExpireTime)
            window.removeEventListener('mousemove', updateExpireTime)
        }

    }, [])

    return updateExpireTime

}

export default useActivity