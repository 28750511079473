import { Button, TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import PaiementPreviewDialog from './PaiementPreviewDialog'

const PaiementItem = ({receipt, resource}) => {
    console.log(receipt);

    const [openDialog, setOpenDialog] = React.useState(false)
    const rest = receipt.balance - receipt.amount

    return (
        <>
            <TableRow key={receipt.receiptNumber} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                    <Button onClick={() => setOpenDialog(true)}>{receipt.receiptNumber}</Button>
                </TableCell>
                <TableCell align="center">{receipt.amount}</TableCell>
                <TableCell align="center">{receipt.balance}</TableCell>
                <TableCell align="center">{rest}</TableCell>
                <TableCell align="center">{receipt.total - rest}</TableCell>
                <TableCell align="center">
                    {dayjs().month(receipt.yearMonthId.substr(-2) - 1).year(receipt.yearMonthId.substr(0,4)).format('MMMM, YYYY')}
                </TableCell>
                <TableCell align="right">{dayjs(receipt.createdAt).format('DD MMMM YYYY, HH:mm:ss')}</TableCell>
            </TableRow>
            <PaiementPreviewDialog 
                receipt={receipt}
                resource={resource}
                openDialog={openDialog}
                setOpenDialog={(value) => setOpenDialog(value)}
            />
        </>
  )
}

export default PaiementItem