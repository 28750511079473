import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import React, { useState } from 'react'
import { personalAPI } from '../../../api/personal';
import AppSnackbar from '../snackbar/Snackbar';
import { Cancel, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ChangeUserStatusDialog = ({ niu, title, message, open, close, reloadData, status}) => {

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarType, setSnackbarType] = useState("success")
    const [isLoading, setIsLoading] = useState(false)


    const changeStatus = () => {
        setIsLoading(true)
        personalAPI.putPersonal(niu, {isActive: status})
            .then(({data}) => {
                if(data.success){
                    setOpenSnackbar(true)
                    setSnackbarType("success")
                    setSnackbarMessage("Statut modifier avec succès")

                    reloadData()
                }
                else {
                    setOpenSnackbar(true)
                    setSnackbarType("error")
                    setSnackbarMessage("Erreur lors de la modification des informations")

                }
                close()
            })
        setIsLoading(false)
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={open}
                TransitionComponent={Transition}
                onClose={() => close()}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => close()} endIcon={ <Cancel/> }>Annnuler</Button>
                    <LoadingButton
                     variant='contained' 
                     onClick={changeStatus} 
                     endIcon={ <Save/> }
                     loading={isLoading} 
                     loadingPosition="end" 
                    >Oui</LoadingButton>
                </DialogActions>
            </Dialog>
            <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
        </>
  )
}