import { Alert, AlertTitle, Backdrop, CircularProgress, colors, Grid, Stack, TextField, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import React, { useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom';
import { authAPI } from '../api/auth';
import useAuth from '../hooks/useAuth';
import { LoadingButton } from '@mui/lab';


function Login() {

  const [niu, setNiu] = useState(" ")
  const [password, setPassword] = useState(" ")
  const [failed, setFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const { auth, setAuth } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/classroom" 

  useEffect(() => {
    if(auth?.niu) {
      navigate(from, { replace: true })
    }
    setPageLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isExpired, setIsExpired] = useState(urlParams.has('expired'))

  const removeUrlParameter = (paramKey) =>  {
    const url = window.location.href
    // console.log("url", url)
    var r = new URL(url)
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    // console.log("r.href", newUrl)
    window.history.pushState({ path: newUrl }, '', newUrl)
  }


  const handleLogin = async () => 
  {
    setIsLoading(true)

    removeUrlParameter('expired')
    setIsExpired(false)
    if(!niu.trim() || !password.trim()) {
      setNiu(niu.trim())
      setPassword(password.trim())
      return
    }
    // Login
    try {
      const response = await authAPI.postLogin({
        niu: niu.trim(),
        password: password.trim()
      })

      if(!response?.data?.success) {
        setPassword('')
        setFailed(true)
        setErrorMessage("Nous n'avons pas réussi à vous identifier. Veuillez vérifier vos informations et essayez de nouveau.")
        setIsLoading(false)
        return
      }


      const role = response?.data?.role.toLowerCase()
      const mustChangePassword = response?.data?.mustChangePassword
      const fullName = response?.data?.fullName
      setAuth({niu, fullName, mustChangePassword, role})
      localStorage.setItem('auth',JSON.stringify({niu, fullName, mustChangePassword, role}))

      //console.log(role);

      setFailed(false)
      setIsLoading(false)

      setNiu('')
      setPassword('')

      if(response?.data?.mustChangePassword) {
        navigate("/changePassword", { replace: true })
        return
      }

      navigate(from, { replace: true })

    } catch (error) {
      console.log("Error", error);
    }

  }

  return (
    <Stack>
      {
      pageLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={pageLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        )
      }
      {
        !pageLoading && (
          <Grid container>
            <Grid item xs={9} bgcolor='primary.main' minHeight={'100vh'}>

            </Grid>
            <Grid item xs={3} px={3}>
              <Stack display='flex' mt={20} p={3} bgcolor='common.white' borderRadius={3} border={1}  borderColor={colors.grey['200']}>
                  <Stack gap={2}>
                    <Typography variant='h5' sx={{mb: 2}}>Connexion</Typography>
                    <TextField
                      size='small'
                      label="Niu" 
                      required 
                      fullWidth
                      value={niu}
                      onClick={(e) => e.target.value === " " && setNiu("")}
                      onChange={(e) => setNiu(e.target.value)} 
                      error={!niu}
                      helperText={
                          !niu ? "Veuillez entrer le nom d'utilisateur" : ""
                      }
                  />
                  <TextField
                      size='small'
                      label="Mot de passe"
                      type='password'
                      required 
                      fullWidth
                      value={password}
                      onClick={(e) => e.target.value === " " && setPassword("")}
                      onChange={(e) => setPassword(e.target.value)} 
                      error={!password}
                      helperText={
                          !password ? "Veuillez entrer le mot de passe" : ""
                      }
                  />
                  </Stack>
                  <Stack mt={2} display='flex' direction='row' justifyContent='end'>
                      <LoadingButton 
                        fullWidth 
                        variant='contained' 
                        onClick={() => handleLogin()} 
                        endIcon={ <LoginIcon /> } 
                        loading={isLoading} 
                        loadingPosition="end" 
                        disabled={!niu.trim() || !password.trim() }
                      >Connexion</LoadingButton>
                  </Stack>
              </Stack>
              {
                failed && (
                  <Alert sx={{ mt:5 }} severity="error">
                    <AlertTitle>Tentative de connexion invalide</AlertTitle>
                    Erreur — <strong>{errorMessage}</strong>
                  </Alert>
                )
              }
              {
                isExpired && (
                  <Alert sx={{ mt:5 }} severity="info">
                    <AlertTitle>Vous avez été deconnecté</AlertTitle>
                    Deconnection — <strong>En raison d'une inactivité de 30 min, vous avez été deconnecter</strong>
                  </Alert>
                )
              }
            </Grid>
          </Grid>
        )
      }
      
    </Stack>
   
  )
}

export default Login