import { API } from "./index";

const getPaiement = () => API.get('/paiement');
const getStudentPaiementByNiu = (niu) => API.get(`/paiement/student/${niu}`);
const getPersonalPaiementByNiu = (niu) => API.get(`/paiement/personal/${niu}`);
const postPaiement = (data) => API.post('/paiement', data);


export const paiementAPI = {
    getPaiement,
    getStudentPaiementByNiu,
    getPersonalPaiementByNiu,
    postPaiement
}
