import { Box, colors, Grid, Stack, Typography } from '@mui/material'
import React from 'react'

const MonthFeed = () => {
  return (
   <Grid container rowGap={3}>
        <Grid item xs={6}>
            <Typography variant='body2' color='grey.500'>Inactif</Typography>
            <Stack display='flex' flexDirection='row' alignItems='center'>
            <Box sx={{ backgroundColor: colors.grey['500'], height: '22px', width: '50%', ml: '2px', marginTop: '8px' }}>
            </Box>
        </Stack> 
        </Grid>
        <Grid item xs={6}>
            <Typography variant='body2' color='grey.500'>En attente</Typography>
            <Stack display='flex' flexDirection='row' alignItems='center'>
            <Box sx={{ backgroundColor: colors.yellow['800'], height: '22px', width: '50%', ml: '2px', marginTop: '8px' }}>
            </Box>

        </Stack> 
        </Grid>
        <Grid item xs={6}>
        <Typography variant='body2' color='grey.500'>Complet</Typography>
        <Stack display='flex' flexDirection='row' alignItems='center'>
            <Box sx={{ backgroundColor: colors.green['700'], height: '22px', width: '50%', ml: '2px', marginTop: '8px' }}>
            </Box>

            
        </Stack> 
        </Grid>
        <Grid item xs={6}>
        <Typography variant='body2' color='grey.500'>Incomplet</Typography>
        <Stack display='flex' flexDirection='row' alignItems='center'>
            <Box sx={{ backgroundColor: '#ff0000', height: '22px', width: '50%', ml: '2px', borderRadius: '2px', marginTop: '8px' }}>
            </Box>
        </Stack> 
        </Grid>
    </Grid>
  )
}

export default MonthFeed