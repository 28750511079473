import { API } from "./index";
// setting years

const getIncome = (year,month) => {
    if(year) {
        if(month) {
            return API.get(`/income?year=${year}&month=${month}`)
        }
        return API.get(`/income?year=${year}`)
    }
    
    return API.get(`/income`)
   
};

const getIncomeByChar = (char) => API.get(`/income/search/${char}`);

const postIncome = (data) => API.post('/income', data);


export const incomeAPI = {
    getIncome,
    postIncome,
    getIncomeByChar
}
