export const combineTwoArrayByIndex = (arr1, arr2) => {
    const obj = {
        "p1":arr1,
        "p2":arr2
    }

    let result = [];
    result = Object.keys(obj).reduce(function (hash) {
        return function (r, k) {
            obj[k].forEach(function (o) {
                if (!hash[o.createdAt]) {
                hash[o.createdAt] = {};
                r.push(hash[o.createdAt]);
                }
                Object.keys(o).forEach(function (l) {
                hash[o.createdAt][l] = o[l];
                });
            });
            return r;
        };
    }(Object.create(null)), []).sort((a, b) => {
        return a['createdAt'] - b['createdAt'];
    });
    return result;
}