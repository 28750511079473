import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import React from 'react'
import File from './File';


const PDF = () => {
  return (
    <>
        <PDFDownloadLink document={<File />} fileName="recu">
            {({loading}) => (loading ? <button> Chargement du document </button> : <button>telecharger</button>)}
        </PDFDownloadLink>

        <PDFViewer style={{ width: "100%", height: '800px'}}>
            <File />
        </PDFViewer>
       
    </>
    
  )
}

export default PDF