import styled from '@emotion/styled';
import { Box } from '@mui/system';
import React from 'react'

const Container = styled(Box)(({ theme }) => ({
    display:"flex",
    flexDirection:"row",
    gap: "20px",
    marginBottom: "15px",
}));

const AppBox = (props) => {
  return (
    <Container {...props} />
  )
}

export default AppBox