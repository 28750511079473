import { Box, Grid, Stack, Typography, colors } from '@mui/material';
import React, { useEffect, useState } from 'react'
import LoadingBackdrop from '../global/LoadingBackdrop';
import MonthItem from '../profile/month/MonthItem';
import { monthOption } from '../../config/defaultValue';
import { studentAPI } from '../../api/student';
import { yearAPI } from '../../api/year';
import dayjs from 'dayjs';

const curentMonth = dayjs().month() + 1;

const View = ({niu}) => {

    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [monthsList, setMonthsList] = useState([])

    const parseMonth = (list) => list.map(data => {
        // before = and correct to === 
        let month = monthOption.find(i => i.key === data.month)

        data['monthName'] = month.value

        return data
    })

    const getStudent = () => {
        setLoading(true)
        studentAPI.getStudentByNiu(niu)
            .then(({data}) => {
                setTotal(data.classroom.monthlyPrice)
                //setTotal(45000)
                //console.log(data);
            })
            .catch((e) => console.log(e))
            .finally(() => setLoading(false))
    }


    const getMonths = (_year)  => {
        setLoading(true)
        yearAPI.getStudentMonthByYearAndNiu(_year, niu)
            .then(({data}) => {
                setMonthsList(parseMonth(data))
                // console.log(data);
            })
            .catch((e) => console.log(e))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getStudent()
        getMonths(dayjs().year())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}  width={'100%'}>
            <Box>
                {
                    loading && <LoadingBackdrop />
                }
                {
                    !loading && <>
                        {
                            monthsList.length > 0 && <Box display={'flex'} gap={1.4} justifyContent={'end'}>
                                <Box>
                                    <Typography variant='caption' color='grey.500'>Complet</Typography>
                                    <Stack display='flex' flexDirection='row' alignItems='center'>
                                        <Box sx={{ backgroundColor: colors.green['700'], height: '22px', width: '50px', marginTop: '2px' }}>
                                        </Box>
                                    </Stack> 
                                </Box>
                                <Box>
                                    <Typography variant='caption' color='grey.500'>Incomplet</Typography>
                                    <Stack display='flex' flexDirection='row' alignItems='center'>
                                        <Box sx={{ backgroundColor: colors.red['700'], height: '22px', width: '50px', marginTop: '2px' }}>
                                        </Box>
                                    </Stack> 
                                </Box>
                            </Box>
                        }
                    <Grid container bgcolor='backgroundColor.main' mt={2}>
                        {
                            monthsList.map(data => {
                                let paid =  0;
                                if(data.paiements.length > 0) {
                                    data.paiements.map(i => paid += i.amount)
                                }

                                let _color = null
                                const enabled = true
                                //if(data.enabled){
                                if(enabled){
                                    if(paid === total){
                                        _color = colors.green['700']
                                    }
                                    else if(paid < total)
                                    {
                                        if(data.id === curentMonth){
                                            _color = colors.yellow['800']
                                        }
                                        else {
                                            _color = colors.red['700']
                                        }
                                    }
                                }else {
                                    _color = colors.grey['300']
                                }

                                return (
                                    <MonthItem 
                                        key={data.id} 
                                        paid={paid}
                                        total={total}
                                        color={_color}
                                        monthName={data.monthName}
                                        monthId={data.id}
                                        niu={niu}
                                        kind={2}
                                    />
                                )
                            })
                        }
                    </Grid>
                    </>
                } 
            </Box>
        </Stack>
    )
}

export default View