import { API } from "./index";
// setting years

const getExpense = (year,month) => {
    if(year) {
        if(month) {
            return API.get(`/expense?year=${year}&month=${month}`)
        }
        return API.get(`/expense?year=${year}`)
    }
    
    return API.get(`/expense`)
   
};

const getExpenseByChar = (char) => API.get(`/expense/search/${char}`);

const postExpense = (data) => API.post('/expense', data);


export const expenseAPI = {
    getExpense,
    postExpense,
    getExpenseByChar
}
