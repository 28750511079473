import { Box, Button, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import EmptyDataAlert from '../global/EmptyDataAlert'
import { useNavigate } from 'react-router-dom'
import { FiberManualRecord, MoreVert } from '@mui/icons-material'
import { green, red } from '@mui/material/colors'
import { serverURL } from '../../config/serverUrl'
import UserListMenu from './UserListMenu'



const UserList = ({userList, reloadData}) => {

    const [isActive, setIsActive] = useState(null)
    const [niu, setNiu] = useState(null)
    const [open, setOpen] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null)
    // const open = Boolean(anchorEl);
    const openMenu = (event, _niu, _isActive) => {
        setNiu(_niu)
        setIsActive(_isActive)
        setAnchorEl(event.currentTarget)
        setOpen(true)
    };


    const navigate = useNavigate()
    return (
        <>
            <Stack>
                {
                    userList.length <= 0 && (
                        <EmptyDataAlert />
                    )
                }
                {
                    userList.length > 0 && (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Niu</TableCell>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Photo</TableCell>
                                        <TableCell>Prenom</TableCell>
                                        <TableCell>Poste</TableCell>
                                        <TableCell>Statut</TableCell>
                                        <TableCell align='right'>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        userList.map(row => {
                                        return (
                                            <TableRow key={row.niu} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell>
                                                    <Button onClick={() => navigate(`/profile/personal/${row.niu}`)}>{row.niu}</Button>
                                                </TableCell>
                                                <TableCell>
                                                    <img 
                                                        src={`${serverURL}/profiles/personals/${row.photoUrl}`} 
                                                        alt='photo_de_profil'
                                                        className='rounded-circle' 
                                                        style={{ maxHeight: '45px', maxWidth: '45px'}}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.lastName}</TableCell>
                                                <TableCell>{row.firstName}</TableCell>
                                                {
                                                    row.jobId === null ? <TableCell>{row.classroom.label}</TableCell> : <TableCell>{row.job.title}</TableCell>
                                                }
                                                <TableCell>
                                                    {
                                                        row.isActive && (
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: 'rgb(237,250,241)', borderRadius: 3, px: 1, py: 0.5, width: '63px'}}>
                                                                <FiberManualRecord sx={{ fontSize: 10, color: green[500] }} />
                                                                <Typography variant='body2' sx={{ color: green[500], fontWeight: 'bold' , ml: 0.5 }}>Oui</Typography>
                                                            </Box>
                                                        )
                                                    }
                                                    {
                                                        !row.isActive && (
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: 'rgb(247,233,236)', borderRadius: 3, px: 1, py: 0.5, width: '63px'}}>
                                                                <FiberManualRecord sx={{ fontSize: 10, color: red[500] }} />
                                                                <Typography variant='body2' sx={{ color: red[500], fontWeight: 'bold' , ml: 0.5 }}>Non</Typography>
                                                            </Box>
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <IconButton onClick={(event) => openMenu(event, row.niu, row.isActive)}>
                                                        <MoreVert />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                      })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </Stack>
            <UserListMenu isActive={isActive} open={open} setOpen={setOpen} anchorEl={anchorEl} setAnchorEl={setAnchorEl} niu={niu} reloadData={reloadData}/>
            
        </>
    )
}

export default UserList