import { cycleFourOption, cycleOneOption, cycleThreeOption, cycleTwoOption } from "./defaultValue"

let countClassroom = 0
let currentClassroom = 0
let currentCycle = 0

export const parseClassroom = (list) => list.map(data => {
    // count double  or more classroom
    let classroom = data.classroom
    let cycle = data.cycle


    if(currentCycle !== cycle) {
        countClassroom = 0
    }
    else {
     if(currentClassroom === classroom) {
        countClassroom += 1
      }
      else {
        countClassroom = 0
      }
    }


    currentClassroom = classroom
    currentCycle = cycle

   
  
    // replace label
    let label = ""
    if(data.cycle === 1) {
      label = cycleOneOption[0].value
    }

    else if(data.cycle === 2) {
      switch (data.classroom) {
          case 1:
              label = cycleTwoOption[0].value
              break
          case 2: 
              label = cycleTwoOption[1].value
              break
          case 3: 
              label = cycleTwoOption[2].value
              break
          default:
              label = "Erreur"
      }
    }
    else if(data.cycle === 3) {
      switch (data.classroom) {
          case 1:
              label = cycleThreeOption[0].value
              break
          case 2: 
              label = cycleThreeOption[1].value
              break
          case 3: 
              label = cycleThreeOption[2].value
              break
          case 4: 
              label = cycleThreeOption[3].value
              break
          case 5: 
              label = cycleThreeOption[4].value
              break
          case 6: 
              label = cycleThreeOption[5].value
              break
          default:
              label = "Erreur"
      }
    }
    else if(data.cycle === 4) {
        switch (data.classroom) {
          case 1:
              label = cycleFourOption[0].value
              break
          case 2: 
              label = cycleFourOption[1].value
              break
          case 3: 
              label = cycleFourOption[2].value
              break
          default:
              label = "Erreur"
      }
    }



    if(countClassroom > 0) {
      data['label'] = `${label} (${countClassroom})`
    }
    else {
      data['label'] = label
    }

    return data
  })