import { Password, Person, PersonOff } from '@mui/icons-material'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { ChangeUserStatusDialog } from '../global/dialog/ChangeUserStatusDialog'
import { SetPasswordDialog } from '../global/dialog/SetPasswordDialog'

const UserListMenu = ({isActive, open, setOpen, anchorEl, setAnchorEl, niu, reloadData}) => {

    // const open = Boolean(anchorEl);
    const [statusDialogTitle, setStatusDialogTitle] = useState("")
    const [statusDialogMessage, setStatusDialogMessage] = useState("")
    const [statusDialogNiu, setStatusDialogNiu] = useState("")
    const [statusDialogValue, setStatusDialogValue] = useState("")
    const [openStatusDialog, setOpenStatusDialog] = useState(false)

    const [resetDialogNiu, setResetDialogNiu] = useState("")
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false)

    const OpenResetPasswordDialog = () => {
        setOpenResetPasswordDialog(true);
    };

    const OpenStatusDialog = () => {
        setOpenStatusDialog(true);
    };

     const CloseStatusDialog = () => {
        setOpenStatusDialog(false);
    };

    const CloseResetPasswordDialog = () => {
        setOpenResetPasswordDialog(false);
    };

    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null)
    };

    const setUserActive = (niu) => {
        // console.log(niu);
        handleClose()
        setStatusDialogTitle("Activation")
        setStatusDialogMessage("Une fois activer, cet utilisateur pourra se connecter. Confirmez-vous cette action ?")
        setStatusDialogNiu(niu)
        setStatusDialogValue(true)
        OpenStatusDialog()
    };

    const setUserNoActive = (niu) => {
        handleClose()
        setStatusDialogTitle("Desactivation")
        setStatusDialogMessage("Vous êtes sur le point de desactiver l'accès de cet utilisateur, il ne pourra plus se connecter au système. Confirmez-vous cette action ?")
        setStatusDialogNiu(niu)
        setStatusDialogValue(false)
        OpenStatusDialog()
    }

    const resetUserPassword = (niu) => {
        handleClose()
        setResetDialogNiu(niu)
        OpenResetPasswordDialog()
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {
                    isActive && (
                        <MenuItem onClick={() => setUserNoActive(niu)}>
                            <ListItemIcon>
                                <PersonOff fontSize="small" />
                            </ListItemIcon>
                            Desactiver
                        </MenuItem>
                    )
                }
                {
                    !isActive && (
                        <MenuItem onClick={() => setUserActive(niu)}>
                            <ListItemIcon>
                                <Person fontSize="small" />
                            </ListItemIcon>
                            Activer
                        </MenuItem>
                    )
                }
                <MenuItem onClick={() => resetUserPassword(niu)} disabled={!isActive}>
                    <ListItemIcon>
                        <Password fontSize="small" />
                    </ListItemIcon>
                    Definir un nouveau mot de passe
                </MenuItem>
            </Menu>
            <ChangeUserStatusDialog  title={statusDialogTitle} message={statusDialogMessage} open={openStatusDialog} close={() => CloseStatusDialog()} niu={statusDialogNiu} status={statusDialogValue} reloadData={reloadData}/>
            <SetPasswordDialog niu={resetDialogNiu} open={openResetPasswordDialog} close={() => CloseResetPasswordDialog()} />
        </>
    )
}

export default UserListMenu