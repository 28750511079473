import { CameraAlt, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, colors, MenuItem, Stack, Typography } from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'

import dayjs from 'dayjs';

import AppBox from '../profile/AppBox'
import AppSnackbar from '../global/snackbar/Snackbar'
import AppDivider from '../profile/AppDivider'
import AppTextField from '../profile/AppTextField'
import { cycleOption, genderOption, salaryOption } from '../../config/defaultValue'
import { generateNiu } from '../../config/niu'
import { PersonalSchema } from '../../validations/PersonalSchema';
import { jobAPI } from '../../api/job';
import { personalAPI } from '../../api/personal';

// backgroundColor: 'rgba(49,27,146, 0.08)', border: '1px solid rgb(49,27,146)'
// backgroundColor: 'rgba(0, 230, 118, 0.08)', border: '1px solid rgb(0,230,118)'






function AdmnistrationSubscribe({ cycleOneData, cycleTwoData, cycleThreeData, cycleFourData }) {

    const [url,setUrl] = React.useState(null)


    //loading 
    const [loading, setLoading] = useState(false)


    const [jobsList, setJobsList] = useState([])
    const [job, setJob] = useState("")
    const [cycle, setCycle] = useState(1)
    const [classroom, setClassroom] = useState(cycleOneData[0].id)
    const [professorId, setProfessorId] = useState("1")

    const [classroomOptions, setClassroomOptions] = useState(cycleOneData)

    const handleCycleChange = (event) => {
        const _cycle = event.target.value
        setCycle(_cycle)
        if(_cycle === 1){
            setClassroom(cycleOneData[0].id)
            setClassroomOptions(cycleOneData)
        }
        else if(_cycle === 2){
            setClassroom(cycleTwoData[0].id)
            setClassroomOptions(cycleTwoData)
        }
        else if(_cycle === 3) {
            setClassroom(cycleThreeData[0].id)
            setClassroomOptions(cycleThreeData)
        }
        else if(_cycle === 4) {
            setClassroom(cycleFourData[0].id)
            setClassroomOptions(cycleFourData)
        }
        else {
            return
        }

    }

    const handleClassChange = (event) => {
        setClassroom(event.target.value)
    }


    // InputState
    const [imageFile, setImageFile] = useState(null)
    const [firstName, setFirstName] = useState(" ")
    const [lastName, setLastName] = useState(" ")
    const [birthdate, setBirthDate] = useState(dayjs())
    const [gender, setGender] = useState("M")
    const [number, setNumber] = useState(" ")
    const [email, setEmail] = useState(" ")
    const [fixedSalary, setFixedSalary] = useState(true)
    const [salary, setSalary] = useState(0)

    //image input
    const inputImage = useRef()


    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarType, setSnackbarType] = useState("success")

    const handleImageChange = (image) => {

        //console.log(image);
        const fileMimeType = image.type.replace('image/','')
        const fileTypes = /jpeg|jpg|png|gif/
        const isGoodMimeType = fileTypes.test(fileMimeType)
        if(isGoodMimeType) {
            setImageFile(image) 
            setUrl(URL.createObjectURL(image))

            //console.log(URL.createObjectURL(image));

            setOpenSnackbar(true)
            setSnackbarType("success")
            setSnackbarMessage("Image chargée avec succès")
        }
        else{
            setOpenSnackbar(true)
            setSnackbarType("error")
            setSnackbarMessage("Veuillez choisir une image")
        }
    }

    const reinitializeState = ()  => {
        // InputState
        setFirstName(" ");
        setLastName(" ")
        setBirthDate(dayjs())
        setGender("M")
        setNumber(" ")
        setEmail(" ")
        setSalary(0)

         //photo
        setImageFile(null)
        setUrl(null)

        //image input
        inputImage.current.value = null
    }

    const addPersonnal = async() => {
        setLoading(true)


        // require image
        // if(!imageFile){
        //     setOpenSnackbar(true)
        //     setSnackbarType("error")
        //     setSnackbarMessage("Veuiller importer la photo de profile")

        //     setLoading(false)
        //     return
        // }

        const niu = generateNiu(firstName, lastName, birthdate)

        const personnal = {
            niu: niu,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            birthDate: birthdate.format('YYYY/MM/DD'),
            gender: gender,
            phoneNumber: number,
            email: email.trim(),
            photoUrl: imageFile ? `${niu}.${imageFile.type.replace('image/','')}` : 'blank.jpg',
            fixedSalary: fixedSalary,
            salary: salary.trim(),
            createdAt: dayjs(),
            upatedAt: dayjs()
        }

        const isValid = await PersonalSchema
            .validate(personnal)
            .catch(error => {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage(error.errors)
                setLoading(false)

                return
            })

        if(isValid) {
            const formData = new FormData();
            for ( var key in personnal ) {
                formData.append(key, personnal[key]);
            }

            if(job === professorId) {
                formData.append('classroomId',classroom);
                formData.append('role','enseignement');
            }
            else {
                formData.append('jobId',job);
                formData.append('role','administration');
            }

            if(imageFile){
                formData.append('image',imageFile, `${niu}`);
            }

            personalAPI.postPersonal(formData)
            .then(({data}) => {
                if(data.success){
                    setOpenSnackbar(true)
                    setSnackbarType("success")
                    setSnackbarMessage("Personnel inscrit avec succès")
                    reinitializeState();
                }
                else {
                    setOpenSnackbar(true)
                    setSnackbarType("error")
                    setSnackbarMessage("Erreur lors de l'enregistrement du personnel")

                }

                setLoading(false)

            })
            .catch((e) => {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage("Erreur lors de l'enregistrement du personnel")

                setLoading(false)
            })

        }

    }

    // get year from db and update state
    const getJobs = () => {
        jobAPI.getJob()
            .then(({data}) => {
                setJobsList(data);
                //console.log(data);
                let professorIndex = data.find(i => i.title.trim().toLowerCase() === 'corps enseignant')
                setProfessorId(professorIndex.id)
                setJob(professorIndex.id)

            });
    }

    useEffect(() => {
        getJobs()
    },[])

  return (
    <>
        <Stack p={5}>
            <AppBox>
                {
                    !url && 
                    <Box  
                        onClick={() => inputImage.current.click()}
                        display='flex'
                        width={200} 
                        height={200} 
                        border={1} 
                        borderColor={colors.grey['300']} 
                        bgcolor={colors.grey['200']} 
                        justifyContent='center'
                        alignItems='center'
                    >
                        <CameraAlt sx={{ fontSize: '3rem', color: colors.grey['500'] }} />
                    </Box>
                }
                {
                    url && 
                    <Box
                        onClick={() => inputImage.current.click()}
                        display='flex'
                        width={200} 
                        height={200} 
                        border={1} 
                        borderColor={colors.grey['300']} 
                        bgcolor={colors.grey['200']} 
                        justifyContent='center'
                        alignItems='center'
                    >
                        <img src={url} alt='screenshot' className='img-fluid' />
                    </Box>
                }
                <input 
                    style={{ visibility: 'hidden' }}
                    ref={inputImage} 
                    type='file' 
                    name='uploaded_file'
                    onChange={(e) => handleImageChange(e.target.files[0])} 
                />
            </AppBox>
            <Typography color="primary" pt="20px">Niveau</Typography>
            <AppDivider />
            <AppBox>
                <AppTextField
                    required 
                    select 
                    fullWidth 
                    label="Choix de cycle"
                    size='small'
                    value={job} 
                    onChange={(e) => setJob(e.target.value)}
                >
                    {jobsList.map((data) => (
                    <MenuItem key={data.id} value={data.id}>{data.title}</MenuItem>
                  )
                )
                    }
                </AppTextField>
            </AppBox>
            {job === professorId && 
                (
                    <AppBox>
                        <AppTextField
                            select 
                            label="Choix de cycle" 
                            value={cycle} 
                            onChange={handleCycleChange}
                        >
                            {
                                cycleOption.map((data) => (
                                    <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                                ))
                            }
                        </AppTextField>
                        <AppTextField
                            select 
                            label="Choix de la classe" 
                            value={classroom} 
                            onChange={handleClassChange}
                        >
                        {classroomOptions.map((data) => (
                            <MenuItem key={data.id} value={data.id}>{data.label}</MenuItem>
                            ))
                        }
                        </AppTextField>
                    </AppBox>
                )
            }
            <Typography color="primary" pt="20px">Information personnelle</Typography>
            <AppDivider />
            <AppBox>
                <AppTextField 
                    label="Prenom" 
                    value={firstName} 
                    onClick={(e) => e.target.value === " " && setFirstName("")}
                    onChange={(e) => setFirstName(e.target.value)} 
                    error={!firstName}
                    helperText={
                        !firstName ? "Veuillez entrer le prenom" : ""
                    }
                />
                <AppTextField
                    label="Nom" 
                    value={lastName}
                    onClick={(e) => e.target.value === " " && setLastName("")}
                    onChange={(e) => setLastName(e.target.value)} 
                    error={!lastName}
                    helperText={
                        !lastName ? "Veuillez entrer le nom" : ""
                    }
                />
            </AppBox>
            <AppBox width="50%">
                <AppTextField
                    select 
                    label="Genre" 
                    value={gender} 
                    onChange={(e) => setGender(e.target.value)}
                >
                    {
                        genderOption.map((data) => (
                            <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                        ))
                    }
                </AppTextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Date de naissance"
                        inputFormat="DD/MM/YYYY"
                        value={birthdate}
                        onChange={(value) => setBirthDate(value)}
                        maxDate={dayjs()}
                        renderInput={(params) => <AppTextField {...params} />}
                    />
                </LocalizationProvider>
            </AppBox>
            <Typography color="primary" pt="20px">Contact</Typography>
            <AppDivider />
            <AppBox>
                <AppTextField 
                    label="Telephone"
                    value={number} 
                    onClick={(e) => e.target.value === " " && setNumber("")}
                    onChange={(e) => e.target.value.length < 9 && setNumber(e.target.value)} 
                    error={!number}
                    helperText={
                        !number ? "Veuillez entrer le numero de telephone" : ""
                    }
                />

                <AppTextField 
                    label="Email"
                    type="email"
                    required={false}
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                />
            </AppBox>
            <Typography color="primary" pt="20px">Salaire</Typography>
            <AppDivider />
            <AppBox>
                <AppTextField 
                    label="Type de salaire" 
                    select
                    value={fixedSalary} 
                    onChange={(e) => setFixedSalary(e.target.value)}
                >
                    {
                        salaryOption.map((data) => (
                            <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                        ))
                    }
                </AppTextField>
                <AppTextField 
                    label="Salaire" 
                    type="number"
                    value={salary} 
                    onChange={(e) => setSalary(e.target.value)} 
                    error={salary < 0}
                    helperText={
                        salary < 0 ? "Veuillez entrer le salaire" : ""
                    }
                />
            </AppBox>
            <Box mt={2}>
                <LoadingButton loading={loading} onClick={() => addPersonnal()} variant='contained' loadingPosition='start' startIcon={<Save />} disableElevation>
                    Inscrire
                </LoadingButton>
            </Box>
        </Stack>
        <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
    </>
    
  )
}

export default AdmnistrationSubscribe