import { CalendarMonth, MoreTime, Person } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Stack, Tab, Typography } from '@mui/material'
import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { personalAPI } from '../../api/personal'
import PersonalPaiement from '../../components/profile/personal/PersonalPaiment'
import PersonalUpdate from '../../components/profile/personal/PersonalUpdate'
import useAuth from '../../hooks/useAuth'
import PersonalPresence from '../../components/profile/personal/PersonalPresence'

const PersonalProfile = () =>  {
    let { auth } = useAuth()

    let { niu } = useParams();
    const [personal, setPersonal] = useState([])

    // Tab
    const [value, setValue] = useState("1")

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const getPersonal = () => {
        personalAPI.getPersonalByNiu(niu)
            .then(({data}) => {
                //console.log(data);
                //setTotal(data.classroom.subscribePrice)
                setPersonal(data);
            })
    }

    useEffect(() => {
        getPersonal()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (
    <Stack>
        <Stack>
           <TabContext value={value}>
            <Stack borderBottom={1} borderColor='#e8e8e8' display='flex' flexDirection='row' mr={2} justifyContent='space-between'>
                <Box p={2} display='flex' alignItems='center' flexDirection='row'>
                    <Typography variant='h5' fontWeight='bolder'>{`${personal.firstName} ${personal.lastName}`}</Typography>
                </Box>
                <TabList onChange={handleChange} textColor='primary' indicatorColor='primary'>
                    <Tab value={"3"} icon={<MoreTime />} iconPosition='start' label='Heures'/>
                    <Tab value={"1"} icon={<CalendarMonth />} iconPosition='start' label='Mensualité'/> 
                    {
                        (auth?.role === "directeur" || auth?.role === "administrateur") && (
                            <Tab value={"2"} icon={<Person />} iconPosition='start' label='Profil'/>
                        )
                    }
                    
                </TabList>
            </Stack>
            <TabPanel value={"1"} sx={{ p:'0' }} >
                <PersonalPaiement niu={niu} />
            </TabPanel>
            <TabPanel value={"2"} sx={{ p:'0' }}>
                <PersonalUpdate niu={niu} />
            </TabPanel>
            <TabPanel value={"3"} sx={{ p:'0' }}>
                <PersonalPresence niu={niu} />
            </TabPanel>
        </TabContext>
        </Stack>
    </Stack>
  )
}

export default PersonalProfile