import { Stack, Paper, IconButton } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { personalAPI } from '../../api/personal'
import { serverURL } from '../../config/serverUrl'
import EmptyDataAlert from '../global/EmptyDataAlert'
import { DataGrid, GridToolbar,frFR } from '@mui/x-data-grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const ProfessorList = ({cycle, classroom}) => {

  //console.log('cycle',cycle);
  //console.log('classroom',classroom);

  const columns = [
    {field: 'niu', headerName:'Niu', flex: 1},
    {field: 'photoUrl', headerName:'Photo', flex: 1, sortable: false, disableColumnMenu: true, renderCell: (cellValue) => <img 
      src={`${serverURL}/profiles/personals/${cellValue.row.photoUrl}`} 
      alt='photo_de_profil'
      style={{ maxHeight: '45px', maxWidth: '45px'}}
    />},
    {field: 'firstName', headerName:'Nom', flex: 1},
    {field: 'lastName', headerName:'Prenom', flex: 1},
    {field: 'birthDate', headerName:'Date de naissance', flex: 1},
    {field: 'age', headerName:'Age', flex: 1},
    {field: 'gender', headerName:'Genre', flex: 1},
    {field: 'phoneNumber', headerName:'Telephone', flex: 1},
    {field: 'classroomLabel', headerName:'Classe', flex: 1},
    {field: 'Ouvrir', sortable: false, disableColumnMenu: true, renderCell: (cellValues) => {
      return (
          <IconButton  size="small" onClick={() => navigate(`/profile/personal/${cellValues.row.niu}`)}>
            <ArrowForwardIosIcon/>
          </IconButton>
      )
    }},
  ]

  const dataGridSx = {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    width: '100%',
    borderRadius: 2
  }

  const navigate = useNavigate()

  const [professorsList, setProfessorsList] = useState([])
  // console.log(classroomList);

  const getProfessors = (cycle, classroom) => {
      if(cycle && cycle !== 'all') {
        if (classroom && classroom !== 'all') {
        //console.log("classroom",classroom)
        personalAPI.getProfessorByClassroom(classroom)
          .then(({data}) => {
              setProfessorsList(parseData(data))
              //console.log(data)
          })
        }
        else {
          //console.log("cycle",cycle)
          personalAPI.getProfessorByCycle(cycle)
          .then(({data}) => {
              setProfessorsList(parseData(data))
              //console.log(data)
          })
        }
      }
      else {
        // console.log("all")
        personalAPI.getProfessor()
        .then(({data}) => {
            setProfessorsList(parseData(data))
            return
        })
      }
  }

  const parseData = (data) => {
    return data.map((d,i) => {
      const age = dayjs().year() - dayjs(d.birthDate).year()
      return {
      ...d,
      id: i + 1,
      classroomLabel: d.classroom.label,
      age: `${age > 1 ? `${age} ans` : '~ 1 an'}`
      //console.log(d);
    }})
  }

  useEffect(() => {
    getProfessors(cycle,classroom)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycle, classroom])

  return (
    <Stack>
      {
        professorsList.length <= 0 && (
          <EmptyDataAlert />
        )
      }
      {
        professorsList.length > 0 && (
          <Paper sx={dataGridSx}>
            <DataGrid 
              rows={professorsList} 

              columns={columns} 
              slots={{ toolbar: GridToolbar }} 
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
              ...professorsList.initialState,
              pagination: {
                ...professorsList.initialState?.pagination,
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            />
          </Paper>
        )
      }
    </Stack>

  )
}

export default ProfessorList