const getToken = async () => {
    const tokenUrl = 'https://api.orange.com/oauth/v3/token'
    return await fetch(tokenUrl, { 
        method: "POST",
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic R2RwQTlndzF6RDJ3NmZsRklmY1dRTnIwOUNSc3RJQTM6MDB3cUxMZWFTTmJFbm9aTQ==', 
        }), 
    })
}

const getPaiementUrl = () => {
    const webDevUrl = 'https://api.orange.com/orange-money-webpay/dev/v1/webpayment'
    return fetch(webDevUrl, { 
        method: 'POST', 
        headers: new Headers({
            'Authorization': 'Basic R2RwQTlndzF6RDJ3NmZsRklmY1dRTnIwOUNSc3RJQTM6MDB3cUxMZWFTTmJFbm9aTQ==', 
            'Content-Type': 'application/x-www-form-urlencoded'
        }), 
        body: 'grant_type=client_credentials'
    })
}

export const OMAPI = {
    getToken,
    getPaiementUrl
}