import { Box, Typography, colors } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { forwardRef, useEffect, useRef, useState } from 'react';
import useLogout from '../../../hooks/useLogout';
import useActivity from '../../../hooks/useActivity';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExpireTimeDialog({open, setOpen}) {

    const logout = useLogout()

    const [sec, setSec] = useState(5)
    const timer = useRef()

    const startTimer = () => {

        timer.current = setInterval(() => {
            setSec(prev => prev - 1)
        }, 1000)
    }

    const stopTimer = () => {
        clearInterval(timer.current)
        timer.current = 0
    }

    useEffect(() => {
        startTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(sec===0) {
            stopTimer()
            logout({expire: true})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sec])

    const updateExpireTime = useActivity()

    const stayOnline = () => {
        updateExpireTime()
        setOpen(false)
    }

    const handleClose = (props, reason) => {
       if(reason === "backdropClick") {
        return
       }

        setOpen(false)
    }

    return (
        <div>

        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            fullWidth={true}
            maxWidth='xs'
        >
            <DialogTitle>Êtes-vous toujours là ?</DialogTitle>
            <DialogContent>
            <Typography variant='body2' color={colors.grey[500]}>À raison de reponse, vous serez deconnectez dans: </Typography>
            <Box sx={{ textAlign:"center", display: 'flex', justifyContent: "center", my: 1 }}>
                <Box sx={{ border: "0px solid", borderRadius: 20, width: "8em", height: "8em", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "6em"  }}>{sec}</Typography>
                </Box>
            </Box>
            </DialogContent>
            <DialogActions>
            <Button variant='contained' color='error' onClick={() => logout()}>Deconnection</Button>
            <Button variant='contained' onClick={() => stayOnline()}>Oui, rester connecté</Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}