import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material'
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react'
import { expenseAPI } from '../../api/expense';
import dayjs from 'dayjs';

const Search = () => {


  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  let navigate = useNavigate();

  const handleStudentChange = (event, expense) => {
    navigate(`/expense/details/${expense.niu}`)

  }


  const search = (event) => {
    let char = event.target.value
    if(char) {
      setLoading(true)
      expenseAPI.getExpenseByChar(char)
        .then(({data}) => {
          // console.log(data);
          setOptions(data.map((data) => {
            return {
              ...data,
              label: `${data.receiptNumber} - ${data.reason} - ${data.amount}`,
            }
          }))
          setLoading(false)
          // console.log(data);
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }


  return (
    <Stack>
        <Autocomplete 
            sx={{ width: 400 }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option,value) => option.receiptNumber === value.receiptNumber}
            options={options.sort((a,b) => -b.amount.toString().localeCompare(a.amount.toString()))}
            groupBy={(options) => dayjs(options.transactionDate).format('DD/MM/YYYY')}
            getOptionLabel={(options) => options.label}
            loading={loading}
            onChange={handleStudentChange}
            onKeyUp={search}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Depense" 
                color='primary' 
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null }
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
              )
            } 
        />
    </Stack>
  )
}

export default Search