import React from 'react';
import { PieChart, Pie, Sector, Tooltip, Cell, ResponsiveContainer, Legend } from 'recharts';


const COLORS = ['#8884d8', '#82ca9d', '#FFBB28', '#FF8042'];

// let renderLabel = function(entry) {
//     return `${entry.name} (${entry.value}%)`;
// }

const PieChartComponent = ({studentCount}) => {

    const totalChild = studentCount.cycleSmall + studentCount.cycleSmall + studentCount.cycleSmall

    const totalPrimary = studentCount.cycleFirst + studentCount.cycleSecond + studentCount.cycleThird + studentCount.cycleFourth + studentCount.cycleFifth + studentCount.cycleSixth

    const totalSecondary = studentCount.cycleSeventh + studentCount.cycleEighth + studentCount.cycleNinth

    const data = [
        { name: 'Creche', value: studentCount.cycleBaby },
        { name: 'Jardin', value: totalChild },
        { name: 'Primaire', value: totalPrimary },
        { name: 'Secondaire', value: totalSecondary }
    ];


    return (
        <ResponsiveContainer>
            <PieChart height={"100%"} width={"100%"} >
                <Pie
                    data={data}
                    cx={'50%'}
                    cy={'50%'}
                    innerRadius={'50%'}
                    outerRadius={'80%'}
                    fill="#8884d8"
                    paddingAngle={3}
                    dataKey="value"
                    legendType='circle'
                    // label={renderLabel}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Sector />
                <Legend  verticalAlign="bottom" />
            </PieChart>
        </ResponsiveContainer>
    );
}

export default  PieChartComponent