import { ArrowDropDown, ArrowDropUp, AttachMoneyOutlined, Equalizer, GroupsOutlined, ManageAccountsOutlined, TrendingUp, SavingsOutlined } from '@mui/icons-material'
import { Avatar, Box, Grid, Stack,  Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { dashboardAPI } from '../api/dashboard'
import LoadingBackdrop from '../components/global/LoadingBackdrop'
import { lisibleNumber } from '../config/lisibleNumber'
import AreaChartComponent from '../components/dashboard/AreaChart'
import PieChartComponent from '../components/dashboard/PieChart'

// student number / class list
// last expense list
// monthly income by class

function Dashboard() {

  const [personalCount, setPersonalCount] = useState([])
  const [studentCount, setStudentCount] = useState([])
  const [subscribePrice, setSubscribePrice] = useState([])

  const [cumulPaiement, setCumulPaiement] = useState([])
  const [cumulExpense, setCumulExpense] = useState([])
  const [cumulIncome, setCumulIncome] = useState([])
  const [cumulProfessorExpense, setCumulProfessorExpense] = useState([])

  const [monthlyPaiement, setMonthlyPaiement] = useState([])
  const [monthlyExpense, setMonthlyExpense] = useState([])
  const [monthlyIncome, setMonthlyIncome] = useState([])
  const [monthlyProfessorExpense, setMonthlyProfessorExpense] = useState([])

  //const sorted = sortByYearMonth(monthlyIncome)
  //console.log("monthlyIncome",monthlyIncome);
  //console.log("monthlyIncomeSorted",sorted);
  //console.log("monthlyProfessorExpense",monthlyProfessorExpense);



  const [loading, setLoading] = useState(true)


  const mustHave = () => {
    const totalBaby = subscribePrice.cycleBaby * studentCount.cycleBaby

    const totalSmall = subscribePrice.cycleSmall * studentCount.cycleSmall
    const totalMedium = subscribePrice.cycleMedium * studentCount.cycleMedium
    const totalBig = subscribePrice.cycleBig * studentCount.cycleBig

    const totalFirst = subscribePrice.cycleFirst * studentCount.cycleFirst
    const totalSecond = subscribePrice.cycleSecond * studentCount.cycleSecond
    const totalThird = subscribePrice.cycleThird * studentCount.cycleThird
    const totalFourth = subscribePrice.cycleFourth * studentCount.cycleFourth
    const totalFifth = subscribePrice.cycleFifth * studentCount.cycleFifth
    const totalSixth = subscribePrice.cycleSixth * studentCount.cycleSixth

    const totalSeventh = subscribePrice.cycleSeventh * studentCount.cycleSeventh
    const totalEighth = subscribePrice.cycleEighth * studentCount.cycleEighth
    const totalNinth = subscribePrice.cycleNinth * studentCount.cycleNinth

    const total = totalBaby + totalSmall + totalMedium + totalBig + totalFirst + totalSecond +
    totalThird + totalFourth + totalFifth + totalSixth + totalSeventh + totalEighth + totalNinth

    return total
  }

  const initializeData = async() => {
    setLoading(true)
    const personalCount = await dashboardAPI.personalCount()
    setPersonalCount(personalCount.data)
    // console.log(personalCount.data);

    const studentCount = await dashboardAPI.studentCount()
    setStudentCount(studentCount.data)
    // console.log(studentCount.data);

    const subscribePrice = await dashboardAPI.subscribePrice()
    setSubscribePrice(subscribePrice.data)
    // console.log(subscribePrice.data);

    const cumulPaiement = await dashboardAPI.cumulPaiement()
    setCumulPaiement(cumulPaiement.data)
    // console.log(monthlyPaiement.data);

    const cumulIncome = await dashboardAPI.cumulIncome()
    setCumulIncome(cumulIncome.data)
    // console.log(monthlyPaiement.data);

    const cumulExpense = await dashboardAPI.cumulExpense()
    setCumulExpense(cumulExpense.data)
    // console.log(monthlyPaiement.data);

    const cumulProfessorExpense = await dashboardAPI.cumulProfessorExpense()
    setCumulProfessorExpense(cumulProfessorExpense.data)
    // console.log(cumulProfessorExpense.data);

    const monthlyPaiement = await dashboardAPI.monthlyPaiement()
    setMonthlyPaiement(monthlyPaiement.data)
    // console.log(monthlyIncome.data);


    const monthlyIncome = await dashboardAPI.monthlyIncome()
    setMonthlyIncome(monthlyIncome.data)
    // console.log(monthlyIncome.data);

    const monthlyExpense = await dashboardAPI.monthlyExpense()
    setMonthlyExpense(monthlyExpense.data)
    // console.log(monthlyExpense.data);

    const monthlyProfessorExpense = await dashboardAPI.monthlyProfessorExpense()
    setMonthlyProfessorExpense(monthlyProfessorExpense.data)
    // console.log(monthlyProfessorExpense.data);



    setLoading(false)
  }

  useEffect(() => {
    initializeData()
  },[])

  return (
    <Stack padding={4} height='100vh' bgcolor='backgroundColor.main' >
      {
        loading && (
          <LoadingBackdrop />
        )
      }
      {
        !loading && (
          <>
            <Grid container columnSpacing={4} rowSpacing={4}>
              <Grid item xs={3}>
                <Box bgcolor="common.white" p={2} borderRadius={2}>
                  <Avatar sx={{ bgcolor: 'rgb(253,242,254)' }}>
                    <AttachMoneyOutlined sx={{ color:'rgb(227,98,242)' }} />
                  </Avatar>
                  <Typography mt={2} variant='h5' fontWeight='bold'>{lisibleNumber(mustHave())} CFA</Typography>
                  <Typography variant='body1' color='rgb(146,153,165)'>Gains inscriptions </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box bgcolor="common.white" p={2} borderRadius={2}>
                  <Avatar sx={{ bgcolor: 'rgb(233,255,255)' }}>
                      <TrendingUp sx={{ color:'rgb(39,208,232)' }} />
                    </Avatar>
                  <Typography mt={2} variant='h5' fontWeight='bold'>{lisibleNumber(cumulPaiement.total[0].total === null ? 0 : cumulPaiement.total[0].total)} CFA</Typography>
                  <Typography variant='body1' color='rgb(146,153,165)'>Cumul mensualités</Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box bgcolor="common.white" p={2} borderRadius={2}>
                  <Avatar sx={{ bgcolor: 'rgb(255,250,234)' }}>
                    <SavingsOutlined sx={{ color:'rgb(249,182,63)' }} />
                  </Avatar>
                  <Typography mt={2} variant='h5' fontWeight='bold'>{lisibleNumber(cumulIncome.total[0].total === null ? 0 : cumulIncome.total[0].total)} CFA</Typography>
                  <Typography variant='body1' color='rgb(146,153,165)'>Cumul gains </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                 <Grid container columnSpacing={4} rowSpacing={4}>
                  <Grid item xs={6}>
                    <Box bgcolor="common.white" p={2} borderRadius={2}>
                      <Avatar sx={{ bgcolor: '#E3F6FF' }}>
                        <GroupsOutlined sx={{ color:'#6E85B7' }} />
                      </Avatar>
                      <Typography mt={2} variant='h5' fontWeight='bold'>{studentCount.total}</Typography>
                      <Typography variant='body1' color='rgb(146,153,165)'>Eleves </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box bgcolor="common.white" p={2} borderRadius={2}>
                      <Avatar sx={{ bgcolor: 'rgb(233,254,244)' }}>
                        <ManageAccountsOutlined sx={{ color:'rgb(51,210,145)' }} />
                      </Avatar>
                      <Typography mt={2} variant='h5' fontWeight='bold'>{personalCount.total}</Typography>
                      <Typography variant='body1' color='rgb(146,153,165)'>Personnels </Typography>
                    </Box>

                  </Grid>

                 </Grid>
              </Grid>


                {/* Chart */}
              <Grid item xs={9} >
                <Box bgcolor="common.white" p={5} borderRadius={2} height={500}> 
                  <AreaChartComponent monthlyPaiement={monthlyPaiement} 
                  monthlyIncome={monthlyIncome}
                  monthlyProfessorExpense={monthlyProfessorExpense} 
                  monthlyExpense={monthlyExpense} />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box bgcolor="common.white" p={2} borderRadius={2} height={500}> 
                  <PieChartComponent studentCount={studentCount} sx={{ height: 500 }} />
                </Box>
              </Grid>


                {/* Revenu exoense difference */}
               <Grid item xs={4}>
                <Box bgcolor="common.white" p={2} borderRadius={2}>
                  <Avatar sx={{ bgcolor: 'rgb(233,254,244)' }}>
                    <ArrowDropDown sx={{ color:'rgb(51,210,145)' }} />
                  </Avatar>
                  <Typography mt={2} variant='h5' fontWeight='bold'>{lisibleNumber(parseInt(cumulIncome.total[0].total === null ? 0 : cumulIncome.total[0].total) + parseInt(cumulPaiement.total[0].total === null ? 0 : cumulPaiement.total[0].total) + parseInt(mustHave()))}  CFA</Typography>
                  <Typography variant='body1' color='rgb(146,153,165)'>Revenu </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}  >
                <Box bgcolor="common.white" p={2} borderRadius={2}>
                  <Avatar sx={{ bgcolor: 'rgb(255,233,233)' }}>
                    <ArrowDropUp sx={{ color:'rgb(210,50,50)' }} />
                  </Avatar>
                  <Typography mt={2} variant='h5' fontWeight='bold'>{lisibleNumber((cumulProfessorExpense.total[0].total === null ? 0 : cumulProfessorExpense.total[0].total) + (cumulExpense.total[0].total === null ? 0 : cumulExpense.total[0].total))} CFA</Typography>
                  <Typography variant='body1' color='rgb(146,153,165)'>Dépense </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="common.white" p={2} borderRadius={2}>
                  <Avatar sx={{ bgcolor: 'rgb(255,250,234)' }}>
                    <Equalizer sx={{ color:'rgb(249,182,63)' }} />
                  </Avatar>
                  <Typography mt={2} variant='h5' fontWeight='bold'>{lisibleNumber((parseInt(cumulPaiement.total[0].total === null ? 0 : cumulPaiement.total[0].total) + 
                                                                                  parseInt(mustHave()) +
                                                                                  parseInt(cumulIncome.total[0].total === null ? 0 : cumulIncome.total[0].total)) - 
                                                                                  (parseInt(cumulProfessorExpense.total[0].total === null ? 0 : cumulProfessorExpense.total[0].total) + 
                                                                                  parseInt(cumulExpense.total[0].total === null ? 0 : cumulExpense.total[0].total))
                                                                                  )} FCA</Typography>
                  <Typography variant='body1' color='rgb(146,153,165)'>Difference </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )
      }
    </Stack>
  )
}

export default Dashboard