import * as yup from "yup";

export const PersonalSchema = yup.object().shape({
    firstName: yup
        .string()
        .min(3,"Le prenom doit avoir 3 charactères mininum")
        .required("Le prenom est obligatoire"),
    lastName: yup
        .string()
        .min(3,"Le nom doit avoir 3 charactères mininum")
        .required("Le nom est obligatoire"),
    salary: yup
        .number()
        .moreThan(0, "Le salaire doit être supérieur à 0")
        .required("Le salaire est obligatoire"),
    phoneNumber: yup
        .string()
        .min(8, "Le numero de téléphone doit avoir 8 charactères minimun")
        .required("Le numero de téléphone est obligatoire"),
})