import { colors, createTheme, ThemeProvider } from '@mui/material';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css";

import SharedLayout from './components/global/SharedLayout';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error';
import Classroom from './pages/Classroom';
import Expense from './pages/Expense';
import Login from './pages/Login';
import Setting from './pages/Setting';
import Subscribe from './pages/Subscribe';
import StudentProfile from './pages/profiles/StudentProfile';
import PersonalProfile from './pages/profiles/PersonalProfile';
import PDF from './components/pdf/PDF';
import Professor from './pages/Professor';
import Personal from './pages/Personal';
import { AuthProvider } from './context/AuthProvider';
import RequireAuth from './components/auth/RequireAuth';
import Unauthorized from './pages/Unauthorized';
import ChangePassword from './pages/ChangePassword';
import Paiement from './pages/Paiement';
import Income from './pages/Income';


//customizing theme
const theme = createTheme({
  palette: {
    primary: {
      main: colors.purple['900'],
    },
    secondary: {
      main: colors.green['A400'],
    },
    neutral: {
      main: colors.grey['500'],
      darker: colors.grey['700']
    },
    backgroundColor: {
      main: 'rgb(241,242,244)'
    },
    common: {
      white: '#fff',
      black: '#000'
    }
  },
  status: {
    danger: '#e53e3e',
  }

})

function App() {

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
              <Route path='/' element={ <SharedLayout /> }>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur']}/> }>
                      <Route index element={ <Dashboard /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable','directeur','surveillant']}/> }>
                      <Route path='classroom' element={ <Classroom /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable','directeur','surveillant']}/> }>
                      <Route path='personal' element={ <Personal /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable','directeur','surveillant']}/> }>
                      <Route path='professor' element={ <Professor /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable', 'directeur']}/> }>
                      <Route path='subscribe' element={ <Subscribe /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable']}/> }>
                      <Route path='expense' element={ <Expense /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable']}/> }>
                      <Route path='income' element={ <Income /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable','directeur']}/> }>
                      <Route path='setting' element={ <Setting /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable','directeur','surveillant']}/> }>
                      <Route path='pdf' element={ <PDF /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable','directeur']}/> }>
                      <Route path='profile/student/:niu' element={ <StudentProfile /> } />
                  </Route>

                  <Route element={ <RequireAuth  allowedRoles={['administrateur','comptable','directeur','surveillant']}/> }>
                      <Route path='profile/personal/:niu' element={ <PersonalProfile /> } />
                  </Route>

                  <Route path='unauthorized' element={ <Unauthorized /> } />
              </Route>

              <Route path='changePassword' element={ <ChangePassword /> } />
              <Route path='login' element={ <Login /> } />
              <Route path='paiement' element={ <Paiement /> } />
              <Route path='*' element={ <Error /> } />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
    
  );
}

export default App;
