import { Apartment, Event, Lock, ViewModule, Work } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Stack, Tab } from '@mui/material'
import React, { useState } from 'react'
import ClassroomList from '../components/setting/ClassroomList';
import SchoolInformation from '../components/setting/SchoolInformation';
import JobList from '../components/setting/JobList';
import YearList from '../components/setting/YearList';
import UserManagement from '../components/setting/UserManagement';
import useAuth from '../hooks/useAuth';

// devise FCFA
// 

function Setting() {
    let { auth } = useAuth()

    // Tab
    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Stack>
            <TabContext value={value}>
                <Stack borderBottom={1} borderColor='#e8e8e8' display='flex' flexDirection='row' mr={2}>
                    <TabList onChange={handleChange} textColor='primary' indicatorColor='primary'>
                        <Tab value={"1"} icon={<ViewModule />} iconPosition='start' label='Classe'/>
                        {
                            (auth?.role === "administrateur" || auth?.role === "directeur")  && (
                                <Tab value={"3"} icon={<Work />} iconPosition='start' label='Poste'/>
                            )
                        }
                        {
                            auth?.role === "administrateur" && (
                                <Tab value={"2"} icon={<Event />} iconPosition='start' label='Année'/>
                            )
                        }
                        {
                            auth?.role === "administrateur" && (
                                <Tab value={"4"} icon={<Apartment />} iconPosition='start' label='Etablissement'/>
                            )
                        }
                        {
                            auth?.role === "administrateur" && (
                                <Tab value={"5"} icon={<Lock />} iconPosition='start' label='Utilisateurs'/>
                            )
                        }
                    </TabList>
                </Stack>
                <TabPanel value={"1"}>
                    <ClassroomList />
                </TabPanel>
                <TabPanel value={"2"} >
                    <YearList />
                </TabPanel> 
                <TabPanel value={"3"} >
                    <JobList />
                </TabPanel> 
                <TabPanel value={"4"} >
                    <SchoolInformation />
                </TabPanel> 
                <TabPanel value={"5"} >
                    <UserManagement />
                </TabPanel> 
            </TabContext>
        </Stack>
    )
}

export default Setting