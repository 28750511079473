import { API } from "./index";

const getInformation = () => API.get('/schoolInformation');
const putInformation = (id,data) => API.put(`/schoolInformation/${id}`, data);


export const schoolInformationAPI = {
    getInformation,
    putInformation
}
