import { CalendarMonth, Person } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Backdrop, Box, CircularProgress, Stack, Tab, Typography } from '@mui/material'
import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { studentAPI } from '../../api/student'
import StudentPaiement from '../../components/profile/student/StudentPaiement'
import StudentUpdate from '../../components/profile/student/StudentUpdate'

const StudentProfile = () =>  {

    let { niu } = useParams();
    const [student, setStudent] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    // Tab
    const [value, setValue] = useState("1")

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const getStudent = () => {
        setIsLoading(true)
        studentAPI.getStudentByNiu(niu)
            .then(({data}) => {
                //console.log(data);
                //setTotal(data.classroom.subscribePrice)
                setStudent(data)
                setIsLoading(false)
            })
    }


    useEffect(() => {
        getStudent()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (
    <Stack>
        {
            isLoading && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )
        }
        {
            !isLoading && (
                <TabContext value={value}>
                    <Stack borderBottom={1} borderColor='#e8e8e8' display='flex' flexDirection='row' mr={2} justifyContent='space-between'>
                        <Box p={2} display='flex' alignItems='baseline' flexDirection='row'>
                            <Typography variant='h5' fontWeight='bolder'>{`${student.firstName} ${student.lastName}`}</Typography>
                            <Typography variant='h6' fontWeight='light' ml={1}>{`- ${student.classroom.label}`}</Typography>
                        </Box>
                        <TabList onChange={handleChange} textColor='primary' indicatorColor='primary'>
                            <Tab value={"1"} icon={<CalendarMonth />} iconPosition='start' label='Mensualité'/> 
                            <Tab value={"2"} icon={<Person />} iconPosition='start' label='Profil'/>
                        </TabList>
                    </Stack>
                    <TabPanel value={"1"} sx={{ p:'0' }} >
                        <StudentPaiement niu={niu} />
                    </TabPanel> 
                    <TabPanel value={"2"} sx={{ p:'0' }}>
                        <StudentUpdate niu={niu} />
                    </TabPanel>
                </TabContext>
            )
        }
    </Stack>
  )
}

export default StudentProfile