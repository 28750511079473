import { CameraAlt, Cancel, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { Box, Button, CircularProgress, colors, MenuItem, Stack, Typography } from '@mui/material'
import React, {useRef, useState, useEffect} from 'react'
import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'
import { StudentSchema } from '../../../validations/StudentSchema'
import { studentAPI } from '../../../api/student'
import { classroomAPI } from '../../../api/classroom'
import { parseClassroom } from '../../../config/parseClassroom'
import AppBox from '../AppBox'
import AppDivider from '../AppDivider'
import AppTextField from '../AppTextField'
import { activeOption, cycleOption, genderOption, parentOption } from '../../../config/defaultValue'
import AppSnackbar from '../../global/snackbar/Snackbar'
import { serverURL } from '../../../config/serverUrl'
import { useNavigate } from 'react-router-dom'
import ComfirmDeletionDialog from '../../global/ComfirmDeletionDialog'


dayjs.locale(fr) 


const StudentUpdate = ({niu}) =>  {
    const navigate = useNavigate()

    const [cycleOneData, setCycleOneData] = useState([])
    const [cycleTwoData, setCycleTwoData] = useState([])
    const [cycleThreeData, setCycleThreeData] = useState([])
    const [cycleFourData, setCycleFourData] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    const [cycle, setCycle] = useState("")
    //const [classroom, setClassroom] = useState(cycleOneData[0].id) 
    const [classroom, setClassroom] = useState("")

    //const [classroomOptions, setClassroomOptions] = useState(cycleOneData)
    const [classroomOptions, setClassroomOptions] = useState([])


    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarType, setSnackbarType] = useState("success")

    //loading 
    const [loading, setLoading] = useState(false)

    //photo
    const [imageFile, setImageFile] = useState(null)
    const [url,setUrl] = useState(null)

    // InputState
    const [noNiu, setNoNiu] = useState(" ")
    const [createdAt, setCreatedAt] = useState(" ")
    const [updatedAt, setUpdatedAt] = useState(" ")
    const [isActive, setIsActive] = useState(true)

    // InputState
    const [firstName, setFirstName] = useState(" ")
    const [lastName, setLastName] = useState(" ")
    const [birthdate, setBirthDate] = useState(dayjs())
    const [gender, setGender] = useState("M")

    // Parent
    const [parent, setParent] = useState("M")
    const [parentName, setParentName] = useState(" ")
    const [parentNumber, setParentNumber] = useState(" ")

    //image input
    const inputImage = useRef()

    // deletion dialog 
    const [openDeletionDialog, setOpenDeletionDialog] = useState(false)


    const handleCycleChange = (_cycle) => {
        setCycle(_cycle)
        if(_cycle === 1){
            setClassroom(cycleOneData[0].id)
            setClassroomOptions(cycleOneData)
        }
        else if(_cycle === 2){
            setClassroom(cycleTwoData[0].id)
            setClassroomOptions(cycleTwoData)
        }
        else if(_cycle === 3) {
            setClassroom(cycleThreeData[0].id)
            setClassroomOptions(cycleThreeData)
        }
        else if(_cycle === 4) {
            setClassroom(cycleFourData[0].id)
            setClassroomOptions(cycleFourData)
        }
        else {
            return
        }

    }

    const handleClassChange = (_classroom) => {
        setClassroom(_classroom)
    }

   
    const handleImageChange = (image) => {

        // console.log(image);
        const fileMimeType = image.type.replace('image/','')
        const fileTypes = /jpeg|jpg|png|gif/
        const isGoodMimeType = fileTypes.test(fileMimeType)
        if(isGoodMimeType) {
            setImageFile(image) 
            setUrl(URL.createObjectURL(image))

            setOpenSnackbar(true)
            setSnackbarType("success")
            setSnackbarMessage("Image chargée avec succès")
        }
        else{
            setOpenSnackbar(true)
            setSnackbarType("error")
            setSnackbarMessage("Veuillez choisir une image")
        }
    }

    const handleSucessDeletionDialog = () => {
        setOpenDeletionDialog(false)
        deleteStudent()
    }

    const deleteStudent = () => {
        setIsLoading(true)

        const deletedAt = {
            deletedAt: dayjs()
        }

        studentAPI.deleteStudent(noNiu,deletedAt)
            .then(({data}) => {
                if(data.success){
                    setOpenSnackbar(true)
                    setSnackbarType("success")
                    setSnackbarMessage("Suppression effectuer avec succès")
                }
                else {
                    setOpenSnackbar(true)
                    setSnackbarType("error")
                    setSnackbarMessage("Erreur lors de la suppression")
                }

                setIsLoading(false)
                navigate("/classroom")

            })
            .catch((e) => {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage("Erreur lors de la suppression")

                setIsLoading(false)
            })
    }

    const updateStudent = async () => {
        setLoading(true)

        // niu, photoURL removed
        const student = {
            firstName: firstName,
            lastName: lastName,
            birthDate: birthdate,
            gender: gender,
            parent: parent,
            parentFullname: parentName,
            parentPhoneNumber: parentNumber,
            isActive: isActive,
            upatedAt: dayjs(),
            classroomId: classroom
        }


        const isValid = await StudentSchema
            .validate(student)
            .catch(error => {
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage(error.errors)
                setLoading(false)

                return
            })

        if(isValid) {
            const formData = new FormData();
            for ( var key in student ) {
                formData.append(key, student[key]);
            }

            if(imageFile) {
                formData.append('image',imageFile, `${niu}`);
                formData.append('photoUrl',`${niu}.${imageFile.type.replace('image/','')}`);
            }

            // console.log(formData);

            studentAPI.putStudent(noNiu,formData)
                .then(({data}) => {
                    if(data.success){
                        setOpenSnackbar(true)
                        setSnackbarType("success")
                        setSnackbarMessage("Informations modifier avec succès")
                    }
                    else {
                        setOpenSnackbar(true)
                        setSnackbarType("error")
                        setSnackbarMessage("Erreur lors de la modification des informations")
                    }

                    initializeClassroomsAndStudent()

                    setLoading(false)

                })
                .catch((e) => {
                    setOpenSnackbar(true)
                    setSnackbarType("error")
                    setSnackbarMessage("Erreur lors de la modification des informations")

                    setLoading(false)
                })

        }

    }


    // get classroom from db and update state
    const initializeClassroomsAndStudent = () => {
        setIsLoading(true);
        classroomAPI.getClassroom()
            .then(({data}) => {
                const parsedClassroom = parseClassroom(data);

                const _cycleOneData = parsedClassroom.filter((item) => item.cycle === 1)
                setCycleOneData(_cycleOneData)

                const _cycleTwoData = parsedClassroom.filter((item) => item.cycle === 2)
                setCycleTwoData(_cycleTwoData)

                const _cycleThreeData = parsedClassroom.filter((item) => item.cycle === 3)
                setCycleThreeData(_cycleThreeData)

                const _cycleFourData = parsedClassroom.filter((item) => item.cycle === 4)
                setCycleFourData(_cycleFourData)


                studentAPI.getStudentByNiu(niu)
                    .then(({data}) => {

                        // console.log(data);
                        // update state
                        setNoNiu(data.niu)
                        setCreatedAt(data.createdAt)
                        setUpdatedAt(data.updatedAt)
                        setIsActive(data.isActive)

                        // InputState
                        setUrl(data.photoUrl)
                        setLastName(data.lastName)
                        setFirstName(data.firstName)
                        setBirthDate(data.birthDate)
                        setGender(data.gender)


                        // Parent
                        setParent(data.parent)
                        setParentName(data.parentFullname)
                        setParentNumber(data.parentPhoneNumber)

                        //classroom
                        const _cycle = data.classroom.cycle
                        setCycle(_cycle)
                        if(_cycle === 1){
                            setClassroom(_cycleOneData[0].id)
                            setClassroomOptions(_cycleOneData)
                        }
                        else if(_cycle === 2){
                            setClassroom(_cycleTwoData[0].id)
                            setClassroomOptions(_cycleTwoData)
                        }
                        else if(_cycle === 3) {
                            setClassroom(_cycleThreeData[0].id)
                            setClassroomOptions(_cycleThreeData)
                        }
                        else if(_cycle === 4) {
                            setClassroom(_cycleFourData[0].id)
                            setClassroomOptions(_cycleFourData)
                        }
                        else {
                            return
                        }
                        handleClassChange(data.classroom.id) 
                    })
            })

            setIsLoading(false);
    }


    useEffect(() => {
        initializeClassroomsAndStudent()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



    return (
    <>
    {
        !isLoading && (
            <Stack p={5}>
                <AppBox>
                    {
                        !url && 
                        <Box  
                            onClick={() => inputImage.current.click()}
                            display='flex'
                            width={200} 
                            height={200} 
                            border={1} 
                            borderColor={colors.grey['300']} 
                            bgcolor={colors.grey['200']} 
                            justifyContent='center'
                            alignItems='center'
                        >
                            <CameraAlt sx={{ fontSize: '3rem', color: colors.grey['500'] }} />
                        </Box>
                    }
                    {
                        url && 
                        <Box
                            onClick={() => inputImage.current.click()}
                            display='flex'
                            width={200} 
                            height={200} 
                            border={1} 
                            borderColor={colors.grey['300']} 
                            bgcolor={colors.grey['200']} 
                            justifyContent='center'
                            alignItems='center'
                        >
                            {
                                imageFile && (
                                    <img src={url} alt='screenshot' className='img-fluid' />
                                )
                            }
                            {
                                !imageFile && (
                                    <img src={`${serverURL}/profiles/students/${url}`} alt='screenshot' className='img-fluid' />
                                )
                            }

                        </Box>
                    }
                    <input 
                        style={{ visibility: 'hidden' }}
                        ref={inputImage} 
                        type='file' 
                        name='uploaded_file'
                        onChange={(e) => handleImageChange(e.target.files[0])} 
                    />
                </AppBox>

                <Typography color="primary" pt="20px">Niveau</Typography>
                <AppDivider />
                <AppBox>
                    <AppTextField
                        select 
                        label="Choix de cycle" 
                        value={cycle} 
                        onChange={(e) => handleCycleChange(e.target.value)}
                    >
                        {
                            cycleOption.map((data) => (
                                <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                            ))
                        }
                    </AppTextField>
                    <AppTextField
                        select 
                        label="Choix de la classe" 
                        value={classroom} 
                        onChange={(e) => handleClassChange(e.target.value)}
                    >
                    {classroomOptions.map((data) => (
                        <MenuItem key={data.id} value={data.id}>{data.label}</MenuItem>
                        ))
                    }
                    </AppTextField>
                </AppBox>

                <Typography color="primary" pt="20px">Information personnelle</Typography>
                <AppDivider />
                <AppBox>
                <AppTextField
                        label="Nom" 
                        value={lastName}
                        onClick={(e) => e.target.value === " " && setLastName("")}
                        onChange={(e) => setLastName(e.target.value)} 
                        error={!lastName}
                        helperText={
                            !lastName ? "Veuillez entrer le nom" : ""
                        }
                    />
                    <AppTextField 
                        label="Prenom" 
                        value={firstName} 
                        onClick={(e) => e.target.value === " " && setFirstName("")}
                        onChange={(e) => setFirstName(e.target.value)} 
                        error={!firstName}
                        helperText={
                            !firstName ? "Veuillez entrer le prenom" : ""
                        }
                    />
                </AppBox>
                <AppBox width="50%">
                    <AppTextField
                        select 
                        label="Genre" 
                        value={gender} 
                        onChange={(e) => setGender(e.target.value)}
                    >
                        {
                            genderOption.map((data) => (
                                <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                            ))
                        }
                    </AppTextField>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Date de naissance"
                            inputFormat="DD/MM/YYYY"
                            value={birthdate}
                            onChange={(value) => setBirthDate(value)}
                            maxDate={dayjs()}
                            renderInput={(params) => <AppTextField {...params} />}
                        />
                    </LocalizationProvider>
                </AppBox>
                <Typography color="primary" pt="20px">Information parent</Typography>
                <AppDivider />
                <AppBox>
                    <AppTextField
                        select 
                        label="Parent" 
                        value={parent} 
                        onChange={(e) => setParent(e.target.value)}
                    >
                        {
                            parentOption.map((data) => (
                                <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                            ))
                        }
                    </AppTextField>
                    <AppTextField 
                        label="Nom complet" 
                        value={parentName} 
                        onClick={(e) => e.target.value === " " && setParentName("")}
                        onChange={(e) => setParentName(e.target.value)} 
                        error={!parentName}
                        helperText={
                            !parentName ? "Veuillez entrer le nom complet" : ""
                        }
                    />
                    <AppTextField 
                        label="Telephone"
                        value={parentNumber} 
                        onClick={(e) => e.target.value === " " && setParentNumber("")}
                        onChange={(e) => e.target.value.length < 9 && setParentNumber(e.target.value)} 
                        error={!parentNumber}
                        helperText={
                            !parentNumber ? "Veuillez entrer le numero de telephone" : ""
                        }
                    />
                </AppBox>
                <Typography color="primary" pt="20px">Compte</Typography>
                <AppDivider />
                <AppBox>
                    <AppTextField
                        select
                        label="Est actif"
                        value={isActive}
                        onChange={(e) => setIsActive(e.target.value)} 
                    >
                    {activeOption.map((data) => (
                        <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                        ))
                    }
                    </AppTextField>

                </AppBox>
                <AppBox>
                    <AppTextField
                        sx={{ mt: 2 }}
                        aria-readonly 
                        disabled
                        label="NIU" 
                        value={noNiu}
                    />
                </AppBox>
                <AppBox>
                <AppTextField
                        sx={{ mt: 2 }}
                        aria-readonly 
                        disabled
                        label="Modifier le" 
                        value={dayjs(updatedAt).format('DD MMMM, HH:mm:ss')}
                    />
                    <AppTextField 
                        sx={{ mt: 2 }}
                        aria-readonly 
                        disabled
                        label="Créer le" 
                        value={dayjs(createdAt).format('DD MMMM, HH:mm:ss')}
                    />
                </AppBox>
            

                <Box mt={2}>
                    <LoadingButton loading={loading} onClick={() => updateStudent()} variant='contained' loadingPosition='start' startIcon={<Save />} disableElevation>
                        Modifier
                    </LoadingButton>
                    <Button startIcon={<Cancel />} variant='contained' color='error' sx={{ mx: 2 }} onClick={() => setOpenDeletionDialog(true)}>
                        Supprimer
                    </Button>
                </Box>
            </Stack>
        )
   } 
   {
        isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        )
   }
    
    <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
    <ComfirmDeletionDialog open={openDeletionDialog} handleSuccess={() => handleSucessDeletionDialog()} setOpen={(isOpen) => setOpenDeletionDialog(isOpen)} />
    </>
  )
}

export default StudentUpdate