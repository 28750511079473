import { Box, colors, Grid, Typography } from '@mui/material'
import React from 'react'
import MonthItemDialog from './MonthItemDialog'

const MonthItem = ({monthName, monthId,paid,total,color,niu, getYears, getPaiements, kind}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  return (
    <>
        <Grid onClick={() => handleOpenDialog()} item xs={3} borderRight={1} borderBottom={1} borderColor={colors.grey['300']} sx={{  paddingLeft: '0px!important' }}>
            <Box p={3}>
                <Box textAlign='end'>
                    <Typography variant='h6' fontWeight='light'>{monthName}</Typography>
                </Box>
                <Box mt={4} mb={6} textAlign='center'>
                    <Typography variant='h5'>{paid}/{total}</Typography>
                </Box>
            </Box>
            <Box sx={{ bgcolor: color, width:'100%', height: '30px' }}>
                <Typography color='white'></Typography>
            </Box>
        </Grid>
        <MonthItemDialog
            niu={niu}
            monthId={monthId}
            monthName={monthName}
            total={total}
            paid={paid}
            openDialog={openDialog}
            setOpenDialog={(value) => setOpenDialog(value)}
            kind={kind}
            getYears={() => getYears()}
            getPaiements={() => getPaiements()}
        />
    </>
  )
}

export default MonthItem