import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

const Unauthorized = () => {
  return (
    <Alert sx={{ m: 2 }} severity="error">
        <AlertTitle>Accès réfusé </AlertTitle>
        Erreur — <strong>Vous n'êtes pas authorisé à accéder à cette page</strong>
    </Alert>
  )
}

export default Unauthorized