import { API } from "./index";
const studentCount = () => API.get(`/dashboard/studentCount`)
const personalCount = () => API.get(`/dashboard/personalCount`)
const subscribePrice = () => API.get(`/dashboard/subscribePrice`)

const cumulPaiement = () => API.get('/dashboard/cumulPaiement')
const cumulIncome = () => API.get('/dashboard/cumulIncome')
const cumulExpense = () => API.get('/dashboard/cumulExpense')
const cumulProfessorExpense = () => API.get('/dashboard/cumulProfessorExpense')

const monthlyPaiement = () => API.get('/dashboard/monthlyPaiement')
const monthlyIncome = () => API.get('/dashboard/monthlyIncome')
const monthlyExpense = () => API.get('/dashboard/monthlyExpense')
const monthlyProfessorExpense = () => API.get('/dashboard/monthlyProfessorExpense')


export const dashboardAPI = {
    studentCount,
    personalCount,
    subscribePrice,
    cumulPaiement,
    cumulIncome,
    cumulExpense,
    cumulProfessorExpense,
    monthlyPaiement,
    monthlyIncome,
    monthlyExpense,
    monthlyProfessorExpense
}