import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, colors } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EmptyDataAlert from '../../global/EmptyDataAlert'
import { Add, Cancel, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import AppSnackbar from '../../global/snackbar/Snackbar'
import { presenceAPI } from '../../../api/presence'
import styled from '@emotion/styled'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import AppTextField from '../AppTextField'
import { yearAPI } from '../../../api/year'
import { monthOption } from '../../../config/defaultValue'
import { getDateDifference } from '../../../config/getDateDifferense'
import { getTotalMinutes } from '../../../config/getTotalMinutes'

const DividerBox = styled(Box)(({ theme }) => ({
    width: "100%", 
    height: "2px", 
    backgroundColor: colors.purple[900], 
    borderRadius: "2px", 
    marginBottom: "15px"
}));

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') 

dayjs.extend(utc)
dayjs.extend(timezone)
const currentTimezone = dayjs.tz.guess() 
dayjs.tz.setDefault(currentTimezone)


const PersonalPresence = ({niu}) => {

    const [yearsList, setYearsList] = useState([])
    const [year, setYear] = useState('')
    const [presencesList, setPresencesList] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentDate, setCurrentDate] = useState(dayjs())
    const [start, setStart] = useState(dayjs())
    const [end, setEnd] = useState(dayjs())

    const handleYearChange = (year) =>  {
        setYear(year)
        localStorage.setItem('year', year)
    }


    const [startValid, setStartValid] = useState(false)
    const [endValid, setEndValid] = useState(false)

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState("success");

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (event, raison) => {

        if(["escapeKeyDown", "backdropClick"].includes(raison))
        {
        return;
        }
    
        setOpenDialog(false);
    };

    const getYears = () => {
        setLoading(true)
        yearAPI.getYear()
            .then(({data}) => {
                const _data = data.map(item => item.year)
                setYearsList(_data);

                let year = localStorage.getItem('year') ? localStorage.getItem('year') : _data[_data.length - 1]

                setYear(year)

            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
            })
    } 

    const addPresence = () => {
        setLoading(true);

        presenceAPI.postPresence({
            personalNiu: niu,
            date: currentDate,
            start: start,
            end: end
        })
            .then(({data}) => {
                if(data.success){
                    setSnackbarMessage("Année ajouter avec succès");
                    setSnackbarType("success");
                    setOpenSnackbar(true);

                    getPresences();
                }
                else {
                    setSnackbarMessage("Erreur lors de l'enregistrement de l'année");
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                }

                setStart(dayjs());
                setEnd(dayjs());

                handleCloseDialog();

                setLoading(false);

            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const getPresences = () => {
        setLoading(true)
        presenceAPI.getPresence(niu,year)
            .then(({data}) => {
                setPresencesList(data);

            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getYears()
        getPresences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year])

    const monthTotalMinutes = []
    for (let index = 0; index < 12; index++) {

        const m = presencesList.filter((item) => dayjs(item.date).month()  === index)

        let item = {}
        item["key"] = index.toString();
        item["total"] = getTotalMinutes(m);

        monthTotalMinutes.push(item)

    }

    // console.log(monthTotalMinutes);

    // const m1 = presencesList.filter((item) => dayjs(item.date).month()  === 0)
    // const m1Minutes = getTotalMinutes(m1)
    

    return (
        <>
            <Stack p={5}>
                <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end" gap={2}>
                    <TextField
                        select 
                        label="Année" 
                        value={year} 
                        onChange={(event) => handleYearChange(event.target.value)}
                    >
                    {
                        yearsList.map((year) => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                        )
                      )
                    }
                    </TextField>
                    <Button size='large' variant='contained' endIcon={ <Add /> } onClick={() => handleOpenDialog()}>Ajouter</Button>
                </Box>
                <Typography color="primary" variant='subtitle1'>Heures cumulées</Typography>
                <DividerBox />

                <Grid container spacing={2}>
                    <Grid item xs={10}>
                         {
                            presencesList.length === 0 && (
                                <EmptyDataAlert />
                            )
                        }

                        {
                            presencesList.length > 0 && (
                                <TableContainer component={Paper}>
                                    <Typography mx={2} variant='h6'>Presences</Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Debut</TableCell>
                                                <TableCell>Fin</TableCell>
                                                <TableCell align='right'>Temps</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                presencesList.map(row => {
                                                return (
                                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell>{row.date}</TableCell>
                                                        <TableCell>{dayjs(row.start).format('HH:mm')}</TableCell>
                                                        <TableCell>{dayjs(row.end).format('HH:mm')}</TableCell>
                                                        <TableCell align='right'>{getDateDifference(row.start, row.end)}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container>
                            <TableContainer component={Paper}>
                                <Typography mx={2} variant='h6'>H/mois</Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Mois</TableCell>
                                            <TableCell align='right'>Temps</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            monthTotalMinutes.map(row => {

                                                //let key = parseInt(row.key) + 1
                                                let month = monthOption.find(i => i.key === parseInt(row.key) + 1)

                                                //data['monthName'] = month.value
                                                //console.log(row.key + 1);
                                                return (
                                                    <TableRow key={row.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell>{month.value}</TableCell>
                                                        <TableCell align='right'>{row.total}</TableCell>
                                                    </TableRow>
                                                )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

            </Stack>
            <Dialog open={openDialog}>
                <DialogTitle>
                    <Typography>Entrez la plage</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ py:1}} gap={2}>
                        <Box sx={{ textAlign:"center", display: 'flex', justifyContent: "center", my: 1 }}>
                            <Box sx={{ border: "0px solid", borderRadius: 20, width: "8em", height: "8em", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography sx={{ fontSize: "6em"  }}>{getDateDifference(start, end)}</Typography>
                            </Box>
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date"
                                inputFormat="DD/MM/YYYY"
                                value={currentDate}
                                onChange={(value) => setCurrentDate(value)}
                                maxDate={dayjs()}
                                renderInput={(params) => <AppTextField {...params} />}
                            />
                            <Box display="flex" flexDirection="row" gap={2} mt={2}>
                                <TimePicker
                                    label="Debut"
                                    ampm={false}
                                    value={start}
                                    minutesStep={15}
                                    onChange={(value) => setStart(value)}
                                    onAccept={(e) => setStartValid(true)}
                                    renderInput={(params) => <AppTextField {...params} />}
                                />
                                <TimePicker
                                    label="Fin"
                                    ampm={false}
                                    value={end}
                                    minTime={start.add(1,'hour')}
                                    minutesStep={15}
                                    onChange={(value) => setEnd(value)}
                                    onAccept={(e) => setEndValid(true)}
                                    renderInput={(params) => <AppTextField {...params} />}
                                />
                            </Box>
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p:2 }}>
                    <Box display="flex" flexDirection="row" gap={2}>
                        <Button variant="contained" endIcon={ <Cancel /> } onClick={() => handleCloseDialog()}> Annuler </Button>
                        <LoadingButton variant="contained" endIcon={ <Save /> } onClick={() => addPresence()} loadingPosition='end' loading={loading} disabled={!startValid || !endValid}> Enregistrer </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
        </>
    )
}

export default PersonalPresence