import { API } from "./index";


const getPersonalByNiu = (niu) => API.get(`/personal/${niu}`);
const getPersonals = () => API.get('/personal');
const postPersonal = (data) => API.post('/personal', data, {
    headers: {
    'Content-Type': 'multipart/form-data'
  }
});

const putPersonal = (niu,data) => API.put(`/personal/${niu}`, data, {
    headers: {
    'Content-Type': 'multipart/form-data'
  }
});

const deletePersonal = (niu,data) => API.delete(`/personal/${niu}`, data);

const getProfessor = () => API.get('/personal/professor');
const getProfessorByChar = (char) => API.get(`/personal/professor/search/${char}`);
const getProfessorByCycle = (cycle) => API.get(`/personal/professor/cycle/${cycle}`);
const getProfessorByClassroom = (classroom) => API.get(`/personal/professor/classroom/${classroom}`);


const getAdministrationByChar = (char) => API.get(`/personal/administration/search/${char}`);
const getAdministration = () => API.get('/personal/administration');
const getAdministrationByJob = (job) => API.get(`/personal/administration/job/${job}`);



export const personalAPI = {
    getPersonalByNiu,
    getPersonals,
    postPersonal,
    putPersonal,
    deletePersonal,

    getProfessor,
    getProfessorByChar,
    getProfessorByCycle,
    getProfessorByClassroom,

    getAdministration,
    getAdministrationByChar,
    getAdministrationByJob,
}
