import React from 'react'
import useAuth from '../../hooks/useAuth'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useActivity from '../../hooks/useActivity'

const RequireAuth = ({allowedRoles}) => {
    useActivity()
    let { auth } = useAuth()
    //console.log(auth);
    const location = useLocation()

    return (
        allowedRoles.includes(auth?.role?.toLowerCase()) 
            ? auth?.mustChangePassword === true
                ? <Navigate to="/changePassword" state={{ from: location }} replace />
                : <Outlet />
            : auth?.niu 
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default RequireAuth