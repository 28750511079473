import { Cancel } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material'
import { PDFViewer } from '@react-pdf/renderer';
import React from 'react'
import File from '../pdf/File';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PaiementPreviewDialog = ({openDialog, setOpenDialog,receipt, resource}) => {

    const handleCloseDialog = (event, raison) => {
      if(["escapeKeyDown", "backdropClick"].includes(raison))
      {
        return;
      }
      setOpenDialog(false);
    };

    return (

        <Dialog maxWidth='md' fullWidth open={openDialog} onClose={handleCloseDialog}  TransitionComponent={Transition} scroll='paper'>
            <DialogContent>
                <PDFViewer style={{ width: "100%", height: '800px'}}>
                    <File receipt={receipt} resource={resource} />
                </PDFViewer>
            </DialogContent>
            <DialogActions sx={{px: 3, mb: 3}}>
                <Button variant='contained' startIcon={ <Cancel /> } disableElevation onClick={handleCloseDialog}>Annuler</Button>
          </DialogActions>
        </Dialog>
    )
}

export default PaiementPreviewDialog