import styled from '@emotion/styled';
import { Box, colors } from '@mui/material';
import React from 'react'

const DividerBox = styled(Box)(({ theme }) => ({
        width: "100%", 
        height: "2px", 
        backgroundColor: colors.purple[900], 
        borderRadius: "2px", 
        marginBottom: "15px"
    }));

const AppDivider = (props) => {
    
  return (
    <DividerBox {...props} />
  )
}

export default AppDivider