export const activeOption = [
  {
    "key": true,
    "value": "Oui"
  },
  {
    "key": false,
    "value": "Non"
  }
]

export const parentOption = [
  {
    "key": "P",
    "value": "Père"
  },
  {
    "key": "M",
    "value": "Mere"
  }
]

export const genderOption = [
  {
    "key": "M",
    "value": "Masculin"
  },
  {
    "key": "F",
    "value": "Feminin"
  }
]

export const salaryOption = [
  {
    "key": true,
    "value": "Fixe"
  },
  {
    "key": false,
    "value": "Par heure"
  }
]

export const cycleOption = [
    {   "key": 1,
        "value": "Crêche"
    },
    {   "key": 2,
        "value": "Maternelle"
    },
    {   "key": 3,
        "value": "Premier cycle"
    },
    {   "key": 4,
        "value": "Second cycle"
    },
]

export const cycleOneOption = [
    {   "key": 1,
        "value": "Crêche"
    }
]

export const cycleTwoOption = [
    {   "key": 1,
        "value": "Petite"
    },
    {   "key": 2,
        "value": "Moyenne"
    },
    {   "key": 3,
        "value": "Grande"
    },
]

export const cycleThreeOption = [
    {   "key": 1,
        "value": "Premiere"
    },
    {   "key": 2,
        "value": "Deuxieme"
    },
    {   "key": 3,
        "value": "Troisieme"
    },
    {   "key": 4,
        "value": "Quatrieme"
    },
    {   "key": 5,
        "value": "Cinquieme"
    },
    {   "key": 6,
        "value": "Sixieme"
    }
]

export const cycleFourOption = [
    {   "key": 1,
        "value": "Septieme"
    },
    {   "key": 2,
        "value": "Huiteme"
    },
    {   "key": 3,
        "value": "Neuvieme"
    },
]

export const monthOption = [
    {   "key": 1,
        "value": "Janvier"
    },
    {   "key": 2,
        "value": "Fevrier"
    },
    {   "key": 3,
        "value": "Mars"
    },
    {   "key": 4,
        "value": "Avril"
    },
    {   "key": 5,
        "value": "Mai"
    },
    {   "key": 6,
        "value": "Juin"
    },
    {   "key": 7,
        "value": "Juillet"
    },
    {   "key": 8,
        "value": "Aout"
    },
    {   "key": 9,
        "value": "Septembre"
    },
    {   "key": 10,
        "value": "Octobre"
    },
    {   "key": 11,
        "value": "Novembre"
    },
    {   "key": 12,
        "value": "Decembre"
    }
];

export const yearData = [
    {   "key": "2021",
        "value": "2021"
    },
    {   "key": "2021",
        "value": "2022"
    },
    {   "key": "2023",
        "value": "2023"
    }
];

export const reasonData = [
    "Loyer",
    "Internet",
    "Eau/Electricité"
]

export const data1 = [
{
  "id": 1,
  "firstName": "Harper",
  "lastName": "Querree",
  "birthDate": "9/22/1999",
  "tel": "46-86-91-84",
  "mother": "Harper Querree",
  "gender": "Male"
}, 
{
  "id": 2,
  "firstName": "Claudina",
  "lastName": "Theze",
  "birthDate": "1/28/2000",
  "tel": "02-23-03-77",
  "mother": "Claudina Theze",
  "gender": "Female"
}, 
{
  "id": 3,
  "firstName": "Reinold",
  "lastName": "Ondrusek",
  "birthDate": "3/15/1999",
  "tel": "85-64-68-16",
  "mother": "Reinold Ondrusek",
  "gender": "Male"
}, {
  "id": 4,
  "firstName": "Tilda",
  "lastName": "Schuler",
  "birthDate": "7/2/1996",
  "tel": "39-90-32-38",
  "mother": "Tilda Schuler",
  "gender": "Female"
}, {
  "id": 5,
  "firstName": "Leonid",
  "lastName": "McShane",
  "birthDate": "11/14/1999",
  "tel": "71-26-59-00",
  "mother": "Leonid McShane",
  "gender": "Polygender"
},{
  "id": 6,
  "firstName": "Harper",
  "lastName": "Querree",
  "birthDate": "9/22/1999",
  "tel": "46-86-91-84",
  "mother": "Harper Querree",
  "gender": "Male"
}, {
  "id": 7,
  "firstName": "Claudina",
  "lastName": "Theze",
  "birthDate": "1/28/2000",
  "tel": "02-23-03-77",
  "mother": "Claudina Theze",
  "gender": "Female"
}, {
  "id": 8,
  "firstName": "Reinold",
  "lastName": "Ondrusek",
  "birthDate": "3/15/1999",
  "tel": "85-64-68-16",
  "mother": "Reinold Ondrusek",
  "gender": "Male"
}, {
  "id": 9,
  "firstName": "Tilda",
  "lastName": "Schuler",
  "birthDate": "7/2/1996",
  "tel": "39-90-32-38",
  "mother": "Tilda Schuler",
  "gender": "Female"
}, {
  "id": 10,
  "firstName": "Leonid",
  "lastName": "McShane",
  "birthDate": "11/14/1999",
  "tel": "71-26-59-00",
  "mother": "Leonid McShane",
  "gender": "Polygender"
}]

export const data2 = [{
  "id": 1,
  "firstName": "Mordy",
  "lastName": "Kobke",
  "birthDate": "8/12/1997",
  "tel": "41-55-69-97",
  "mother": "Mordy Kobke",
  "gender": "Genderfluid"
}, {
  "id": 2,
  "firstName": "Andres",
  "lastName": "Elles",
  "birthDate": "3/30/1998",
  "tel": "35-99-62-69",
  "mother": "Andres Elles",
  "gender": "Male"
}, {
  "id": 3,
  "firstName": "Hildy",
  "lastName": "Ewbank",
  "birthDate": "9/10/2000",
  "tel": "64-27-14-28",
  "mother": "Hildy Ewbank",
  "gender": "Female"
}, {
  "id": 4,
  "firstName": "Sully",
  "lastName": "Orteaux",
  "birthDate": "5/10/1997",
  "tel": "62-44-26-01",
  "mother": "Sully Orteaux",
  "gender": "Male"
}, {
  "id": 5,
  "firstName": "Joelynn",
  "lastName": "Schollick",
  "birthDate": "11/28/1996",
  "tel": "39-03-39-20",
  "mother": "Joelynn Schollick",
  "gender": "Female"
}]

export const data3 = [{
  "id": 1,
  "firstName": "Mordy",
  "lastName": "Kobke",
  "math": "89",
  "biologie": "67",
  "eps": "98",
  "physique": "95",
  "chimie": "45",
}, {
  "id": 2,
  "firstName": "Andres",
  "lastName": "Elles",
  "math": "56",
  "biologie": "54",
  "eps": "75",
  "physique": "47",
  "chimie": "67",
}, {
  "id": 3,
  "firstName": "Hildy",
  "lastName": "Ewbank",
  "math": "73",
  "biologie": "73",
  "eps": "85",
  "physique": "95",
  "chimie": "64",
}, {
  "id": 4,
  "firstName": "Sully",
  "lastName": "Orteaux",
  "math": "25",
  "biologie": "73",
  "eps": "57",
  "physique": "85",
  "chimie": "63",
}, {
  "id": 5,
  "firstName": "Joelynn",
  "lastName": "Schollick",
  "math": "54",
  "biologie": "66",
  "eps": "67",
  "physique": "74",
  "chimie": "78",
}]

export const data4 = [{
  "id": 1,
  "firstName": "Mordy",
  "lastName": "Kobke",
  "math": "89",
  "biologie": "67",
  "eps": "98",
  "physique": "95",
  "chimie": "45",
}, {
  "id": 2,
  "firstName": "Andres",
  "lastName": "Elles",
  "math": "56",
  "biologie": "54",
  "eps": "75",
  "physique": "47",
  "chimie": "67",
}, {
  "id": 3,
  "firstName": "Hildy",
  "lastName": "Ewbank",
  "math": "73",
  "biologie": "73",
  "eps": "85",
  "physique": "95",
  "chimie": "64",
}, {
  "id": 4,
  "firstName": "Sully",
  "lastName": "Orteaux",
  "math": "25",
  "biologie": "73",
  "eps": "57",
  "physique": "85",
  "chimie": "63",
}, {
  "id": 5,
  "firstName": "Joelynn",
  "lastName": "Schollick",
  "math": "54",
  "biologie": "66",
  "eps": "67",
  "physique": "74",
  "chimie": "78",
},{
  "id": 6,
  "firstName": "Joelynn",
  "lastName": "Schollick",
  "math": "54",
  "biologie": "66",
  "eps": "67",
  "physique": "74",
  "chimie": "78",
},{
  "id": 7,
  "firstName": "Joelynn",
  "lastName": "Schollick",
  "math": "54",
  "biologie": "66",
  "eps": "67",
  "physique": "74",
  "chimie": "78",
}]

export const personnalListData = [{
  "id": 1,
  "firstName": "Mordy",
  "lastName": "Kobke",
  "birthDate": "8/12/1997",
  "tel": "41-55-69-97",
  "job": "Directeur",
  "gender": "Genderfluid"
}, {
  "id": 2,
  "firstName": "Andres",
  "lastName": "Elles",
  "birthDate": "3/30/1998",
  "tel": "35-99-62-69",
  "job": "Surveillant",
  "gender": "Male"
}, {
  "id": 3,
  "firstName": "Hildy",
  "lastName": "Ewbank",
  "birthDate": "9/10/2000",
  "tel": "64-27-14-28",
  "job": "Econome",
  "gender": "Female"
}, {
  "id": 4,
  "firstName": "Sully",
  "lastName": "Orteaux",
  "birthDate": "5/10/1997",
  "tel": "62-44-26-01",
  "job": "Gardien",
  "gender": "Male"
}, {
  "id": 5,
  "firstName": "Joelynn",
  "lastName": "Schollick",
  "birthDate": "11/28/1996",
  "tel": "39-03-39-20",
  "job": "Comptable",
  "gender": "Female"
}]

export const professorListData = [{
  "id": 1,
  "firstName": "Mordy",
  "lastName": "Kobke",
  "birthDate": "8/12/1997",
  "tel": "41-55-69-97",
  "classroom": "Deuxieme",
  "gender": "Genderfluid"
}, {
  "id": 2,
  "firstName": "Andres",
  "lastName": "Elles",
  "birthDate": "3/30/1998",
  "tel": "35-99-62-69",
  "classroom": "Petite",
  "gender": "Male"
}, {
  "id": 3,
  "firstName": "Hildy",
  "lastName": "Ewbank",
  "birthDate": "9/10/2000",
  "tel": "64-27-14-28",
  "classroom": "Septieme",
  "gender": "Female"
}, {
  "id": 4,
  "firstName": "Sully",
  "lastName": "Orteaux",
  "birthDate": "5/10/1997",
  "tel": "62-44-26-01",
  "classroom": "Sixieme",
  "gender": "Male"
}, {
  "id": 5,
  "firstName": "Joelynn",
  "lastName": "Schollick",
  "birthDate": "11/28/1996",
  "tel": "39-03-39-20",
  "classroom": "Quatrieme",
  "gender": "Female"
}]

export const jobOptions = [
    {   "key": "1",
        "value": "Corps enseignant"
    },
    {   "key": "2",
        "value": "Surveillant"
    },
    {   "key": "3",
        "value": "Econome"
    },
    {   "key": "4",
        "value": "Gardient"
    },
    {   "key": "5",
        "value": "Proviseur"
    },
    {   "key": "6",
        "value": "Promoteur"
    },
    {   "key": "7",
        "value": "Secretaire"
    },
    {   "key": "8",
        "value": "Cantine"
    },
    {   "key": "9",
        "value": "Comptable"
    },
    {   "key": "10",
        "value": "Directeur"
    },
]

export const cylceZeroOption = [
    {   "key": "1",
        "value": "Petite"
    },
    {   "key": "2",
        "value": "Moyenne"
    },
    {   "key": "3",
        "value": "Grande"
    },
]

export const cylceOneOption = [
    {   "key": "1",
        "value": "Premiere"
    },
    {   "key": "2",
        "value": "Deuxieme"
    },
    {   "key": "3",
        "value": "Troisieme"
    },
    {   "key": "4",
        "value": "Quatrieme"
    },
    {   "key": "5",
        "value": "Cinquieme"
    },
    {   "key": "6",
        "value": "Sixieme"
    }
]

export const cylceTwoOption = [
    {   "key": "1",
        "value": "Septieme"
    },
    {   "key": "2",
        "value": "Huiteme"
    },
    {   "key": "3",
        "value": "Neuvieme"
    },
]