import { Cancel, Edit, Save } from '@mui/icons-material';
import { Box, Button, colors, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { classroomAPI } from '../../api/classroom';
import { LoadingButton } from '@mui/lab';
import AppSnackbar from '../global/snackbar/Snackbar';
import { lisibleNumber } from '../../config/lisibleNumber';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClassroomItem = ({label, studentNumber, subscribePrice, getClassrooms, monthlyPrice, id}) => {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = useState(false);
  
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (event, raison) => {

        if(["escapeKeyDown", "backdropClick"].includes(raison))
        {
        return;
        }
    
        setOpenDialog(false);
        getClassrooms();
    };

    const [newSubscribePrice, setNewSubscribePrice] = useState(subscribePrice);
    const [newMonthlyPrice, setNewMonthlyPrice] = useState(monthlyPrice);
    const [disabled, setDisabled] = useState(true);
    const oldSubscribePrice = subscribePrice;
    const oldMonthlyPrice = monthlyPrice;


    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);


    const handleSetNewSubscribePrice = (value) => {
        setNewSubscribePrice(value);
        if(value !== oldSubscribePrice.toString()){
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }
    }

    
    const handleSetNewMonthlyPrice = (value) => {
        setNewMonthlyPrice(value);
        if(value !== oldMonthlyPrice.toString()){
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }
    }

    // update subscribe price in db
    const updateSubscribePrice  = () => {
        //make load
        setLoading(true);

        // api put price
        classroomAPI.putClassroom({
            subscribePrice: newSubscribePrice,
            monthlyPrice: newMonthlyPrice,
            id: id
        })
        .then(({data}) => {
            if(data.success) {
                // close dialog
                handleCloseDialog();

                // disable button for forcing subscribe price changement
                setDisabled(true);

                // set open snackbar
                setOpenSnackbar(true);

                // stop loading
                setLoading(false);
            }
        })
    }


    return (
        <>
            <Box border={0.8} borderRadius={3} width="230px" p={3} onClick={() => handleOpenDialog()} 
                sx={{ "&:hover": { borderColor: colors.purple[900], borderWidth: 0.8, boxShadow: 5  } }}
            >
                <Box display='flex' justifyContent="space-between" alignItems="baseline">
                    <Typography variant='h5'>{label}</Typography>
                    <IconButton color='primary' variant="contained" size='small' onClick={() => handleOpenDialog()}> <Edit fontSize='small'/> </IconButton>
                </Box>

                <Box display='flex' justifyContent="space-between" alignItems="baseline">
                    <Typography variant='caption'>Nombre d'eleve</Typography>
                    <Typography fontWeight="bold">{studentNumber}</Typography>
                </Box>
                <Box  display='flex' justifyContent="space-between" alignItems="baseline">
                    <Typography variant='caption'>Inscription </Typography>
                    <Typography fontWeight="bold">{lisibleNumber(subscribePrice)} CFA</Typography>
                </Box>
                <Box  display='flex' justifyContent="space-between" alignItems="baseline">
                    <Typography variant='caption'>Mensualiyé </Typography>
                    <Typography fontWeight="bold">{lisibleNumber(monthlyPrice)} CFA</Typography>
                </Box>
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}  TransitionComponent={Transition}>
                <DialogTitle>
                    <Typography>{label}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                         <TextField 
                            label="Inscription"
                            type="text"
                            variant="standard"
                            value={newSubscribePrice}
                            onChange={e => handleSetNewSubscribePrice(e.target.value)}
                            onClick={e => e.target.value === "0" ? e.target.value = "" : null}
                        />
                         <TextField 
                            label="Mensualité"
                            type="text"
                            variant="standard"
                            value={newMonthlyPrice}
                            onChange={e => handleSetNewMonthlyPrice(e.target.value)}
                            onClick={e => e.target.value === "0" ? e.target.value = "" : null}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p:2 }}>
                    <Box display="flex" flexDirection="row" gap={2}>
                        <Button variant="contained" endIcon={ <Cancel /> } onClick={() => handleCloseDialog()}> Annuler </Button>
                       <LoadingButton disabled={disabled} variant="contained" endIcon={ <Save /> } onClick={updateSubscribePrice} loadingPosition='end' loading={loading}> Enregistrer </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message="Prix d'inscription modifié avec succès" />
        </>

        
    )
}

export default ClassroomItem