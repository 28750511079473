import { CameraAlt, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers'
import { Box, colors, MenuItem, Stack, Typography } from '@mui/material'
import React, {useRef, useState} from 'react'
import dayjs from 'dayjs'
import { cycleOption, genderOption, parentOption } from '../../config/defaultValue'
import { generateNiu } from '../../config/niu'
import { studentAPI } from '../../api/student'
import AppSnackbar from '../global/snackbar/Snackbar'
import AppBox from '../profile/AppBox'
import AppTextField from '../profile/AppTextField'
import AppDivider from '../profile/AppDivider'
import { StudentSchema } from '../../validations/StudentSchema'


// backgroundColor: 'rgba(49,27,146, 0.08)', border: '1px solid rgb(49,27,146)'
// backgroundColor: 'rgba(0, 230, 118, 0.08)', border: '1px solid rgb(0,230,118)'


const ClassroomSubscribe = ({ cycleOneData, cycleTwoData, cycleThreeData, cycleFourData }) =>  {

    const [cycle, setCycle] = useState(1)
    const [classroom, setClassroom] = useState(cycleOneData[0].id)

    const [classroomOptions, setClassroomOptions] = useState(cycleOneData)

    const handleCycleChange = (event) => {
        const _cycle = event.target.value
        setCycle(_cycle)
        if(_cycle === 1){
            setClassroom(cycleOneData[0].id)
            setClassroomOptions(cycleOneData)
        }
        else if(_cycle === 2){
            setClassroom(cycleTwoData[0].id)
            setClassroomOptions(cycleTwoData)
        }
        else if(_cycle === 3) {
            setClassroom(cycleThreeData[0].id)
            setClassroomOptions(cycleThreeData)
        }
        else if(_cycle === 4) {
            setClassroom(cycleFourData[0].id)
            setClassroomOptions(cycleFourData)
        }
        else {
            return
        }

    }

    const handleClassChange = (event) => {
        setClassroom(event.target.value)
    }


    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarType, setSnackbarType] = useState("success")

    //loading 
    const [loading, setLoading] = useState(false)

    //photo
    const [imageFile, setImageFile] = useState(null)
    const [url,setUrl] = useState(null)

    // InputState
    const [firstName, setFirstName] = useState(" ")
    const [lastName, setLastName] = useState(" ")
    const [birthdate, setBirthDate] = useState(dayjs().subtract(7, 'month'))
    const [gender, setGender] = useState("M")

    // Parent
    const [parent, setParent] = useState("M")
    const [parentName, setParentName] = useState(" ")
    const [parentNumber, setParentNumber] = useState(" ")

    //image input
    const inputImage = useRef()

    const reinitializeState = ()  => {
        // InputState
        setFirstName(" ");
        setLastName(" ")
        setBirthDate(dayjs())
        setGender("M")

        // Parent
        setParent("M")
        setParentName(" ")
        setParentNumber(" ")

         //photo
        setImageFile(null)
        setUrl(null)

        //image input
        inputImage.current.value = null
    }

    const handleImageChange = (image) => {

        // console.log(image);
        const fileMimeType = image.type.replace('image/','')
        const fileTypes = /jpeg|jpg|png|gif/
        const isGoodMimeType = fileTypes.test(fileMimeType)
        if(isGoodMimeType) {
            setImageFile(image) 
            setUrl(URL.createObjectURL(image))

            //console.log(URL.createObjectURL(image));

            setOpenSnackbar(true)
            setSnackbarType("success")
            setSnackbarMessage("Image chargée avec succès")
        }
        else{
            setOpenSnackbar(true)
            setSnackbarType("error")
            setSnackbarMessage("Veuillez choisir une image")
        }
    }

    const addStudent = async () => {
        setLoading(true)

        // require image

        // if(!imageFile){
        //     setOpenSnackbar(true)
        //     setSnackbarType("error")
        //     setSnackbarMessage("Veuiller importer la photo de profile")

        //     setLoading(false)
        //     return
        // }

        const niu = generateNiu(firstName, lastName, birthdate)

        const student = {
            niu: niu,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            birthDate: birthdate.format('YYYY/MM/DD'),
            gender: gender,
            parent: parent,
            parentFullname: parentName.trim(),
            parentPhoneNumber: parentNumber.trim(),
            photoUrl: imageFile ? `${niu}.${imageFile.type.replace('image/','')}` : 'blank.jpg',
            isActive: true,
            createdAt: dayjs(),
            upatedAt: dayjs(),
            classroomId: classroom,
        }


        const isValid = await StudentSchema
            .validate(student)
            .catch(error => {
                // console.log(error.name);
                // console.log(error.errors);
                setOpenSnackbar(true)
                setSnackbarType("error")
                setSnackbarMessage(error.errors)
                setLoading(false)

                return
            })

        if(isValid) {
            const formData = new FormData();
            for ( var key in student ) {
                formData.append(key, student[key]);
            }

            if(imageFile){
                formData.append('image',imageFile, `${niu}`);
            }

            studentAPI.postStudent(formData)
                .then(({data}) => {
                    if(data.success){
                        setOpenSnackbar(true)
                        setSnackbarType("success")
                        setSnackbarMessage("Élève inscrit avec succès")
                        reinitializeState();
                    }
                    else {
                        setOpenSnackbar(true)
                        setSnackbarType("error")
                        setSnackbarMessage("Erreur lors de l'enregistrement de l'élève")

                    }

                    setLoading(false)

                })
                .catch((e) => {
                    console.log(e);
                    setOpenSnackbar(true)
                    setSnackbarType("error")
                    setSnackbarMessage("Erreur lors de l'enregistrement de l'élève")

                    setLoading(false)
                })
        }

    }



    return (
    <>
    <Stack p={5}>
        <AppBox>
            {
                !url && 
                <Box  
                    onClick={() => inputImage.current.click()}
                    display='flex'
                    width={200} 
                    height={200} 
                    border={1} 
                    borderColor={colors.grey['300']} 
                    bgcolor={colors.grey['200']} 
                    justifyContent='center'
                    alignItems='center'
                >
                    <CameraAlt sx={{ fontSize: '3rem', color: colors.grey['500'] }} />
                </Box>
            }
            {
                url && 
                <Box
                    onClick={() => inputImage.current.click()}
                    display='flex'
                    width={200} 
                    height={200} 
                    border={1} 
                    borderColor={colors.grey['300']} 
                    bgcolor={colors.grey['200']} 
                    justifyContent='center'
                    alignItems='center'
                >
                    <img src={url} alt='screenshot' className='img-fluid' />
                </Box>
            }
            <input 
                style={{ visibility: 'hidden' }}
                ref={inputImage} 
                type='file' 
                name='uploaded_file'
                onChange={(e) => handleImageChange(e.target.files[0])} 
            />
        </AppBox>
        <Typography color="primary" pt="20px">Niveau</Typography>
        <AppDivider />
        <AppBox>
             <AppTextField
                select 
                label="Choix de cycle" 
                value={cycle} 
                onChange={handleCycleChange}
            >
                {
                    cycleOption.map((data) => (
                        <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                    ))
                }
            </AppTextField>
            <AppTextField
                select 
                label="Choix de la classe" 
                value={classroom} 
                onChange={handleClassChange}
            >
            {classroomOptions.map((data) => (
                <MenuItem key={data.id} value={data.id}>{data.label}</MenuItem>
                ))
            }
            </AppTextField>
        </AppBox>

        <Typography color="primary" pt="20px">Information personnelle</Typography>
        <AppDivider />
        <AppBox>
            <AppTextField 
                label="Prenom" 
                value={firstName} 
                onClick={(e) => e.target.value === " " && setFirstName("")}
                onChange={(e) => setFirstName(e.target.value)} 
                error={!firstName}
                helperText={
                    !firstName ? "Veuillez entrer le prenom" : ""
                }
            />
           <AppTextField
                label="Nom" 
                value={lastName}
                onClick={(e) => e.target.value === " " && setLastName("")}
                onChange={(e) => setLastName(e.target.value)} 
                error={!lastName}
                helperText={
                    !lastName ? "Veuillez entrer le nom" : ""
                }
            />
        </AppBox>
        <AppBox width="50%">
            <AppTextField
                select 
                label="Genre" 
                value={gender} 
                onChange={(e) => setGender(e.target.value)}
            >
                {
                    genderOption.map((data) => (
                        <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                    ))
                }
            </AppTextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Date de naissance"
                    inputFormat="DD/MM/YYYY"
                    value={birthdate}
                    onChange={(value) => setBirthDate(value)}
                    maxDate={dayjs().subtract(7,'month')}
                    renderInput={(params) => <AppTextField {...params} />}
                />
            </LocalizationProvider>
        </AppBox>


        <Typography color="primary" pt="20px">Information parent</Typography>
        <AppDivider />
        <AppBox>
            <AppTextField
                select 
                label="Parent" 
                value={parent} 
                onChange={(e) => setParent(e.target.value)}
            >
                {
                    parentOption.map((data) => (
                        <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                    ))
                }
            </AppTextField>
            <AppTextField 
                label="Nom complet" 
                value={parentName} 
                onClick={(e) => e.target.value === " " && setParentName("")}
                onChange={(e) => setParentName(e.target.value)} 
                error={!parentName}
                helperText={
                    !parentName ? "Veuillez entrer le nom complet" : ""
                }
            />
             <AppTextField 
                label="Telephone"
                type="tel"
                value={parentNumber} 
                onClick={(e) => e.target.value === " " && setParentNumber("")}
                onChange={(e) => e.target.value.length < 9 && setParentNumber(e.target.value)} 
                error={!parentNumber}
                helperText={
                    !parentNumber ? "Veuillez entrer le numero de telephone" : ""
                }
            />
        </AppBox>

        <Box mt={2}>
             <LoadingButton loading={loading} onClick={() => addStudent()} variant='contained' loadingPosition='start' startIcon={<Save />} disableElevation>
                Inscrire
            </LoadingButton>
        </Box>
    </Stack>
    <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
    </>
  )
}

export default ClassroomSubscribe