import { Stack, Typography, Box, colors, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ClassroomItem from './ClassroomItem';
import { Add, Cancel, Save } from '@mui/icons-material';
import { cycleOneOption, cycleTwoOption, cycleThreeOption, cycleFourOption, cycleOption } from '../../config/defaultValue';
import { classroomAPI } from '../../api/classroom';
import AppSnackbar from '../global/snackbar/Snackbar';
import { LoadingButton } from '@mui/lab';
import { getClassroomLabel } from '../../config/getClassroomLabel';
import EmptyDataAlert from '../global/EmptyDataAlert';

const DividerBox = styled(Box)(({ theme }) => ({
    width: "100%", 
    height: "2px", 
    backgroundColor: colors.purple[900], 
    borderRadius: "2px", 
    marginBottom: "15px"
}));



const ClassroomList = () => {

    // state initialization
    const [cycle, setCycle] = useState(1);
    const [classroom, setClassroom] = useState(1);
    const [subscribePrice, setSubscribePrice] = useState(0);
    const [classeOptions, setClasseOptions] = useState(cycleOneOption);
    const [classroomList, setClassroomList] = useState([]);
    const [loading, setLoading] = useState(false);

    // set dialog classroom data depend to cycle
    const handleCycleChange = (event) => {
      const _cycle = event.target.value;
      // console.log(_cycle);
      setCycle(_cycle)
      if(_cycle === 1){
          setClasseOptions(cycleOneOption)
      }
      else if(_cycle === 2){
          setClasseOptions(cycleTwoOption)
      }
      else if(_cycle === 3) {
          setClasseOptions(cycleThreeOption)
      }
      else if(_cycle === 4) {
          setClasseOptions(cycleFourOption)
      }
      else{
        return;
      }
    }


    // updata classroom state
    const handleClassroomChange = (event) => {
        setClassroom(event.target.value)
    }

    // component state
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    
    // open dialog function
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // close dialog functon
    const handleCloseDialog = (event, raison) => {

        if(["escapeKeyDown", "backdropClick"].includes(raison))
        {
            return;
        }
        setOpenDialog(false);
    };

    
     const addClassroom = () => {
        // set button loading
        setLoading(true);

        // create classroom
        const classroomData = {
            cycle: parseInt(cycle),
            classroom: parseInt(classroom),
            subscribePrice: subscribePrice,
            label: getClassroomLabel(classroomList, cycle, classroom)
        }

        // post year data to server
        classroomAPI.postClassroom(classroomData)
            // on function finish
            .then(({data}) => {
                if(data.success){
                    // get classroom from databse
                    getClassrooms();
                   
                    //close dialog
                    handleCloseDialog();
                    //Show snackbar
                    setOpenSnackbar(true);

                    // set state to base state
                    setCycle(1);
                    setClassroom(1);
                    setSubscribePrice(0);


                     //set button loading
                    setLoading(false);
                }
            });

    }

    // get classroom from db and update state
    const getClassrooms = () => {
        classroomAPI.getClassroom()
            .then(({data}) => {
                setClassroomList(data);
                // console.log(data);

                return
            });
    }


    // on app launch get year data from db
    useEffect(() => {
        getClassrooms();
    }, []);

    const cycleOneData = classroomList.filter(
        (item) => item.cycle === 1
    );

    const cycleTwoData = classroomList.filter(
        (item) => item.cycle === 2
    );

    const cycleThreeData = classroomList.filter(
        (item) => item.cycle === 3
    );

    const cycleFourData = classroomList.filter(
        (item) => item.cycle === 4
    );

    // console.log("cycleOne", cycleOneData);
    // console.log("cycleTwo", cycleTwoData);

    return (
        <>
            <Stack>
                <Box width="100%" display="flex" flexDirection="column" alignItems="end">
                    <Button size='large' variant='contained' endIcon={ <Add /> } onClick={() => handleOpenDialog()}>Ajouter une classe</Button>
                </Box>
                <Typography color="primary" variant='subtitle1'>Creche</Typography>
                <DividerBox />
                {
                    cycleOneData.length <= 0 && (
                        <EmptyDataAlert />
                    )
                }
                <Box display="flex" flexWrap="wrap" flexGrow={1} gap={3}>
                    {
                        cycleOneData.length > 0 && cycleOneData.map((data) => (
                                <ClassroomItem 
                                    key={data.id} 
                                    id={data.id}
                                    getClassrooms = {() => getClassrooms()}
                                    setSubscribePrice = {() => setSubscribePrice()}
                                    label={data.label} 
                                    studentNumber={data.studentNumber} 
                                    subscribePrice={data.subscribePrice}
                                    monthlyPrice={data.monthlyPrice}
                                />
                            ))
                    }
                </Box>
                <Typography color="primary" variant='subtitle1' mt={5}>Maternelle</Typography>
                <DividerBox />
                {
                    cycleTwoData.length <= 0 && (
                        <EmptyDataAlert />
                    )
                }
                 <Box display="flex" flexWrap="wrap" flexGrow={1} gap={3}>
                    {
                        cycleTwoData.length > 0 && cycleTwoData.map((data) => (
                                <ClassroomItem 
                                    key={data.id} 
                                    id={data.id}
                                    getClassrooms = {() => getClassrooms()}
                                    setSubscribePrice = {() => setSubscribePrice()}
                                    label={data.label} 
                                    studentNumber={data.studentNumber}
                                    subscribePrice={data.subscribePrice}
                                    monthlyPrice={data.monthlyPrice}
                                />
                            ))
                    }
                </Box>

                <Typography color="primary" variant='subtitle1' mt={5}>Premier cycle</Typography>
                <DividerBox />
                {
                    cycleThreeData.length <= 0 && (
                        <EmptyDataAlert />
                    )
                }
                <Box display="flex" flexWrap="wrap" flexGrow={1} gap={3}>
                    {
                        cycleThreeData.length > 0 && cycleThreeData.map((data) => (
                                <ClassroomItem 
                                    key={data.id} 
                                    id={data.id}
                                    getClassrooms = {() => getClassrooms()}
                                    setSubscribePrice = {() => setSubscribePrice()}
                                    label={data.label} 
                                    studentNumber={data.studentNumber}
                                    subscribePrice={data.subscribePrice}
                                    monthlyPrice={data.monthlyPrice}
                                />
                            ))
                    }
                </Box>

                <Typography color="primary" variant='subtitle1' mt={5}>Second cycle</Typography>
                <DividerBox />
                {
                    cycleFourData.length <= 0 && (
                        <EmptyDataAlert />
                    )
                }
                <Box display="flex" flexWrap="wrap" flexGrow={1} gap={3}>
                    {
                        cycleFourData.length > 0 && cycleFourData.map((data) => (
                                <ClassroomItem 
                                    key={data.id} 
                                    id={data.id}
                                    getClassrooms = {() => getClassrooms()}
                                    setSubscribePrice = {() => setSubscribePrice()}
                                    label={data.label} 
                                    studentNumber={data.studentNumber}
                                    subscribePrice={data.subscribePrice}
                                    monthlyPrice={data.monthlyPrice}
                                />
                            ))
                    }
                </Box>
            </Stack>
            <Dialog open={openDialog}>
                <DialogTitle>
                    <Typography>Ajouter une nouvelle année</Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        required 
                        select 
                        fullWidth 
                        label="Choix de cycle" 
                        size='large' 
                        value={cycle} 
                        onChange={handleCycleChange}
                        variant='standard'
                    >
                        {
                            cycleOption.map(data => (
                                 <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                            ))
                        }
                    </TextField>

                    <TextField
                        required 
                        select 
                        fullWidth 
                        label="Choix de la classe" 
                        size='large' 
                        value={classroom} 
                        onChange={handleClassroomChange}
                        variant='standard'
                        sx={{ mt: 3 }}
                    >
                        {classeOptions.map((data) => (
                                 <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem>
                            ))
                        }
                    </TextField>
                </DialogContent>
                <DialogActions sx={{ p:2 }}>
                    <Box display="flex" flexDirection="row" gap={2}>
                        <Button variant="contained" endIcon={ <Cancel /> } onClick={() => handleCloseDialog()}> Annuler </Button>
                        <LoadingButton variant="contained" endIcon={ <Save /> } onClick={() => addClassroom()} loadingPosition='end' loading={loading}> Enregistrer </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message="Année ajoutée avec succès" />
        </>
    )
}

export default ClassroomList