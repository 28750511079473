import styled from '@emotion/styled';
import { Add, Cancel, Save } from '@mui/icons-material';
import { Box, Button, colors, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import AppSnackbar from '../global/snackbar/Snackbar';
import { jobAPI } from '../../api/job';
import EmptyDataAlert from '../global/EmptyDataAlert';

const DividerBox = styled(Box)(({ theme }) => ({
    width: "100%", 
    height: "2px", 
    backgroundColor: colors.purple[900], 
    borderRadius: "2px", 
    marginBottom: "15px"
}));

const JobList = () => {

    // state initialization
    const [jobsList, setJobsList] = useState([])
    const [job, setJob] = useState("");
    const [loading, setLoading] = useState(false);


    //component dialog / snackbar handler
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState("success");
  
    // open dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // close dialig
    const handleCloseDialog = (event, raison) => {

        if(["escapeKeyDown", "backdropClick"].includes(raison))
        {
        return;
        }
    
        setOpenDialog(false);
    };

    const addYear = () => {
        // set button loading
        setLoading(true);

        // post year data to server
        jobAPI.postJob({title: job})
            // on function finish
            .then(({data}) => {
                //console.log(data);
                if(data.success){
                    //Show snackbar
                    setSnackbarMessage("Année ajouter avec succès");
                    setSnackbarType("success");
                    setOpenSnackbar(true);

                    // get year from databse
                    getJobs();
                }
                else {
                    setSnackbarMessage("Erreur lors de l'enregistrement de l'année");
                    setSnackbarType("error");
                    setOpenSnackbar(true);
                }
                // set year to current year
                setJob("");

                 //close dialog
                handleCloseDialog();

                //set button loading
                setLoading(false);

            });

    }

    // get year from db and update state
    const getJobs = () => {
        jobAPI.getJob()
            .then(({data}) => {
                setJobsList(data);

            });
    }
 
    // on app launch get year data from db
    useEffect(() => {
       getJobs();
    }, [])

    return (
        <>
            <Stack>
                <Box width="100%" display="flex" flexDirection="column" alignItems="end">
                    <Button size='large' variant='contained' endIcon={ <Add /> } onClick={() => handleOpenDialog()}>Ajouter un poste</Button>
                </Box>
                <Typography color="primary" variant='subtitle1'>Année scolaire</Typography>
                <DividerBox />

                {
                    jobsList.length === 0 && (
                        <EmptyDataAlert />
                    )
                }

                {
                    jobsList.length > 0 && (
                        <Stack display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                            {
                                jobsList.map(({title,id}) => (
                                    <Box key={id} display="flex" width="150px" px={1} py={4} borderRadius={3} border={0.8} justifyContent="center" alignItems="center">
                                        <Typography>{title}</Typography>
                                    </Box>
                                ))

                            }
                        </Stack>
                    )
                }

            </Stack>
            <Dialog open={openDialog}>
                <DialogTitle>
                    <Typography>Ajouter un poste</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ py:1}}>
                        <TextField 
                            fullWidth
                            required
                            label="Poste"
                            variant='standard'
                            value={job}
                            onChange={(e) => setJob(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p:2 }}>
                    <Box display="flex" flexDirection="row" gap={2}>
                        <Button variant="contained" endIcon={ <Cancel /> } onClick={() => handleCloseDialog()}> Annuler </Button>
                        <LoadingButton variant="contained" endIcon={ <Save /> } onClick={() => addYear()} loadingPosition='end' loading={loading}> Enregistrer </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <AppSnackbar open={openSnackbar} setOpen={() => setOpenSnackbar()} message={snackbarMessage} type={snackbarType} />
            </>
    )
}

export default JobList