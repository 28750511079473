import { Dashboard, Group, Logout, ManageAccounts, Person, PersonAdd, Settings, SupportAgent, RemoveCircle, AddCircle } from '@mui/icons-material'
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { schoolInformationAPI } from '../../api/schoolInformation'
import useLogout from '../../hooks/useLogout'
import ExpireTimeDialog from './dialog/ExpireTimeDialog'
import useAuth from '../../hooks/useAuth'

function Sidebar() {
  let { auth } = useAuth()

  const logout = useLogout()

  const navigate = useNavigate()

  const [name, setName] = useState("")
  // loading
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInformations = () => {

    setLoading(true)

    schoolInformationAPI.getInformation()
      .then(({data}) => {
          // console.log(data.name);
          setName(data.name)

          setLoading(false)
        })
      .catch(() => {
          setName("N/A")
          setLoading(false)
      })
  }

  useEffect(() => {
    getInformations()
  },[])

  const logOut = () => {
    logout()
    handleClose()
  }

  // const profile = () => {
  //   handleClose()
  // }

  const checkForActivity = () => {

        const expireTime = localStorage.getItem('expireTime')

        if(expireTime < Date.now()) {
            //logout()
            setOpen(true)
            //console.log('logout', logout);
            return
        }
    }

  useEffect(() => {

        const interval = setInterval(() => {
            checkForActivity()
        }, 5000)


        return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


  return (
    <>
    <Stack sx={{
      height: '100vh',
      width: '250px',
      borderRight: '1px solid #C8C8C8'
    }}>
        <Stack sx={{
          margin: '2rem 1rem 2rem 1rem',
          height: '100%',
          display: 'flex',
          direction: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between', 
          alignItems: 'center',
        }}>

        <Stack>
          <Typography variant='h4'>{loading ? 'Chargement...' : name}</Typography>
        </Stack>

        <Stack display='block' px='20px'>
        {
          auth?.role === "administrateur" && (
            <>
              <Button variant="text" color='primary' disableElevation startIcon={<Dashboard />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
                onClick={() => navigate("/")}
              >
                Tableau de bord
              </Button>
              <hr />
            </>
          )
        }

        <Button variant="text" color='primary' disableElevation startIcon={<Person />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
          onClick={() => navigate("/classroom")}
        >
          Eleves
        </Button> 
        <Button variant="text" color='primary' disableElevation startIcon={<Group />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
          onClick={() => navigate("/professor")}
        >
          Professeurs
        </Button> 
        <Button variant="text" color='primary' disableElevation startIcon={<ManageAccounts />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
          onClick={() => navigate("/personal")}
        >
          Personnels
        </Button> 

        <hr />

        {
          (auth?.role === "administrateur" || auth?.role === "comptable" || auth?.role === "directeur") && (
            <>
              <Button variant="text" color='primary' disableElevation startIcon={<PersonAdd />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
                onClick={() => navigate("/subscribe")}
              >
                Inscription
              </Button>

              <hr />
            </>
          )
        }
        {
          (auth?.role === "administrateur" || auth?.role === "comptable") && (
            <>
              <Button variant="text" color='primary' disableElevation startIcon={<RemoveCircle />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
                onClick={() => navigate("/expense")}
              >
                Depenses
              </Button>

              <Button variant="text" color='primary' disableElevation startIcon={<AddCircle />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
                onClick={() => navigate("/income")}
              >
                Gains
              </Button>
              <hr />
            </>
          )
        }
        {
          (auth?.role === "administrateur" || auth?.role === "comptable" || auth?.role === "directeur" ) && (
            <>
              <Button variant="text" color='primary' disableElevation startIcon={<Settings />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
                onClick={() => navigate("/setting")}
              >
                Parametres
              </Button>
              <hr />
            </>
          )
        }


        <Button variant="text" color='primary' disableElevation startIcon={<SupportAgent />} sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
          onClick={() => navigate("/helpCenter")}
        >
          Centre d'aide
        </Button>

        </Stack>

        <Stack display='block' px='20px' width='100%'>
          <Button id="demo-positioned-menu" 
            variant="text" 
            color='primary' 
            disableElevation 
            startIcon={<Logout />} 
            sx={{ fontSize: "1rem",textTransform: 'capitalize' }}
            aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClick}
            >
            {auth?.fullName}
          </Button>
        </Stack>

        <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* <MenuItem onClick={profile}>Profil</MenuItem> */}
        <MenuItem onClick={logOut}>Deconnexion</MenuItem>
      </Menu>

        </Stack>
    </Stack>
    {
      open && (
        <ExpireTimeDialog open={open} setOpen={setOpen}/>
      )
    }
    
    </>
    
    
  )
}

export default Sidebar