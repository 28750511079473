import { Alert, AlertTitle, Backdrop, CircularProgress, colors, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { personalAPI } from '../api/personal';
import { Password } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';


function ChangePassword() {

  const [password, setPassword] = useState(" ")
  const [confirmPassword, setConfirmPassword] = useState(" ")
  const [failed, setFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const { auth, setAuth } = useAuth()

  //console.log(auth.mustChangePassword);


  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/classroom" 

  useEffect(() => {

    if(!auth.mustChangePassword) {
      navigate(from, { replace: true })
    }

    setPageLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleChangePassword = async () => 
  {
    setIsLoading(true)


    if(!password.trim() || !confirmPassword.trim()) {
      setPassword(password.trim())
      setConfirmPassword(confirmPassword.trim())
      return
    }
    // Change password

    if(password.trim() === confirmPassword.trim()) {
      try {
        const changePasswordResponse = await personalAPI.putPersonal(auth.niu,{
          password: password.trim()
        })

        if(!changePasswordResponse?.data?.success) {
          setPassword('')
          setConfirmPassword('')
          setFailed(true)
          setErrorMessage("Definition du nouveau mot de passe echouer. Veuillez vérifier vos informations et essayez de nouveau.")
          setIsLoading(false)
          return
        }

        const mustChangePasswordResponse = await personalAPI.putPersonal(auth.niu,{
            mustChangePassword: false
        })

        if(!mustChangePasswordResponse?.data?.success) {
          setPassword('')
          setConfirmPassword('')
          setFailed(true)
          setErrorMessage("Definition du nouveau mot de passe echouer. Veuillez vérifier vos informations et essayez de nouveau.")
          setIsLoading(false)
          return
        }

        console.log(JSON.parse(localStorage.getItem('auth')));
        const storedAuth = JSON.parse(localStorage.getItem('auth'))
        storedAuth.mustChangePassword = false
        localStorage.setItem('auth',JSON.stringify(storedAuth))
        setAuth(storedAuth)

        setFailed(false)

        setPassword('')
        setConfirmPassword(' ')

        navigate("/classroom", { replace: true })

      } catch (error) {
        console.log("Error", error);
      }
    }


  }

  return (
    <Stack>
      {
        pageLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={pageLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )
      }
      {
        !pageLoading && (
          <Grid container>
            <Grid item xs={9} bgcolor='primary.main' minHeight={'100vh'}>

            </Grid>
            <Grid item xs={3} px={3}>
              <Stack display='flex' mt={20} p={3} bgcolor='common.white' borderRadius={3} border={1}  borderColor={colors.grey['200']}>
                  <Stack gap={2}>
                    <Typography variant='h5' sx={{mb: 2}}>Veuillez definir un nouveau mot de passe</Typography>
                    <TextField
                        size='small'
                        label="Mot de passe"
                        required 
                        fullWidth
                        value={password}
                        onClick={(e) => e.target.value === " " && setPassword("")}
                        onChange={(e) => setPassword(e.target.value)} 
                        error={!password}
                        helperText={
                            !password ? "Veuillez entrer le mot de passe" : ""
                        }
                    />
                    <TextField
                      size='small'
                      label="Confirmation du mot de passe" 
                      required 
                      fullWidth
                      value={confirmPassword}
                      onClick={(e) => e.target.value === " " && setConfirmPassword("")}
                      onChange={(e) => setConfirmPassword(e.target.value)} 
                      error={!confirmPassword}
                      helperText={
                          !confirmPassword ? "Veuillez confirmer le mot de passe" : ""
                      }
                  />
                  </Stack>
                  <Stack mt={2} display='flex' direction='row' justifyContent='end'>
                      <LoadingButton 
                        fullWidth 
                        variant='contained' 
                        onClick={() => handleChangePassword()} 
                        endIcon={ <Password /> } loading={isLoading} 
                        loadingPosition="end" 
                        disabled={!password.trim() || !confirmPassword.trim() || (password.trim() !== confirmPassword.trim()) }
                      >Definir le mot de passe</LoadingButton>
                  </Stack>
              </Stack>
              {
                failed && (
                  <Alert sx={{ mt:5 }} severity="error">
                    <AlertTitle>Erreur</AlertTitle>
                    Erreur — <strong>{errorMessage}</strong>
                  </Alert>
                )
              }
            </Grid>
          </Grid>
        )
      }
    </Stack>
  )
}

export default ChangePassword