import * as yup from "yup";

export const StudentSchema = yup.object().shape({
    firstName: yup
        .string()
        .min(3,"Le prenom doit avoir 3 charactères mininum")
        .required("Le prenom est obligatoire"),
    lastName: yup
        .string()
        .min(3,"Le nom doit avoir 3 charactères mininum")
        .required("Le nom est obligatoire"),
    parentFullname: yup
        .string()
        .min(6,"Le nom complèt du parent doit avoir 6 charactères minimun")
        .required("Le nom complèt du parent est obligatoire"),
    parentPhoneNumber: yup
        .string()
        .min(8, "Le numero de téléphone du parent doit avoir 8 charactères minimun")
        .required("Le numero de téléphone du parent est obligatoire"),
})