import * as yup from "yup";

export const SchoolInformationSchema = yup.object().shape({
    name: yup
        .string()
        .min(3,"Le nom doit avoir 3 charactères mininum")
        .required("Le nom est obligatoire"),
    address: yup
        .string()
        .min(3,"L'adresse doit avoir 3 charactères mininum")
        .required("L'adresse est obligatoire"),
    street: yup
        .string()
        .min(1,"La rue doit avoir 1 charactères mininum")
        .required("La rue est obligatoire"),
    door: yup
        .string()
        .min(1,"La porte doit avoir 1 charactères mininum")
        .required("La porte est obligatoire"),
    phoneNumber: yup
        .string()
        .min(8,"Le numéro de téléphone doit avoir 8 charactères mininum")
        .required("Le numéro de téléphone est obligatoire"),
    email: yup
        .string()
        .email("L'adresse email n'est pas valide")
        .required("L'adresse email est obligatoire")
})