import { colors, Grid, MenuItem, Stack, TextField } from '@mui/material'
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react'
import { paiementAPI } from '../../../api/paiement';
import { studentAPI } from '../../../api/student';
import { yearAPI } from '../../../api/year';
import { monthOption } from '../../../config/defaultValue';
import MonthFeed from '../month/MonthFeed';
import MonthItem from '../month/MonthItem';
import PaiementList from '../PaiementList';
import LoadingBackdrop from '../../global/LoadingBackdrop';



function StudentPaiement({niu}) {

    // console.log(niu);

    const [yearsList, setYearsList] = useState([])
    const [monthsList, setMonthsList] = useState([])
    const [year, setYear] = useState('')
    const [total, setTotal] = useState(0)
    const [paiementsList, setPaiementsList] = useState([])
    const [isLoading, setIsLoading] = useState([])
    const [receiptResourceInformation, setReceiptResourceInformation] = useState({})



    const curentMonth = dayjs().month() + 1;

    const handleYearChange = (year) =>  {
        setYear(year)
        localStorage.setItem('year', year)
        getMonths(year)
    }

    const getStudent = () => {
        setIsLoading(true)
        studentAPI.getStudentByNiu(niu)
            .then(({data}) => {
                setReceiptResourceInformation({
                    name: `${data.firstName} ${data.lastName}`,
                    phoneNumber: data.parentPhoneNumber,
                    email: 'N/A',
                    toSchool: true
                })

                setTotal(data.classroom.monthlyPrice)
                //setTotal(45000)
                //console.log(data);
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false))
        
    }

    // get year from db and update state
    const getYears = () => {
        setIsLoading(true)
        yearAPI.getYear()
            .then(({data}) => {
                const _data = data.map(item => item.year)
                setYearsList(_data);

                let year = localStorage.getItem('year') ? localStorage.getItem('year') : _data[_data.length - 1]

                setYear(year)
                getMonths(year)


            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false))
    }

    const getMonths = (_year)  => {
        setIsLoading(true)
        yearAPI.getStudentMonthByYearAndNiu(_year, niu)
            .then(({data}) => {
                setMonthsList(parseMonth(data))
                // console.log(data);
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false))
    }

    const getPaiements = ()  => {
        setIsLoading(true)
        paiementAPI.getStudentPaiementByNiu(niu)
            .then(({data}) => {
                setPaiementsList(data)
                // console.log(data);
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false))
        
    }


    const parseMonth = (list) => list.map(data => {

        // before = and correct to === 
        let month = monthOption.find(i => i.key === data.month)

        data['monthName'] = month.value

        return data
    })


    // on app launch get year data from db
    useEffect(() => {
        getYears()
        getStudent()
        getPaiements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // console.log(monthsList);

  return (
    <Stack>
        {
            isLoading && <LoadingBackdrop />
        }
        {
            !isLoading && (
            <Grid container>
                <Grid item xs={10}>
                    <Grid container bgcolor='backgroundColor.main'>
                        {
                            monthsList.map(data => {
                                let paid =  0;
                                if(data.paiements.length > 0) {
                                    data.paiements.map(i => paid += i.amount)
                                }

                                let _color = null
                                const enabled = true
                                //if(data.enabled){
                                if(enabled){
                                    if(paid === total){
                                        _color = colors.green['700']
                                    }
                                    else if(paid < total)
                                    {
                                        if(data.id === curentMonth){
                                            _color = colors.yellow['800']
                                        }
                                        else {
                                            _color = colors.red['700']
                                        }
                                    }
                                }else {
                                    _color = colors.grey['300']
                                }

                                return (
                                    <MonthItem 
                                        key={data.id} 
                                        paid={paid}
                                        total={total}
                                        color={_color}
                                        monthName={data.monthName}
                                        monthId={data.id}
                                        niu={niu}
                                        kind={1}
                                        getYears={() => getYears()}
                                        getPaiements={() => getPaiements()}
                                    />
                                )
                            })
                        }
                    </Grid>
                    <Grid container mt={5}>
                        <PaiementList paiementsList={paiementsList} resource={receiptResourceInformation} total={total} />
                    </Grid>
                </Grid>
                <Grid item xs={2} p={3}>
                <Stack>
                    <MonthFeed />
                    <hr />

                    <Stack pr={2}>
                        <TextField
                            select 
                            fullWidth 
                            sx={{ mt: 2 }}
                            label="Année" 
                            size='small'
                            value={year} 
                            onChange={(event) => handleYearChange(event.target.value)}
                        >
                        {yearsList.map((year) => (
                            <MenuItem key={year} value={year}>{year}</MenuItem>
                            )
                        )
                        }
                        </TextField>
                    </Stack>
                </Stack>
                </Grid>
            </Grid>
            )
        } 
      
    </Stack>
  )
}

export default StudentPaiement