import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const SnackbarAlert = React.forwardRef(
    function snackBarAlert(props,ref){
        return <Alert elevation={6} ref={ref} {...props}/>
    }
)


const AppSnackbar = ({open, setOpen, message, type}) => {
    
    const handleClose = (event, reason) => {
        if(reason === 'clickaway')
        {
            return
        }
        setOpen(false)
    }
    

  return (
    <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
            vertical:'bottom',
            horizontal:'right'
            
        }}
    >
        <SnackbarAlert onClose={handleClose} severity={type} variant='filled'> 
            {message}
        </SnackbarAlert>

    </Snackbar>
  )
}

export default AppSnackbar