import { API } from "./index";

const getClassroomById = (id) => API.get(`/classroom/${id}`);
const getClassroom = () => API.get('/classroom');
const postClassroom = (data) => API.post('/classroom', data);
const putClassroom = (data) => API.put('/classroom', data);

export const classroomAPI = {
    getClassroomById,
    getClassroom,
    postClassroom,
    putClassroom
}
