import { useNavigate } from "react-router-dom"
import useAuth from "./useAuth"

const useLogout = () => {
    const { setAuth } = useAuth()
    const navigate = useNavigate()

    const logout = (expire) => {
        setAuth({})
        localStorage.removeItem('auth')
        if(expire) {
            return navigate('/login?expired')
        }
        navigate('/login')

    } 

    return logout
}

export default useLogout