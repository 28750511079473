import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

const EmptyDataAlert = () => {
  return (
    <Alert severity="info">
        <AlertTitle>Information</AlertTitle>
        Aucune donnée — <strong>Veuillez inserer des données!</strong>
    </Alert>
  )
}

export default EmptyDataAlert