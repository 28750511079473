import { Outlet } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { Box, Stack } from "@mui/material";


function SharedLayout () {
    return (
        <Stack>
            <Stack sx={{ position: 'fixed' }}>
                <Sidebar />
            </Stack>
            <Stack >
                {/* <Navbar /> */}
                <Box sx={{ left: 250, position: 'relative', width: 'calc(100% - 250px)' }}>
                    <Outlet />
                </Box>
                {/* <Footer /> */}
            </Stack>
        </Stack>
    )
}

export default SharedLayout;