import { FormatListBulleted } from '@mui/icons-material';
import { MenuItem, Stack, Tab, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { personalAPI } from '../api/personal';
import SearchBar from '../components/personal/Search';
import PersonalList from '../components/personal/PersonalList';
import { jobOptions } from '../config/defaultValue';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import EmptyDataAlert from '../components/global/EmptyDataAlert';


const Personal = () => {

  const [job, setJob] = useState("all")

  const [administrationList, setAdministrationList] = useState([])

  //console.log(administrationList)
  const getAdministrationList = () => {
    personalAPI.getAdministration()
      .then(({data}) => {
        setAdministrationList(data)
      })
  }

  // Tab
  const [value, setValue] = useState("1")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    getAdministrationList()
  }, [])

  return (
    <Stack pt={3}>
        <Stack display='flex' flexDirection='row' justifyContent='space-between' mt={2} mx={2.5} gap={2} id='topBar'>
            <Stack display='flex' flexDirection='row' gap={2}>
              <TextField
                required 
                select 
                fullWidth 
                label="Choix de cycle" 
                size='large' 
                value={job} 
                onChange={(e) => setJob(e.target.value)}
                sx={{ minWidth: '180px' }}
               >
                  <MenuItem value='all'>Tout</MenuItem>
                  {
                    jobOptions.map(data => (
                      <MenuItem key={data.key} value={data.key}>{data.value}</MenuItem> 
                    ))
                  }
                </TextField>
            </Stack>
            <Stack display='flex' flexDirection='row' gap={2}>
              {/* <Button size='large' variant="contained" className='sidebar-btn' color='primary' disableElevation startIcon={<Search />} sx={{ fontSize: "1rem" }}>Chercher</Button> */}
              <SearchBar />
            </Stack>
        </Stack>
        <Stack>
          <TabContext value={value}>
            <Stack borderBottom={1} borderColor='#e8e8e8' display='flex' flexDirection='row' mr={2}>
                <TabList onChange={handleChange} textColor='primary' indicatorColor='primary'>
                    <Tab value={"1"} icon={<FormatListBulleted />} iconPosition='start' label='Liste'/>
                </TabList>
            </Stack>
            <TabPanel value={"1"} >
                {
                  administrationList.length <= 0 && (
                    <EmptyDataAlert />
                  )
                }
                {
                  administrationList.length > 0 && (
                    <PersonalList personalListData={administrationList} />
                  )
                }
            </TabPanel> 
          </TabContext>
        </Stack>
    </Stack>
  )
}

export default Personal