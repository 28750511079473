import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material'
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react'
import dayjs from 'dayjs';
import { personalAPI } from '../../api/personal';

const Search = () => {


  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  let navigate = useNavigate();

  const handleStudentChange = (event, personal) => {
    navigate(`/profile/personal/${personal.niu}`)
  }

  const search = (event) => {
    let char = event.target.value
    if(char) {
      setLoading(true)
      personalAPI.getProfessorByChar(char)
        .then(({data}) => {
          setOptions(data.map((data) => {
            const age = dayjs().year() - dayjs(data.birthDate).year()
            return {
              ...data,
              label: `${data.niu} - ${data.firstName} ${data.lastName} - ${age} ${age > 1 ? 'ans' : 'an'} `,
            }
          }))
          setLoading(false)
          // console.log(data);
        })
        .catch(() => {
          setLoading(false)
        })
      }
  }

  // useEffect(() => {
  //   if(!open) {
  //     setOptions([])
  //   }
  // }, [open])



  return (
    <Stack>
        <Autocomplete 
            sx={{ width: 400 }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option,value) => option.niu === value.niu}
            options={options.sort((a,b) => -b.classroom.label.localeCompare(a.classroom.label))}
            groupBy={(options) => options.classroom.label}
            getOptionLabel={(options) => options.label}
            loading={loading}
            onChange={handleStudentChange}
            onKeyUp={search}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Professeur" 
                color='primary' 
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null }
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
              )
            } 
        />
    </Stack>
  )
}

export default Search