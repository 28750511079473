import { API } from "./index";

const postLogin = (data) => API.post(`/auth`, data);
const getRefresh = () => API.get(`/refresh`, {
    withCredentials: true
});

export const authAPI = {
    postLogin,
    getRefresh
}
